const QUOTE = "Quote"

export const QUOTE_TYPE = {
  QUOTE,
}

export const QUOTE_TYPE_LABELS = {
  [QUOTE]: "Quote",
}

export const QUOTE_TYPE_PLACEHOLDERS = {
  [QUOTE]: "e.g. A quote about the importance of lifelong learning.",
}
