import routerNames from "@/configs/routerNames"
import PageLayout from "@/layouts/PageLayout.vue"

/**
 * @name authRoutes
 * @type {import("vue-router").RouteRecordRaw}
 */
export default {
  path: "/activities",
  component: PageLayout,
  children: [
    {
      path: "",
      name: routerNames.ACTIVITIES,
      component: () => import("@/modules/activities/views/ActivitiesView.vue"),
    },
  ],
}
