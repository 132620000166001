import "core-js"
import { createApp } from "vue"
import App from "@/App.vue"
import router from "@/router"
import store from "@/store"
import auth0 from "@/plugins/auth0.plugin"
import fetchInitialDataPlugin from "@/plugins/fetchInitialData.plugin"
import eventBus from "@/plugins/eventBus.plugin"
import fileControllerPlugin from "@/plugins/fileController.plugin"
import cachingPlugin from "@/plugins/caching.plugin"
import registerBaseComponents from "@/components/Bases"
import registerIconComponents from "@/components/Icons"
import registerSkeletonComponents from "@/components/Skeletons"
import socketioPlugin from "@/plugins/socketio.plugin"
import hsAgiPlugin from "@/plugins/hsAgi.plugin"
import tokenManager from './plugins/tokenManager'

import "@/styles/main.scss"

const app = createApp(App)
const devMode = [
  "https://app.helloscribe.guru",
  "http://localhost:8080",
].includes(window.origin)

const errorHandler = (err, vm, info) => {
  if (!devMode) return
  console.error("Error during component rendering :>>", err)
  console.log("Vue Instance :>>", vm)
  console.info("info :>>", info)
  console.log("Current route:", router.currentRoute.value)
  console.log("Available routes:", router.getRoutes().map(route => ({
    name: route.name,
    path: route.path
  })))
}

const mountApp = () => {
  if (!window) return
  if (
    window.frameElement ||
    window.self !== window.top ||
    window.location !== window.parent.location
  ) {
    console.log("iframe detected")
    document.body.remove()
    return
  }

  app.use(eventBus)
  app.use(router)
  app.use(auth0)
  app.use(store)
  app.use(cachingPlugin)
  app.use(fetchInitialDataPlugin)
  app.use(fileControllerPlugin)
  app.use(socketioPlugin)
  app.use(hsAgiPlugin)
  app.use(tokenManager)
  registerBaseComponents(app)
  registerIconComponents(app)
  registerSkeletonComponents(app)
  app.config.errorHandler = errorHandler
  app.config.warnHandler = (msg, vm, trace) => {
    console.warn("Vue warning:", msg)
    console.log("Component:", vm)
    console.log("Trace:", trace)
  }
  app.mount("#app")

  window.devLog = (...args) => {
    devMode && console.log(...args)
  }
  window.devErr = (...args) => {
    devMode && console.error(...args)
  }
}
mountApp()