const MEDIA_PITCH = "Media Pitch"

export const outreachTypes = [MEDIA_PITCH]

export const OUTREACH_TYPES = {
  MEDIA_PITCH,
}

export const OUTREACH_TYPES_PLACEHOLDERS = {
  [MEDIA_PITCH]:
    "e.g. This is the story of how Antler Inc. invented the first ever invisibile cloak",
}
