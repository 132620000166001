<script setup></script>

<template>
  <span class="flex h-inherit"
    ><svg
      class="w-auto h-auto stroke-inherit"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 12H5C4.73478 12 4.48043 11.8946 4.29289 11.7071C4.10536 11.5196 4 11.2652 4 11V7.5C4 7.23478 4.10536 6.98043 4.29289 6.79289C4.48043 6.60536 4.73478 6.5 5 6.5H9C9.26522 6.5 9.51957 6.60536 9.70711 6.79289C9.89464 6.98043 10 7.23478 10 7.5V12ZM10 12C10 14.5 9 16 6 17.5M20 12H15C14.7348 12 14.4804 11.8946 14.2929 11.7071C14.1054 11.5196 14 11.2652 14 11V7.5C14 7.23478 14.1054 6.98043 14.2929 6.79289C14.4804 6.60536 14.7348 6.5 15 6.5H19C19.2652 6.5 19.5196 6.60536 19.7071 6.79289C19.8946 6.98043 20 7.23478 20 7.5V12ZM20 12C20 14.5 19 16 16 17.5"
        stroke-linecap="round"
      />
    </svg>
  </span>
</template>
