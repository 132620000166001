/** @type {import("vuex").GetterTree} */
export default {
  getQuery(state) {
    return state.query
  },

  getNewsResults(state) {
    return state.newsResults
  },

  getScholarResults(state) {
    return state.scholarResults.filter((r) => r.abstract)
  },

  getFactResults(state) {
    return state.factResults
  },

  getSearching(state) {
    return state.searching
  },
}
