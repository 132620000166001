import { ALL_TOOLS_LIST } from "@/constants/allToolsList"

export default {
  sidebarExpanded: true,
  user: null,
  adminAccount: null, // only for the invited users
  fetchingData: false,
  allTemplates: [],
  allTools: ALL_TOOLS_LIST,
  selectedTemplate: null,
  subscription: null,
  tokenInfo: {
    granted: 0,
    balance: 0,
  },
  team: null,
  cards: [],
  generating: false, // if the app is currently generating contents or not.
  allTags: [],
  selectedTags: [],
  showTutorialModal: false,
  tutorialLink: "",
  darkMode: true,
  authErr: null,
  alerts: [
    // {
    //   id: "fdsafewrewr3431fds3",
    //   message:
    //     "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Omnis, iste!",
    //   severity: "error",
    // },
  ],
}
