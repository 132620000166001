import { http } from "@/utils"
import store from "@/store"
import { ACTIONS, COMMITS, GETTERS } from "@/store/constants"
import { eventBus } from "@/plugins/eventBus.plugin"
import uiEvents from "@/configs/uiEvents"

/**
 * @returns {Promise<Project[]>}
 */
export const getAll = async () => {
  try {
    const res = await (await http()).get("/projects")
    store.commit(COMMITS.SET_PROJECTS_REPO, res.data.projects || [])
    store.dispatch(ACTIONS.UPDATE_ALL_PROJECTS_LIST)
    return res.data.projects
  } catch (err) {
    window.devErr(err)
    store.commit(COMMITS.SET_PROJECTS_REPO, [])
  }
  return []
}

/**
 * @param {string} id
 * @param {{folder?: string}} filters
 * @returns {Promise<Project>}
 */
export const getAProjectById = async (id, filters = {}) => {
  try {
    const res = await (
      await http()
    ).get("/project/" + id, {
      params: {
        ...filters,
      },
    })
    return res.data.project
  } catch (err) {
    window.devErr(err)
    return null
  }
}

/**
 * @param {string} id
 * @param {Omit<Project, "_id">} payload
 */
export const updateProject = async (id, payload) => {
  const res = await (await http()).put("/project/" + id, { ...payload })
  store.dispatch(ACTIONS.UPDATE_PROJECT, res.data.project)
  return res.data.project
}

export const deleteProject = async (id) => {
  await (await http()).delete("/project/" + id)
  store.dispatch(ACTIONS.DELETE_PROJECT, id)
}

/**
 * @param {Omit<Project, "_id">} payload
 */
export const createProject = async (payload) => {
  const tokenInfo = store.getters[GETTERS.TOKEN_INFO]
  if (!tokenInfo || tokenInfo.balance < 1) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOKEN_RAN_OUT_MODAL)
    return
  }

  const user = store.getters[GETTERS.USER]
  const res = await (
    await http()
  ).post("/project", { ...payload, ownerId: user._id })
  const projectsRepo = store.getters[GETTERS.PROJECTS_REPO]
  store.commit(COMMITS.SET_PROJECTS_REPO, [...projectsRepo, res.data.project])
  eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
    severity: "success",
    text: "Project created!",
  })
  return res.data.project
}

// files //
