import { watch } from "vue"
import store from "@/store"
import { COMMITS } from "@/store/constants"
import { authService, notesService, projectsService } from "@/services"
import routerNames from "@/configs/routerNames"
import { getBrowserCookies } from "@/utils"
import auth0 from "./auth0.plugin"

export default {
  /**
   * @param {import("vue").App} app
   */
  install(app) {
    /** redirect helper */
    const redirect = async ({ name = undefined, path = undefined }) => {
      if (name) await app.config.globalProperties.$router.replace({ name })
      else if (path) await app.config.globalProperties.$router.replace({ path })
    }

    const startLoading = () => store.commit(COMMITS.SET_FETCHING_DATA, true)
    const stopLoading = (delayed = false) =>
      !delayed
        ? store.commit(COMMITS.SET_FETCHING_DATA, false)
        : setTimeout(() => store.commit(COMMITS.SET_FETCHING_DATA, false), 1000)

    /** gets data from db for the user */
    const fetchInitialData = async () => {
      notesService.getAll()
      notesService.getAllTags()
      projectsService.getAll()
    }

    /**
     * @param {import("@auth0/auth0-vue").User} auth0User
     */
    const authenticate = async (auth0User) => {
      // if the user is visiting the share file page or the logout page then do not authenticate
      if (
        [routerNames.FILE_SHARE, routerNames.LOGOUT].includes(
          app.config.globalProperties.$route.name,
        )
      ) {
        return
      }

      try {
        startLoading()

        const cookies = getBrowserCookies()
        const referrer_id = cookies["_fprom_ref"] || undefined
        // const fprTid = cookies["_fprom_tid"]
        const invitation = sessionStorage.getItem(
          window.origin + ".invitation_id",
        )
        sessionStorage.removeItem(window.origin + ".invitation_id")
        // authenticate the user right after the page loads
        const {
          user,
          redirectUrl,
          cards,
          subscription,
          team,
          adminAccount,
          tokenInfo,
        } = await authService.authenticate({
          ...auth0User,
          invitation,
          referrer_id,
        })

        if (user) {
          store.commit(COMMITS.SET_USER, user)
        }
        if (subscription) {
          store.commit(COMMITS.SET_SUBSCRIPTION, subscription)
        }
        if (team) {
          store.commit(COMMITS.SET_TEAM, team)
        }
        if (cards) {
          store.commit(COMMITS.SET_CARDS, cards)
        }
        if (adminAccount) {
          store.commit(COMMITS.SET_ADMIN_ACCOUNT, adminAccount)
        }
        if (tokenInfo) {
          store.commit(COMMITS.SET_TOKEN_INFO, tokenInfo)
        }

        // if the backend specifies a redirect url then send the user to the url
        // backend only sends a redirect url to prevent loading the data so no fetchInitialData() call here
        if (redirectUrl) {
          if (redirectUrl.startsWith("https://")) {
            window.location.href = redirectUrl
          } else {
            await redirect({ path: redirectUrl })
          }
          return stopLoading(true)
        }

        await fetchInitialData()
        stopLoading()
      } catch (err) {
        await auth0.logout({
          logoutParams: {
            returnTo: window.origin + "/auth/login",
          },
        })
      }
    }

    watch(
      auth0.user,
      async (user) => {
        if (user) await authenticate(user)
      },
      { deep: true },
    )

    app.config.globalProperties.$authenticate = authenticate
    app.provide("authenticate", authenticate)
  },
}
