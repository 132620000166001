import routerNames from "@/configs/routerNames"
import { authGuard } from "@auth0/auth0-vue"
import { redirectLoggedInUsers } from "./guards"
import RedirectLoginView from "@/modules/auth/views/RedirectLoginView.vue"

/**
 * @name authRoutes
 * @type {import("vue-router").RouteRecordRaw}
 */
export default {
  path: "/auth",
  // component:
  children: [
    {
      path: "",
      component: RedirectLoginView,
    },
    {
      path: "login",
      name: routerNames.LOGIN,
      beforeEnter: redirectLoggedInUsers,
      component: () => import("@/modules/auth/views/LoginView.vue"),
    },
    {
      path: "logout",
      name: routerNames.LOGOUT,
      component: () => import("@/modules/auth/views/LogoutView.vue"),
    },
    {
      path: "email-confirmation",
      name: routerNames.EMAIL_CONFIRMATION,
      beforeEnter: authGuard,
      // component: () => import()
    },
  ],
}
