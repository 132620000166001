import { startCase } from "lodash"
import { GETTERS } from "./constants"
import { TOOL_TYPE } from "@/constants/ToolType"
import routerNames from "@/configs/routerNames"
import { ALL_TOOLS_LIST } from "@/constants/allToolsList"

export const getSidebarExpanded = (state) => {
  return state.sidebarExpanded
}

export const getFetchingData = (state) => {
  return state.fetchingData
}

export const getUser = (state) => {
  return state.user
}

export const getAccount = (state) => {
  return state.account
}

export const getAdminAccount = (state) => {
  return state.adminAccount
}

export const getProjectNotes = (state) => {
  const project = state.projects.selectedProject
  if (!project) return []
  const projectNotes = state.allNotes.filter(
    (note) => note.project === project._id,
  )
  return projectNotes
}

export const getSubscriptionInfo = (state) => {
  return state.subscription
}
export const getAppSumoSubscriptionInfo = (state) => {
  return state.appSumoSubscription
}

export const getAllTemplates = (state) => {
  const pinned = []
  const rest = []
  state.allTemplates.forEach((template) =>
    template.pinned ? pinned.push(template) : rest.push(template),
  )
  return [...pinned, ...rest]
}

export const getAllTools = (state) => {
  const pinned = []
  const others = []
  const allTools = state.allTools || []  // Ensure allTools is an array

  for (let i = 0; i < allTools.length; i++) {
    const tool = allTools[i]
    if (tool.pinned) pinned.push(tool)
    else others.push(tool)
  }

  return [
    ...pinned,
    ...others.sort((a, b) => {
      // Check if 'name' property exists before using localeCompare
      if (a.name && b.name) {
        return a.name.localeCompare(b.name, "en")
      } else if (a.name) {
        return -1  // a has name, b doesn't, so a comes first
      } else if (b.name) {
        return 1   // b has name, a doesn't, so b comes first
      } else {
        return 0   // neither has name, consider them equal
      }
    }),
  ]
}

export const getGenerating = (state) => {
  return state.generating
}

export const getSelectedTags = (state) => {
  return state.selectedTags
}

export const getAllTags = (state) => {
  return state.allTags
}

export const getTutorialLink = (state) => {
  return state.tutorialLink
}

export const getShowTutorialModal = (state) => {
  return state.showTutorialModal
}

export const getAlerts = (state) => {
  return state.alerts
}

export const getDarkMode = (state) => {
  return state.darkMode
}

export const getUserCards = (state) => {
  return state.cards
}

export const getUserTeam = (state) => {
  return state.team
}

export function getTokenInfo(state) {
  return state.tokenInfo
}

export function getAuthErr(state) {
  return state.authErr
}

export const getGlobalSearchItems = (state, getters) => {
  /** @type {GlobalSearchResultObj[]} */
  const items = [
    {
      _id: "user-profile-change-avatar",
      link: {
        name: routerNames.PROFILE,
        query: { action: "change-avatar" },
        params: {},
      },
      summary: "",
      title: "Change your profile avatar",
      type: "User",
    },
    {
      _id: "user-profile-change-username",
      link: {
        name: routerNames.PROFILE,
        query: { action: "change-username" },
        params: {},
      },
      summary: "",
      title: "Change your Username",
      type: "User",
    },
    {
      _id: "user-profile-affiliate-program",
      link: {
        name: routerNames.AFFILIATE,
        query: {},
        params: {},
      },
      title: "HelloScribe Affiliate program!",
      summary:
        "25% of the total value of subscription each month for a period of 12 months.",
      type: "User",
    },
    {
      _id: "billing-purchase-objectives",
      link: {
        name: routerNames.BILLING,
        query: { action: "purchase-objectives" },
        params: {},
      },
      title: "Purchase more Objectives",
      summary: "Get more objectives to boost your productivity and sale!",
      type: "Billing",
    },
    {
      _id: "user-invite-teammates",
      link: {
        name: routerNames.TEAM,
        query: { action: "invite" },
        params: {},
      },
      title: "Invite Teammates!",
      summary: "Work together, work faster!",
      type: "Collaboration",
    },
    {
      _id: "create-new-template",
      link: {
        name: routerNames.ALL_TEMPLATES,
        query: { action: "create-new-template" },
        params: {},
      },
      title: "Create Template",
      summary: "Add your own custom templates to boost your workflow",
      type: "Template",
    },
  ]

  // Convert all the Notes
  /** @type {Note[]} */
  const notes = getters[GETTERS.NOTES_REPO]
  for (let i = 0; i < notes.length; i++) {
    const note = notes[i]
    items.push({
      _id: note._id,
      title: note.slug,
      summary: note.text,
      type: "Note",
      link: {
        name: routerNames.EXPLORER_NOTES,
        query: { selected_note: note._id },
      },
    })
  }

  const selectedProject = getters[GETTERS.SELECTED_PROJECT]
  const selectedFolder = getters[GETTERS.SELECTED_FOLDER]

  const isArticleTool = (toolName) => {
    if (toolName === TOOL_TYPE.FIRSTDRAFT) return true
    if (toolName === TOOL_TYPE.prev_FIRSTDRAFT) return true
    return false
  }

  // convert all the files
  /** @type {ProjectFile[]} */
  const files = getters[GETTERS.ALL_FILES]
  for (let i = 0; i < files.length; i++) {
    const file = files[i]
    if (file && file._id && file.base_data) {
      items.push({
        _id: file._id,
        title: file.name,
        summary: file.base_data.description,
        type: startCase(file.type),
        link: {
          name: isArticleTool(file.tool_type)
            ? routerNames.FIRSTDRAFT
            : routerNames.TOOL_MODE,
          params: {
            category: isArticleTool(file.tool_type)
              ? "article"
              : file.tool_type,
          },
          query: {
            project: file.project_id,
            folder: file.folder,
            file: file._id,
            tool: file.base_data?.type || undefined,
          },
        },
      })
    }
  }

  // Convert all the projects
  /** @type {Project[]} */
  const projects = getters[GETTERS.PROJECTS_REPO]
  for (let i = 0; i < projects.length; i++) {
    const project = projects[i]
    items.push({
      _id: project._id,
      title: project.name,
      summary: project.description || "",
      type: "Project",
      link: {
        name: routerNames.PROJECT_OVERVIEW,
        params: { project_id: project._id },
      },
    })
  }

  const isArticle = (tool) =>
    tool.category === TOOL_TYPE.FIRSTDRAFT ||
    tool.category === TOOL_TYPE.prev_FIRSTDRAFT

  // Convert all the templates
  /** @type {Template[]} */
  const templates = getters[GETTERS.ALL_TEMPLATES]
  for (let i = 0; i < templates.length; i++) {
    const template = templates[i]
    items.push({
      _id: template._id,
      title: template.name,
      summary: template.category,
      type: "Template",
      link: {
        name: isArticle(template)
          ? routerNames.FIRSTDRAFT
          : routerNames.TOOL_MODE,
        params: { category: template.category },
        query: { template: template._id },
      },
    })
  }

  const defaultToolsQuery = {
    project: selectedProject ? selectedProject._id : "",
    folder: selectedFolder ? selectedFolder._id : "",
  }

  // Convert all the tools
  for (let i = 0; i < ALL_TOOLS_LIST.length; i++) {
    const tool = ALL_TOOLS_LIST[i]
    items.push({
      _id: tool.id,
      title: tool.name,
      summary: tool.description,
      type: "Tool",
      link: {
        name: isArticle(tool) ? routerNames.FIRSTDRAFT : routerNames.TOOL_MODE,
        params: { category: tool.category },
        query: {
          ...defaultToolsQuery,
          tool: isArticle(tool) ? "" : tool.id,
        },
      },
    })
  }

  return items
}

export const isNewUser = (state) => state.user?.isNewUser ?? false
export const isEmailVerified = (state) => state.user?.email_verified ?? false