/** @type {import("vuex").MutationTree} */
export default {
  setUserType(state, userType) {
    state.userType = userType
  },

  setFullname(state, fullname) {
    state.fullname = fullname
  },

  setIndustry(state, industry) {
    state.industry = industry
  },

  setOnboardingStep(state, step) {
    state.onboardingStep = step
  },

  setUsageType(state, usageType) {
    state.usageType = usageType
  },

  setSourceType(state, sourceType) {
    state.sourceType = sourceType
  },

  setOnboardingError(state, err) {
    state.onboardingError = err
  },
}