import state from "./state"
import getters from "./getters"
import actions from "./actions"
import mutations from "./mutations"

/** @type {import("vuex").ModuleTree} */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
