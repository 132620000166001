<script setup>
import { computed } from "vue"
import { useStore } from "vuex"
import { useAuth0 } from "@auth0/auth0-vue"
import { GETTERS } from "@/store/constants"
import LandingPageNavbar from "@/modules/navBar/components/LandingPageNavbar.vue"

const store = useStore()
const auth0 = useAuth0()
const fetchingData = computed(() => store.getters[GETTERS.FETCHING_DATA])
const isPageReady = computed(() => {
  if (auth0.isLoading.value) return false
  if (fetchingData.value) return false

  return true
})
const minHeight = computed(() => {
  return window.innerHeight.toString() + "px"
})
</script>

<template>
  <LandingPageNavbar />

  <div
    v-if="!isPageReady"
    class="flex flex-col justify-center items-center bg-bgContainer-light dark:bg-bgContainer-dark rounded-xl p-8 w-[90vw] max-w-[400px] gap-4 fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-[103]"
  >
    <BrandLogo3d class="h-[50px] mb-6" />
    <h4 class="text-h6">Authenticating...</h4>
    <BaseSpinner />
  </div>
  <div
    v-if="!isPageReady"
    class="fixed top-0 left-0 right-0 bottom-0 bg-bgNav-light dark:bg-bgNav-dark z-[102]"
  />

  <main
    class="w-full flex flex-col justify-center items-center p-4 md:p-6 bg-bgNav-light dark:bg-bgNav-dark"
    style="padding-top: 80px"
    :style="{ minHeight: minHeight }"
  >
    <RouterView v-if="isPageReady" />
  </main>
</template>