import { GETTERS } from "@/store/constants"
import { hsAgiHttp } from "@/utils"
import { AGENT_CHAT_HISTORY_PER_PAGE } from "@/configs/constants"
import { agentService } from "@/services"

/** @type {import("vuex").ActionTree} */
export default {
  async fetchChatHistory({ commit, state, rootGetters }, force = false) {
    let repo = state.chatHistoryRepo

    if (state.chatHistoryRepo.length < 1 || force) {
      try {
        const user = rootGetters[GETTERS.USER]
        // const account = rootGetters[GETTERS.ACCOUNT]

        const res = await (
          await hsAgiHttp()
        ).get(`/agi/threads/${user._id}`, {
          params: {
            account_id: user._id,
          },
        })
        if (res.data.data) {
          repo = res.data.data
        }
        // const res = await (
        //   await agentHttp()
        // ).get(
        //   `/get-chat-history?account_id=${user._id}&customer_id=${account.customer_id}`,
        // )
        // if (res.data.chat_history) {
        //   repo = res.data.chat_history
        // }
      } catch (err) {
        window.devErr(err.response?.data || err)
        repo = []
      }
    }

    commit("setChatHistoryRepo", repo)

    const selectedProject = rootGetters[GETTERS.SELECTED_PROJECT]
    if (!selectedProject) {
      commit("setChatHistory", repo)
    } else {
      const filtered = repo.filter(
        (chat) => chat.project_id === selectedProject._id,
      )
      commit("setChatHistory", filtered)
    }
  },

  async getAThread({ commit }, id) {
    try {
      const thread = await agentService.getThreadById(id)
      if (thread) {
        commit("updateThread", thread)
      }
    } catch (err) {
      window.devErr(err.response ? err.response.data : err)
    }
  },

  async changeChatHistoryPage(
    { state, commit, dispatch },
    { next, largeSteps },
  ) {
    let nextPage = JSON.parse(JSON.stringify(state.chatHistoryCurrentPage))
    const maxPages = Math.ceil(
      state.chatHistory.length / AGENT_CHAT_HISTORY_PER_PAGE,
    )
    if (largeSteps) {
      if (next) {
        nextPage = nextPage + 10 > maxPages ? maxPages : nextPage + 10
      } else {
        nextPage = nextPage - 10 < 1 ? 1 : nextPage - 10
      }
    } else {
      if (next) {
        nextPage = nextPage + 1 > maxPages ? maxPages : nextPage + 1
      } else {
        nextPage = nextPage - 1 < 1 ? 1 : nextPage - 1
      }
    }
    commit("setChatHistoryCurrentPage", nextPage)
    await dispatch("fetchChatHistory")
  },
}
