/** @type {import("vuex").MutationTree} */
export default {
  setQuery(state, query) {
    state.query = query
  },

  setNewsResults(state, newsResults) {
    state.newsResults = newsResults
  },

  setScholarResults(state, scholarResults) {
    state.scholarResults = scholarResults
  },

  setFactResults(state, factResults) {
    state.factResults = factResults
  },

  setSearching(state, searching) {
    state.searching = searching
  },
}
