import routerNames from "@/configs/routerNames"
import ShareFileLayout from "@/layouts/ShareFileLayout.vue"
import { authGuard } from "@auth0/auth0-vue"

/**
 * @name authRoutes
 * @type {import("vue-router").RouteRecordRaw}
 */
export default {
  path: "/printing",
  component: ShareFileLayout,
  beforeEnter: authGuard,
  children: [
    {
      path: "threads/:tid",
      children: [
        {
          path: "",
          name: routerNames.PRINT_THREAD,
          component: () =>
            import("@/modules/printing/views/PrintThreadPage.vue"),
        },
        {
          path: ":task_id",
          name: routerNames.PRINT_THREAD_TASK,
          component: () =>
            import("@/modules/printing/views/PrintThreadTaskPage.vue"),
        },
      ],
    },
    {
      path: "files/:fid",
      name: routerNames.PRINT_FILE,
      component: () =>
        import("@/modules/printing/views/PrintToolFilesPage.vue"),
    },
  ],
}
