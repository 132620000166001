<script setup>
import {
  onBeforeMount,
  ref,
  watch,
  inject,
  computed,
  defineAsyncComponent,
} from "vue"
import { useRoute } from "vue-router"
import { useStore } from "vuex"
import { ACTIONS, COMMITS, GETTERS } from "@/store/constants"
import { TOOL_TYPE, TOOL_TYPE_TUTORIALS } from "@/constants/ToolType"
import { useOutsideClick } from "@/utils"
import routerNames from "@/configs/routerNames"
import uiEvents from "@/configs/uiEvents"

const SwitchGlobalModeButtons = defineAsyncComponent(() =>
  import("@/modules/writing/components/SwitchGlobalModeButtons.vue"),
)
const ToolsPageBreadcrumbs = defineAsyncComponent(() =>
  import("@/modules/writing/components/ToolsPageBreadcrumbs.vue"),
)
const LanguageSelectionModal = defineAsyncComponent(() =>
  import("@/modules/writing/components/LanguageSelectionModal.vue"),
)
const ResponsiveActionBar = defineAsyncComponent(() =>
  import("@/modules/writing/components/ResponsiveActionBar.vue"),
)
const DesktopToolBar = defineAsyncComponent(() =>
  import("@/modules/writing/components/DesktopToolBar.vue"),
)

const route = useRoute()
const store = useStore()
const fileController = inject("fileController")
const eventBus = inject("eventBus")
const subscription = computed(() => store.getters[GETTERS.SUBSCRIPTION])
const user = computed(() => store.getters[GETTERS.USER])
const loading = ref(false)
const isAgentMode = computed(() => route.name === routerNames.AGENT_MODE)

/** @type {import("vue").ComputedRef<ProjectFile>} */
const selectedFile = computed(() => store.getters[GETTERS.SELECTED_FILE])
/** @type {import("vue").ComputedRef<Project>} */
const selectedProject = computed(() => store.getters[GETTERS.SELECTED_PROJECT])
/** @type {import("vue").ComputedRef<ProjectFile | null>} */
const selectedFolder = computed(() => store.getters[GETTERS.SELECTED_FOLDER])
const breadcrumbsList = ref([
  {
    name: "All Tools & Templates",
    path: "/writing/all/tools",
  },
  {
    current: true,
    name: route.params.category,
    path: route.fullPath,
    query: route.query,
  },
])

// Quick file actions
const saving = ref(false)
const sharing = ref(false)
const copying = ref(false)
const downloading = ref(false)
const showSelectStatus = ref(false)
const changingStatus = ref(false)
const showLanguageModal = ref(false)

const updatePathList = () => {
  const pathList = []
  if (selectedProject.value) {
    pathList.push(
      {
        name: "All Projects",
        path: "/projects",
      },
      {
        name: selectedProject.value.name,
        path: `/projects/${selectedProject.value._id}`,
      },
    )

    if (selectedFolder.value && selectedFolder.value._id) {
      pathList.push({
        name: selectedFolder.value.name,
        path: `/projects/${selectedProject.value._id}`,
        query: { folder: selectedFolder.value._id },
      })
    }

    pathList.push({
      current: true,
      name: selectedFile.value?.name || "Untitled",
      path: route.fullPath,
      query: route.query,
    })
  } else {
    pathList.push(
      {
        name: "All Tools",
        path: "/writing/all/tools",
      },
      {
        current: true,
        name:
          route.params.category === "article"
            ? TOOL_TYPE.FIRSTDRAFT
            : route.params.category,
        path: route.fullPath,
        query: route.query,
      },
    )
  }

  breadcrumbsList.value = pathList
}

const saveCurrentFile = async (allowRedirect = false) => {
  if (!selectedFile.value.project_id) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_ADD_TO_PROJECT_MODAL, { allowRedirect })
  } else {
    saving.value = true
    await fileController.saveSelectedFile()
    saving.value = false
  }
}

const downloadFileContent = async () => {
  if (!selectedFile.value._id) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      severity: "error",
      text: "Please save the current file before downloading.",
    })
    return
  }
  downloading.value = true
  // await fileController.prepareDownloadableFileContents()
  window.open(
    `${window.origin}/printing/files/${selectedFile.value._id}`,
    "_blank",
  )
  downloading.value = false
}

const shareFileContent = async () => {
  eventBus.emit(uiEvents.GLOBAL.SHOW_SHARE_TOOL_MODAL)
}

const copyFileContent = async () => {
  copying.value = true
  await fileController.copyFileContents()
  setTimeout(() => {
    copying.value = false
  }, 1000)
}

const handleChangeFileStatus = async (status) => {
  const file = JSON.parse(JSON.stringify(selectedFile.value))
  file.status = status
  changingStatus.value = true
  store.commit(COMMITS.SET_SELECTED_FILE, file)
  await fileController.saveSelectedFile()
  changingStatus.value = false
  showSelectStatus.value = false
}

const showTutorial = async () => {
  store.dispatch(
    ACTIONS.SHOW_TUTORIAL,
    isAgentMode.value
      ? TOOL_TYPE_TUTORIALS["agent-mode"]
      : TOOL_TYPE_TUTORIALS[selectedFile.value.tool_type],
  )
}

useOutsideClick("file-status-component", () => (showSelectStatus.value = false))

watch(
  () => [route, selectedProject, selectedFolder],
  () => {
    updatePathList()
  },
  { deep: true },
)
watch(
  () => route.query,
  async (q, oldQ) => {
    loading.value = true
    if (q.template !== oldQ.template) fileController.selectATemplate(q.template)
    if (q.tool !== oldQ.tool) {
      await fileController.getCurrentFile()
      updatePathList()
    }
    loading.value = false
  },
  { deep: true },
)

onBeforeMount(async () => {
  loading.value = true

  if (!selectedProject.value) {
    if (route.query.project) {
      const folderId = route.query.folder
      await store.dispatch(ACTIONS.SELECT_PROJECT, [
        route.query.project,
        folderId,
      ])
    }
  }
  await fileController.getCurrentFile()
  updatePathList()
  loading.value = false
})
</script>

<template>
  <div
    v-if="user.invited || (subscription && subscription.status === 'active')"
    class="page-wrapper"
  >
    <nav
      class="w-full bg-bgContainer-light dark:bg-bgContainer-dark border-b border-border-light dark:border-border-dark p-4 gap-4 md:gap-6 md:p-6 pb-6 flex flex-col justify-start items-start"
    >
      <Suspense>
        <ToolsPageBreadcrumbs :breadcrumbsList="breadcrumbsList" />
        <template #fallback>
          <div class="w-full flex flex-row gap-1">
            <div class="bg-skeletonBg rounded h-[19px] w-[6rem]"></div>
            <div class="bg-skeletonBg rounded h-[19px] w-[8rem]"></div>
          </div>
        </template>
      </Suspense>

      <div
        class="w-full flex flex-col xl:flex-row justify-between items-start gap-4"
      >
        <Suspense>
          <SwitchGlobalModeButtons />
        </Suspense>

        <Suspense>
          <DesktopToolBar
            v-if="!loading && selectedFile && selectedFile.base_data"
            :saving="saving"
            :downloading="downloading"
            :sharing="sharing"
            :copying="copying"
            :isAgentMode="isAgentMode"
            :changingStatus="changingStatus"
            @saveCurrentFile="saveCurrentFile"
            @downloadFileContent="downloadFileContent"
            @shareFileContent="shareFileContent"
            @copyFileContent="copyFileContent"
            @showLanguageModal="showLanguageModal = true"
            @showTutorial="showTutorial"
            @handleChangeFileStatus="handleChangeFileStatus"
          />
          <template #fallback>
            <BaseSpinner size="xs" w-full />
          </template>
        </Suspense>

        <Suspense>
          <ResponsiveActionBar
            v-if="!loading && selectedFile && selectedFile.base_data"
            :saving="saving"
            :downloading="downloading"
            :sharing="sharing"
            :copying="copying"
            :changingStatus="changingStatus"
            @saveCurrentFile="saveCurrentFile"
            @downloadFileContent="downloadFileContent"
            @shareFileContent="shareFileContent"
            @copyFileContent="copyFileContent"
            @showLanguageModal="showLanguageModal = true"
            @showTutorial="showTutorial"
            @handleChangeFileStatus="handleChangeFileStatus"
          />
          <template #fallback>
            <BaseSpinner size="xs" w-full />
          </template>
        </Suspense>
      </div>
    </nav>

    <Suspense>
      <LanguageSelectionModal
        :show="showLanguageModal"
        @close="showLanguageModal = false"
      />
      <template #fallback>
        <BaseSpinner size="xs" w-full />
      </template>
    </Suspense>

    <div
      v-if="loading"
      class="w-full flex flex-col justify-center items-center bg-bgContainer-light dark:bg-bgContainer-dark rounded-xl p-8 gap-4"
    >
      <h4 class="text-h6">Getting your file...</h4>
      <BaseSpinner />
    </div>
    <RouterView v-else-if="selectedFile && selectedFile.base_data" />
  </div>
  <BaseSubscriptionError
    v-else-if="subscription && subscription.status !== 'active'"
  />
  <div v-else class="page-wrapper">
    <BaseSpinner />
  </div>
</template>
