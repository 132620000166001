import routerNames from "@/configs/routerNames"
import ShareFileLayout from "@/layouts/ShareFileLayout.vue"

/**
 * @name authRoutes
 * @type {import("vue-router").RouteRecordRaw}
 */
export default {
  path: "/share",
  component: ShareFileLayout,
  children: [
    {
      path: "files/:share_id",
      name: routerNames.FILE_SHARE,
      component: () => import("@/modules/share/views/ShareFileView.vue"),
    },
    {
      path: "chats/:share_id",
      name: routerNames.CHAT_SHARE,
      component: () => import("@/modules/share/views/ShareChatView.vue"),
    },
  ],
}
