import { marked } from "marked"
import hljs from 'highlight.js';
import 'highlight.js/styles/atom-one-dark.css'; // You can choose a different style if you prefer

export const clickableStringForDynamicEditor = (htmlContent) => {
  let text = htmlContent
  const rgx = /\[([^\]]+)\]/g
  const matches = htmlContent.match(rgx)
  if (!matches || matches.length < 1) return text

  matches.forEach((match) => {
    let converted = match
    converted = converted.replace("[", "<em>")
    converted = converted.replace("]", "</em><br />")
    text = text.replace(match, converted)
  })
  return text
}

export const convertLineBreaks = (str) => {
  const rgx = /(\r\n|\r|\n)/g
  return str.replace(rgx, "<br/>")
}

export const convertInToParagraphs = (str) => {
  const splitted = convertLineBreaks(str).split("<br/>")
  let convertedText = ""
  splitted.forEach((text) => {
    convertedText += "<p>" + text + "</p>"
  })
  return convertedText
}

/**
 * @param {string} formattedText
 * @returns {string}
 */
export const trimDownToPlaintext = (formattedText) => {
  let plaintext = formattedText
  plaintext = formattedText.replace(/(<br>|<br\/>|<br \/>|<\/p>)/gi, "\n")
  const rgx = /(<([^>]+)>)/gi
  plaintext = plaintext.replace(rgx, "")
  return replaceHtmlCodeWithChars(plaintext.trim())
}

export const convertToNumber = (str) => {
  if (typeof str === "number") return str
  const s = str.trim()
  const n = parseInt(s, 10)
  return Number.isNaN(n) ? 0 : n
}

/**
 * Count words in a long string
 * @param {string} str
 * @returns
 */
export const countWordsInString = (str) => {
  const trimmed = trimDownToPlaintext(str) // remove any HTML tags
  const rgx = /[a-zA-Z0-9-_+*]+/gi
  const matches = trimmed.match(rgx)
  if (matches) return matches.length
  return 0
}

export function mdParser(md) {
  // Set up custom renderer
  const renderer = new marked.Renderer();

  // Override the code block rendering
  renderer.code = (code, language) => {
    const validLanguage = hljs.getLanguage(language) ? language : 'plaintext';
    const highlightedCode = hljs.highlight(validLanguage, code).value;
    return `<code-block code="${encodeURIComponent(code)}" language="${validLanguage}">${highlightedCode}</code-block>`;
  };

  // Set options for marked
  marked.setOptions({
    renderer: renderer,
    highlight: function(code, lang) {
      const language = hljs.getLanguage(lang) ? lang : 'plaintext';
      return hljs.highlight(code, { language }).value;
    },
    langPrefix: 'hljs language-'
  });

  // Parse the markdown
  const html = marked.parse(md);
  return html;
}

export function replaceHtmlCodeWithChars(str) {
  const rgx = /&#\d+;|&#x[0-9A-Fa-f]+;|&[a-zA-Z0-9]+;/g
  const replacedStr = str.replace(rgx, (match) => {
    switch (match) {
      case "&#39;":
        return "'"
      case "&#8704;":
        return "∀"
      case "&forall;":
        return "∀"
      case "&#x26;":
        return "&"
      case "&amp;":
        return "&"
      case "&copy;":
        return "©"
      case "&quot;":
        return '"'
      case "&#8220;":
        return "“"
      case "&#8221;":
        return "”"
      case "&lt;":
        return "<"
      case "&gt;":
        return ">"
      case "&#x25;":
        return "%"
      case "&#8364;":
        return "€"
      case "&euro;":
        return "€"
      case "&trade;":
        return "™"
      case "&reg;":
        return "®"
      case "&pound;":
        return "£"
      case "&#8470;":
        return "№"
      case "&frac12;":
        return "½"
      case "&frac14;":
        return "¼"
      case "&nbsp;":
        return " "
      case "&raquo;":
        return "»"
      case "&laquo;":
        return "«"
      case "&times;":
        return "×"
      case "&divide;":
        return "÷"
      case "&#8482;":
        return "™"
      case "&sect;":
        return "§"
      case "&yen;":
        return "¥"
      case "&micro;":
        return "µ"
      case "&deg;":
        return "°"
      case "&#8486;":
        return "Ω"
      case "&#8710;":
        return "∆"
      case "&#8719;":
        return "∏"
      case "&#8730;":
        return "√"
      case "&lambda;":
        return "λ"
      case "&pi;":
        return "π"
      case "&theta;":
        return "θ"
      case "&#946;":
        return "β"
      case "&sigma;":
        return "σ"
      case "&#931;":
        return "Σ"
      case "&delta;":
        return "δ"
      case "&#967;":
        return "φ"
      case "&omega;":
        return "ω"
      case "&epsilon;":
        return "ε"
      case "&gamma;":
        return "γ"
      case "&#934;":
        return "Φ"
      case "&#937;":
        return "Ω"
      case "&alpha;":
        return "α"
      case "&upsilon;":
        return "υ"
      case "&#962;":
        return "β"
      case "&#8487;":
        return "℧"
      case "&#8488;":
        return "ℨ"
      case "&#8721;":
        return "∑"
      default:
        return match
    }
  })
  return replacedStr
}