import routerNames from "@/configs/routerNames"
import { authGuard } from "@auth0/auth0-vue"
import PageLayout from "@/layouts/PageLayout.vue"
import SettingsLayout from "@/layouts/SettingsLayout.vue"

/**
 * @name settingsRoutes
 * @type {import("vue-router").RouteRecordRaw}
 */
export default {
  path: "/settings",
  beforeEnter: authGuard,
  component: PageLayout,
  children: [
    {
      path: "",
      name: routerNames.SETTINGS,
      component: SettingsLayout,
      children: [
        {
          path: "profile",
          name: routerNames.PROFILE,
          component: () => import("@/modules/settings/views/ProfileView.vue"),
        },
        {
          path: "team",
          name: routerNames.TEAM,
          component: () => import("@/modules/settings/views/TeamView.vue"),
        },
        {
          path: "billing",
          name: routerNames.BILLING,
          component: () => import("@/modules/settings/views/BillingView.vue"),
        },
        {
          path: "integrations",
          name: routerNames.INTEGRATIONS,
          component: () =>
            import("@/modules/settings/views/IntegrationsView.vue"),
        },
        // {
        //   path: "affiliate",
        //   name: routerNames.AFFILIATE,
        //   component: () => import("@/modules/settings/views/AffiliateView.vue"),
        // },
      ],
    },
  ],
}
