const HASHTAG_GENERATOR = "hashtag-generator"
const INSTAGRAM_CAPTION = "ig-caption"
const LINKEDIN_OUTREACH = "linkedin-outreach"
const LINKEDIN_POST = "linkedin-post"
const PRODUCT_LAUNCH = "product-launch"
const QUORA_ANSWERS = "quora-answers"
const SOCIAL_POST = "social-post"
const TWITTER_POST = "twitter-post"
const TWITTER_THREAD = "twitter-thread"
const VIRAL_POST_HOOK = "viral-post-hook"
const YOUTUBE_DESCRIPTION = "youtube-description"

export const SOCIAL_MEDIA_TYPES = {
  INSTAGRAM_CAPTION,
  PRODUCT_LAUNCH,
  YOUTUBE_DESCRIPTION,
  QUORA_ANSWERS,
  VIRAL_POST_HOOK,
  SOCIAL_POST,
  LINKEDIN_POST,
  LINKEDIN_OUTREACH,
  TWITTER_THREAD,
  TWITTER_POST,
  HASHTAG_GENERATOR,
}

export const SOCIAL_MEDIA_TYPE_LABELS = {
  [INSTAGRAM_CAPTION]: "Caption Generator",
  [HASHTAG_GENERATOR]: "Hashtag Generator",
  [LINKEDIN_OUTREACH]: "LinkedIn Outreach",
  [LINKEDIN_POST]: "LinkedIn Post",
  [PRODUCT_LAUNCH]: "Product Announcement",
  [QUORA_ANSWERS]: "Quora Answer",
  [SOCIAL_POST]: "Social Post",
  [TWITTER_POST]: "Twitter Post",
  [TWITTER_THREAD]: "Twitter Thread",
  [VIRAL_POST_HOOK]: "Viral Hook",
  [YOUTUBE_DESCRIPTION]: "Video Description",
}

export const SOCIAL_MEDIA_TYPE_PLACEHOLDERS = {
  [PRODUCT_LAUNCH]:
    "e.g. Announcement of a new productivity tool that helps professionals get more done in less time.",
  [INSTAGRAM_CAPTION]:
    "e.g. A caption about how artificial intelligence will change the world.",
  [YOUTUBE_DESCRIPTION]:
    "e.g. A description for a video about the future of work in the age of artificial intelligence.",
  [QUORA_ANSWERS]:
    "e.g. An answer to the question: How will artificial intelligence change the world?",
  [VIRAL_POST_HOOK]:
    "e.g. A hook about how to use artificial intelligence to increase productivity.",
  [SOCIAL_POST]:
    "e.g. A post about how artificial intelligence will change the world.",
  [LINKEDIN_POST]:
    "e.g. A LinkedIn post about how artificial intelligence will change the world.",
  [LINKEDIN_OUTREACH]:
    "Enter the profile headline or description of your prospect",
  [TWITTER_THREAD]:
    "e.g. A thread about how artificial intelligence will change the world.",
  [TWITTER_POST]:
    "e.g. A post about how artificial intelligence will change the world.",
  [HASHTAG_GENERATOR]: "Give me hashtags tags for the following topic:",
}
