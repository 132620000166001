<script setup></script>

<template>
  <span class="flex h-inherit">
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="w-auto h-auto stroke-inherit"
    >
      <path
        d="M1 20V19C1 17.1435 1.7375 15.363 3.05025 14.0503C4.36301 12.7375 6.14348 12 8 12C9.85652 12 11.637 12.7375 12.9497 14.0503C14.2625 15.363 15 17.1435 15 19V20"
        stroke-linecap="round"
      />
      <path
        d="M13 14C13 12.6739 13.5268 11.4021 14.4645 10.4645C15.4021 9.52678 16.6739 9 18 9C18.6566 9 19.3068 9.12933 19.9134 9.3806C20.52 9.63188 21.0712 10.0002 21.5355 10.4645C21.9998 10.9288 22.3681 11.48 22.6194 12.0866C22.8707 12.6932 23 13.3434 23 14V14.5"
        stroke-linecap="round"
      />
      <path
        d="M8 12C9.06087 12 10.0783 11.5786 10.8284 10.8284C11.5786 10.0783 12 9.06087 12 8C12 6.93913 11.5786 5.92172 10.8284 5.17157C10.0783 4.42143 9.06087 4 8 4C6.93913 4 5.92172 4.42143 5.17157 5.17157C4.42143 5.92172 4 6.93913 4 8C4 9.06087 4.42143 10.0783 5.17157 10.8284C5.92172 11.5786 6.93913 12 8 12ZM18 9C18.7956 9 19.5587 8.68393 20.1213 8.12132C20.6839 7.55871 21 6.79565 21 6C21 5.20435 20.6839 4.44129 20.1213 3.87868C19.5587 3.31607 18.7956 3 18 3C17.2044 3 16.4413 3.31607 15.8787 3.87868C15.3161 4.44129 15 5.20435 15 6C15 6.79565 15.3161 7.55871 15.8787 8.12132C16.4413 8.68393 17.2044 9 18 9Z"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </span>
</template>
