export default {
  notesRepo: [],
  allNotes: [],
  notesFromCurrentProject: false,
  gettingNotes: false,
  searchIndex: "",
  selectedNote: null,
  showNoteEditor: false,
  notesFilterType: "Newest",
  currentPage: 1,
}
