<script setup>
import { computed, ref, watchEffect, defineAsyncComponent } from "vue"
import { useStore } from "vuex"
import { RouterView, useRoute, useRouter } from "vue-router"
import routerNames from "@/configs/routerNames"
import { GETTERS } from "@/store/constants"

const UserIcon = defineAsyncComponent(() =>
  import("@/components/Icons/UserIcon.vue"),
)
const GroupIcon = defineAsyncComponent(() =>
  import("@/components/Icons/GroupIcon.vue"),
)
const MasterCardIcon = defineAsyncComponent(() =>
  import("@/components/Icons/MasterCardIcon.vue"),
)
// const HandshakeIcon = defineAsyncComponent(() =>
//   import("@/components/Icons/HandshakeIcon.vue"),
// )
// const IntegrationIcon =  defineAsyncComponent(() => import("@/components/Icons/IntegrationIcon.vue"))

const store = useStore()
const route = useRoute()
const router = useRouter()
/** @type {import("vue").ComputedRef<User>} */
const user = computed(() => store.getters[GETTERS.USER])
const filterOptions = computed(() => {
  const list = [
    {
      name: routerNames.PROFILE,
      filter: routerNames.PROFILE,
      icon: UserIcon,
    },
  ]

  if (user.value && !user.value.invited) {
    list.push(
      {
        name: routerNames.TEAM,
        filter: routerNames.TEAM,
        icon: GroupIcon,
      },
      {
        name: routerNames.BILLING,
        filter: routerNames.BILLING,
        icon: MasterCardIcon,
      },
      // {
      //   name: routerNames.INTEGRATIONS,
      //   filter: routerNames.INTEGRATIONS,
      //   icon: IntegrationIcon,
      // },
      // {
      //   name: routerNames.AFFILIATE,
      //   filter: routerNames.AFFILIATE,
      //   icon: HandshakeIcon,
      // },
    )
  }

  return list
})
const pathList = ref([
  {
    name: "Setting",
    path: "/settings/profile",
    current: true,
  },
])

const updateThePathList = () => {
  pathList.value = [
    {
      name: "Setting",
      path: "/settings/profile",
    },
    {
      name: route.name,
      path: route.fullPath,
      current: true,
    },
  ]
}

watchEffect(() => updateThePathList())

const handleSelectFilter = (name) => {
  router.push({ name })
}
</script>

<template>
  <div class="w-full h-full">
    <div
      v-if="user"
      class="page-wrapper flex flex-col justify-start items-start"
    >
      <BaseExplorerFilterBar
        :options="filterOptions"
        :activeFilter="route.name"
        @selectFilter="handleSelectFilter"
      />
      <BaseBreadcrumbs :list="pathList" class="w-full p-4 md:px-8" />

      <RouterView />
    </div>
  </div>
</template>
