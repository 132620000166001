const ACTIVITIES = "Activities"
const TOOLS_TEMPLATES = "Tools '\u0026' Templates Library"
const ALL_TOOLS = "All Tools"
const ALL_TEMPLATES = "All Templates"
const ARTICLE = "Article"
const ARTICLE_GENERATE_FORM = "Article Generate Form"
const HEADLINE = "Headline"
const SUMMARY = "Summary"
const BRAINSTORM = "Brainstorm"
const OUTREACH = "Outreach"
const MEDIA_PITCH = "Media Pitch"
const SOCIAL = "Social"
const PROMOTE = "Promote"
const QUOTE = "Quote"
const SETTINGS = "Settings"
const QUESTION = "Question"
const MESSAGING = "Messaging"
const CORRESPONDENCE = "Correspondence"
const PROJECT_SETTINGS = "Project Settings"
const PROJECT_OVERVIEW = "Project Overview"
const SUMOLING_REGISTER = "SumoLing Register"
const JOIN_COLLABORATOR = "Join Collaborator"
const ENTERTAINMENT = "Entertainment"
const TOOL_MODE = "Tool Mode"
const AGENT_MODE = "Agent Mode"

const ARTICLE_MODE = "Article Mode"
const FIRSTDRAFT = "First Draft Writer"

const SAVED_DRAFTS = "Saved Drafts"
const EXPLORER_NOTES = "Explorer Notes"
const EXPLORER_PROJECTS = "Explorer Projects"

const HELP_TUTORIALS = "Tutorials"

const HOME = "Home"
const PRICING = "Pricing"
const CONTACT_US = "Contact Us"
const TERMS_OF_USE = "TERMS_OF_USE"
const PRIVACY = "PRIVACY"

const AUTH_CALLBACK = "Auth callback"
const LOGIN = "Login"
const LOGOUT = "Logout"
const SIGNUP = "Sign Up"
const REGISTER = "Register"
const ONBOARDING = "Onboarding"
const GOOGLE_REGISTER = "GOOGLE_REGISTER"
const FORGOT_PASSWORD = "Forgot Password"
const RESET_PASSWORD = "Reset Password"
const EMAIL_CONFIRMATION = "Email Confirmation"
const INVITATION = "Invitation"

const ERROR_404 = "Error 404"

const PROFILE = "Profile"
const ACCOUNT = "Account"
const BILLING = "Billing"
const CHANGE_PLAN = "Change Plan"
const AFFILIATE = "Affiliate"
const SUBSCRIPTION_SUCCESS = "Subscription Success"
const SUBSCRIPTION_SUCCESS_SQUAD = "Subscription Success Squad"
const SUBSCRIPTION_SUCCESS_MAVERICK = "Subscription Success Maverick"
const SUBSCRIPTION_SUCCESS_UPGRADE = "Subscription Success Upgrade"
const TEAM = "Team"
const PASSWORD = "Password"
const UPGRADE = "Upgrade"
const JOIN_TEAM = "Join Team"
const TASK_SHARE = "Task share"
const FILE_SHARE = "File Share"
const CHAT_SHARE = "Chat Share"
const SUMOLING_PLAN = "SumoLing Plan"
const INTEGRATIONS = "Integrations"
const ADCOPY = "Ads"
const FINISH_LOGIN = "Finish Login"
const PRINT_THREAD = "Print Thread"
const PRINT_THREAD_TASK = "Print Thread Task"
const PRINT_FILE = "Print File"
const PRINT_OUTPUT = "Print Output"

const DEFAULT_LANDING_PAGE = "Default landing page"

const EXTENDED_ONBOARDING = "ExtendedOnboarding"

const routerNames = {
  AUTH_CALLBACK,
  LOGIN,
  LOGOUT,
  SIGNUP,
  REGISTER,
  SUMOLING_REGISTER,
  GOOGLE_REGISTER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  EMAIL_CONFIRMATION,
  INVITATION,
  ONBOARDING,
  ERROR_404,
  PROFILE,
  ACCOUNT,
  BILLING,
  CHANGE_PLAN,
  AFFILIATE,
  SUBSCRIPTION_SUCCESS,
  SUBSCRIPTION_SUCCESS_SQUAD,
  SUBSCRIPTION_SUCCESS_MAVERICK,
  SUBSCRIPTION_SUCCESS_UPGRADE,
  TEAM,
  PASSWORD,
  UPGRADE,
  SUMOLING_PLAN,
  JOIN_TEAM,
  ACTIVITIES,
  TOOLS_TEMPLATES,
  EXPLORER_PROJECTS,
  ARTICLE_GENERATE_FORM,
  ARTICLE,
  HEADLINE,
  SUMMARY,
  BRAINSTORM,
  MEDIA_PITCH,
  OUTREACH,
  SOCIAL,
  CORRESPONDENCE,
  ENTERTAINMENT,
  TOOL_MODE,
  AGENT_MODE,
  ARTICLE_MODE,
  FIRSTDRAFT,
  ADCOPY,
  JOIN_COLLABORATOR,
  SAVED_DRAFTS,
  EXPLORER_NOTES,
  HELP_TUTORIALS,
  HOME,
  CONTACT_US,
  PRICING,
  TERMS_OF_USE,
  PRIVACY,
  PROMOTE,
  QUOTE,
  SETTINGS,
  QUESTION,
  MESSAGING,
  PROJECT_SETTINGS,
  PROJECT_OVERVIEW,
  TASK_SHARE,
  FILE_SHARE,
  CHAT_SHARE,
  ALL_TOOLS,
  ALL_TEMPLATES,
  INTEGRATIONS,
  FINISH_LOGIN,
  PRINT_THREAD,
  PRINT_THREAD_TASK,
  PRINT_FILE,
  PRINT_OUTPUT,
  DEFAULT_LANDING_PAGE,
  EXTENDED_ONBOARDING,
}

export default routerNames