/** @type {import("vuex").MutationTree} */
export default {
  setNotesRepo(state, notes) {
    state.notesRepo = notes
  },

  setAllNotes(state, notes) {
    state.allNotes = notes
  },

  setGettingNotes(state, value) {
    state.gettingNotes = value
  },

  setSearchIndex(state, str) {
    state.searchIndex = str
  },

  setShowNoteEditor(state, value) {
    state.showNoteEditor = value
  },

  setSelectedNote(state, note) {
    state.selectedNote = note
  },

  setNotesFromCurrentProject(state, val) {
    state.notesFromCurrentProject = val
  },

  setNotesFilterType(state, type) {
    state.notesFilterType = type
  },

  setCurrentPage(state, pageNo) {
    state.currentPage = pageNo
  },
}
