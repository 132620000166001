import { FIRST_DRAFT_TYPES } from "@/constants/firstDraftTypes"
import { GETTERS } from "@/store/constants"

const KEY_PREFIX = "helloscribe.browserLocalCache"
const LAST_RESET_DATE = "helloscribe.lastCacheResetDate"

const crud = (key) => ({
  get() {
    const cacheStr = localStorage.getItem(key)
    if (!cacheStr) return null
    const cache = JSON.parse(cacheStr)
    return cache
  },

  save(data) {
    // get the previous contents before adding new one
    const prevDataStr = localStorage.getItem(key)
    if (prevDataStr) {
      const prevData = JSON.parse(prevDataStr)
      localStorage.setItem(key, JSON.stringify({ ...prevData, ...data }))
    } else {
      const strData = JSON.stringify(data)
      localStorage.setItem(key, strData)
    }
  },

  remove() {
    localStorage.removeItem(key)
  },
})

export default {
  /**
   * @param {import("vue").App} app
   */
  install(app) {
    const store = app.config.globalProperties.$store
    /** @returns {User} */
    const getUser = () => store.getters[GETTERS.USER]
    /** @returns {Project} */
    const getProjectId = () => app.config.globalProperties.$route.query.project
    const getTool = () => {
      const tool = app.config.globalProperties.$route.query.tool
      const category = app.config.globalProperties.$route.params.category
      if (category === "article") return FIRST_DRAFT_TYPES.ARTICLE_WRITER
      else return tool
    }

    /**
     * Browser caching:
     * the app will save the currently working file in the browser's localStorage.
     * this only applies to instantly opened files and files that aren't saved on the backend.
     *
     * the saving path is as follows:
     * KEY_PREFIX + current user id + selected project id or none + selected tool type
     *
     * Note: when a user logs out the caches will be erased for security purpose
     * this should be enough to store files from multiple users in a single browser without leaking their information.
     * the files are harmless since they don't contain any authentication information
     */
    const browserCache = {
      files() {
        const userId = getUser()._id
        const projectId = getProjectId() ? getProjectId() : "global"
        const key =
          KEY_PREFIX + "." + userId + "." + projectId + "." + getTool()
        return crud(key)
      },

      clearCache() {
        const localKeys = Object.keys(localStorage).filter((key) =>
          key.startsWith(KEY_PREFIX),
        )
        localKeys.forEach((key) => {
          localStorage.removeItem(key)
        })
        localStorage.setItem(LAST_RESET_DATE, new Date().toISOString())
      },

      checkResetNeeded() {
        const lastResetDate = localStorage.getItem(LAST_RESET_DATE)
        if (!lastResetDate) return true
        const threshold = new Date("2023-07-13T04:46:20.962Z")
        const lastDate = new Date(lastResetDate)
        if (lastDate.getTime() > threshold.getTime()) return false
        return true
      },
    }

    app.config.globalProperties.$browserCache = browserCache
    app.provide("browserCache", browserCache)
  },
}
