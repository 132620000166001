<script setup></script>

<template>
  <span class="flex h-inherit">
    <svg
      class="w-auto h-auto stroke-inherit"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 12V5.749C20.0001 5.67006 19.9845 5.59189 19.9543 5.51896C19.9241 5.44603 19.8798 5.37978 19.824 5.324L16.676 2.176C16.5636 2.06345 16.4111 2.00014 16.252 2H4.6C4.44087 2 4.28826 2.06321 4.17574 2.17574C4.06321 2.28826 4 2.44087 4 2.6V21.4C4 21.5591 4.06321 21.7117 4.17574 21.8243C4.28826 21.9368 4.44087 22 4.6 22H11M8 10H16M8 6H12M8 14H11M17.954 16.94L18.954 15.94C19.0581 15.8358 19.1817 15.7531 19.3178 15.6968C19.4539 15.6404 19.5997 15.6113 19.747 15.6113C19.8943 15.6113 20.0401 15.6404 20.1762 15.6968C20.3123 15.7531 20.4359 15.8358 20.54 15.94C20.7501 16.1502 20.8682 16.4353 20.8682 16.7325C20.8682 17.0297 20.7501 17.3148 20.54 17.525L19.54 18.525L17.954 16.939L14.963 19.93C14.8135 20.08 14.7154 20.2737 14.683 20.483L14.439 22.04L15.996 21.797C16.2053 21.7646 16.399 21.6665 16.549 21.517L19.539 18.525"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16 2V5.4C16 5.55913 16.0632 5.71174 16.1757 5.82426C16.2883 5.93679 16.4409 6 16.6 6H20"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </span>
</template>
