import state from "./state"
import mutations from "./mutations"
import actions from "./actions"

const getters = {
  ONBOARDING_STEP: (state) => state.onboardingStep,
  ONBOARDING_USER_TYPE: (state) => state.userType,
  ONBOARDING_FULLNAME: (state) => state.fullname,
  ONBOARDING_INDUSTRY: (state) => state.industry,
  ONBOARDING_USAGE_TYPE: (state) => state.usageType,
  ONBOARDING_SOURCE_TYPE: (state) => state.sourceType,
  ONBOARDING_ERROR: (state) => state.onboardingError,
  totalSteps: () => 3, // Assuming there are 3 steps in total
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}