<script setup></script>

<template>
  <span class="flex h-inherit"
    ><svg
      class="w-auto h-auto stroke-inherit"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0492 10.6071L2.97915 17.6771C2.7932 17.8629 2.64568 18.0835 2.54503 18.3263C2.44438 18.569 2.39258 18.8293 2.39258 19.0921C2.39258 19.355 2.44438 19.6152 2.54503 19.858C2.64568 20.1008 2.7932 20.3214 2.97915 20.5071C3.35421 20.8821 3.86282 21.0927 4.39315 21.0927C4.92348 21.0927 5.4321 20.8821 5.80715 20.5071L12.8772 13.4351M17.1922 13.8001L21.0702 17.6781C21.4451 18.0532 21.6557 18.5618 21.6557 19.0921C21.6557 19.6225 21.4451 20.1311 21.0702 20.5061C20.6951 20.8811 20.1865 21.0917 19.6562 21.0917C19.1258 21.0917 18.6172 20.8811 18.2422 20.5061L12.0332 14.2981M6.73215 5.90413L4.60915 6.61013L2.48915 3.07513L3.90315 1.66113L7.43915 3.78213L6.73215 5.90413ZM6.73215 5.90413L9.56215 8.73413"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.0507 10.607C9.20666 8.45396 9.37166 5.62896 11.1117 3.88896C12.8517 2.14896 16.0617 1.76796 17.8287 2.82896L14.7887 5.86896L14.5057 8.97996L17.6167 8.69796L20.6567 5.65696C21.7187 7.42496 21.3367 10.635 19.5967 12.374C17.8567 14.114 15.0327 14.279 12.8797 13.435"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </span>
</template>
