<script setup></script>

<template>
  <span class="flex h-inherit">
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="w-auto h-auto stroke-inherit"
    >
      <path
        d="M9.70735 3.22162L2.63635 10.2926C2.26141 10.6677 2.05078 11.1763 2.05078 11.7066C2.05078 12.2369 2.26141 12.7456 2.63635 13.1206L8.29235 18.7776C8.4781 18.9636 8.69868 19.1111 8.94147 19.2117C9.18427 19.3124 9.44452 19.3642 9.70735 19.3642C9.97018 19.3642 10.2304 19.3124 10.4732 19.2117C10.716 19.1111 10.9366 18.9636 11.1224 18.7776L18.1924 11.7066L9.70735 3.22162ZM9.70735 3.22162L8.29335 1.80762"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20 15C20 15 23 17.993 23 19.887C23 21.542 21.655 22.887 20 22.887C18.344 22.887 17.012 21.542 17 19.887C17.01 17.992 20 15 20 15Z"
        stroke-miterlimit="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </span>
</template>
