import { http } from "@/utils"
import store from "@/store"
import { COMMITS, GETTERS } from "@/store/constants"
import router from "@/router"
import routerNames from "@/configs/routerNames"
import { convertLineBreaks } from "@/utils/stringUtils"
import { eventBus } from "@/plugins/eventBus.plugin"
import uiEvents from "@/configs/uiEvents"

/**
 * get all the files of a project
 * @param {string} projectId
 * @param {{folder: string}} filters
 * @returns {Promise<{files: ProjectFile[]; folder: ProjectFile | null}>}
 */
export const getProjectFiles = async (projectId, filters = {}) => {
  const res = await (
    await http()
  ).get(`/files/${projectId}`, {
    params: { folder: filters.folder || "" },
  })
  return res.data.files
}

/**
 * updates a file in the db
 * @param {string} fileId
 * @param {ProjectFile} payload
 * @returns {Promise<ProjectFile>}
 */
export const updateAFile = async (fileId, payload, silent = false) => {
  const res = await (await http()).put("/file/" + fileId, { ...payload })

  if (!silent) {
    const filteredFiles = store.state.projects.allFiles.filter(
      (file) => file._id !== fileId,
    )
    store.commit(COMMITS.SET_FILES, [...filteredFiles, res.data.file])
    if (store.state.projects.selectedFile._id === fileId) {
      store.commit(COMMITS.SET_SELECTED_FILE, res.data.file)
    }
  }

  return res.data.file
}

/**
 * deletes a file from the db
 * @param {string} fileId
 */
export const dltAFile = async (fileId) => {
  await (await http()).delete("/file/" + fileId)
  const filteredFiles = store.state.projects.allFiles.filter(
    (file) => file._id !== fileId,
  )
  store.commit(COMMITS.SET_FILES, filteredFiles)
  if (
    store.state.projects.selectedFile &&
    store.state.projects.selectedFile._id === fileId
  ) {
    store.commit(COMMITS.SET_SELECTED_FILE, null)
    if (store.state.projects.selectedProject) {
      router.push({
        name: routerNames.PROJECT_OVERVIEW,
        params: {
          project_id: store.state.projects.selectedProject._id,
        },
      })
    }
  }

  eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
    severity: "success",
    text: "File deleted!",
  })
}

/**
 * creates a new file under the selected project
 * @param {Omit<ProjectFile, "_id">} payload
 * @returns {Promise<ProjectFile>}
 */
export const createFile = async (payload) => {
  const user = store.state.user
  const project = store.state.projects.selectedProject
  const projectId = payload.project_id || project._id || undefined
  if (!projectId) {
    throw new Error("No active project found!")
  }

  const res = await (
    await http()
  ).post("/file", {
    ...payload,
    ownerId: user._id,
    project_id: projectId,
  })
  store.commit(COMMITS.SET_FILES, [
    ...store.state.projects.allFiles,
    res.data.file,
  ])

  eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
    severity: "success",
    text: "File created!",
  })

  return res.data.file
}

/**
 * creates a new folder under the selected project
 * @param {string} name
 * @returns {Promise<ProjectFile>}
 */
export const createFolder = async (name) => {
  const tokenInfo = store.getters[GETTERS.TOKEN_INFO]
  if (!tokenInfo || tokenInfo.balance < 1) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOKEN_RAN_OUT_MODAL)
    return
  }

  /** @type {Omit<ProjectFile, "_id">} */
  const payload = {
    name,
    type: "folder",
    tool_type: "none",
    tool_count: 0,
    status: "Completed",
    folder: "",
  }
  const file = await createFile(payload)

  eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
    severity: "success",
    text: "Folder created!",
  })

  return file
}

/**
 * gets a file by its id from the db
 * @param {string} id
 * @returns {Promise<ProjectFile>}
 */
export const getAFile = async (id) => {
  const res = await (await http()).get("/file/" + id)
  return res.data.file
}

/**
 * removes one of the generated contents from the current file
 * @param {string} fileId
 * @param {ProjectFileContent} content
 * @returns {Promise<ProjectFile | null>}
 */
export const removeGeneratedContent = async (
  content,
  isPrevContent = false,
) => {
  try {
    /** @type {ProjectFile} */
    const selectedFile = store.getters[GETTERS.SELECTED_FILE]
    if (!selectedFile) throw new Error("No selected file found")

    const contents = (
      isPrevContent ? selectedFile.previous_contents : selectedFile.contents
    ).filter((c) => {
      if (c.text) return c.text !== content.text
      if (c.answer) return c.answer !== content.answer
    })
    if (selectedFile._id) {
      const payload = {}
      if (isPrevContent) payload.previous_contents = contents
      else payload.contents = contents
      const file = await updateAFile(selectedFile._id, payload)
      return file
    } else {
      const file = { ...selectedFile }
      if (isPrevContent) file.previous_contents = contents
      else file.contents = contents
      store.commit(COMMITS.SET_SELECTED_FILE, file)
      return file
    }
  } catch (err) {
    window.devErr(err)
    return null
  }
}

/**
 * clears the generated contents of the current file
 * @param {string} fileId
 * @returns {Promise<ProjectFile | null>}
 */
export const clearGeneratedContents = async () => {
  try {
    /** @type {ProjectFile} */
    const selectedFile = store.state.projects.selectedFile
    if (!selectedFile) throw new Error("No selected file found")
    const file = await updateAFile(selectedFile._id, { contents: [] })
    return file
  } catch (err) {
    window.devErr(err)
    return null
  }
}

/**
 * Generate contents for a tool
 * @param {string} path
 * @param {ProjectFileBaseData} payload
 * @returns {Promise<{results: Record<string, any>[]; tokenInfo: {granted: number; balance: number}; tokensUsed: {input: number; output: number}}>}
 */
export async function generateContent(path, data) {
  const tokenInfo = store.getters[GETTERS.TOKEN_INFO]
  if (tokenInfo.balance < 1) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOKEN_RAN_OUT_MODAL)
    return
  }
  const payload = {
    ...JSON.parse(JSON.stringify(data)),
    tokenInfo,
  }
  const res = await (await http()).post(path, payload)
  const results = res.data.results.map((result) => ({
    ...result,
    text: convertLineBreaks(result.text),
  }))
  return {
    results,
    tokenInfo: res.data.tokenInfo,
    tokensUsed: res.data.tokensUsed,
  }
}

export const getSharedFile = async (id) => {
  const res = await (await http()).get(`/share/files/${id}`)
  return res.data.share
}

export const createShareFileLink = async (file) => {
  const res = await (
    await http()
  ).post(`/share/files`, {
    contents: file.contents,
    ownerId: file.ownerId,
    file_id: file._id || undefined,
  })
  const link = `${window.origin}/share/files/${res.data.share._id}`
  return link
}

/**
 * Remove the relation between a note and a file's content.
 * @param {string} fileId
 * @param {string} noteId
 */
export const removeContentNoteRelation = async (fileId, noteId) => {
  try {
    const res = await (
      await http()
    ).put("/files/content-note-relation", { fileId, noteId })
    if (!res.data.file) return

    const allFiles = store.getters[GETTERS.ALL_FILES]
    const updatedAllFiles = allFiles.filter((f) => f._id === fileId)
    store.commit(COMMITS.SET_FILES, [...updatedAllFiles, res.data.file])

    const currentFile = store.getters[GETTERS.SELECTED_FILE]
    if (currentFile && currentFile._id === fileId) {
      store.commit(COMMITS.SET_SELECTED_FILE, res.data.file)
    }
  } catch (err) {
    window.devErr(err)
  }
}

export function printSpecificCmponent() { }
