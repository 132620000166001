import routerNames from "@/configs/routerNames"
import { authGuard } from "@auth0/auth0-vue"
import PageLayout from "@/layouts/PageLayout.vue"

/**
 * @name authRoutes
 * @type {import("vue-router").RouteRecordRaw}
 */
export default {
  path: "/notes",
  beforeEnter: authGuard,
  component: PageLayout,
  children: [
    {
      path: "",
      name: routerNames.EXPLORER_NOTES,
      component: () =>
        import("@/modules/notes/views/SavedNotesExplorerView.vue"),
    },
  ],
}
