export default {
  projectsRepo: [],
  allProjects: [],
  selectedProject: null,
  projectsFilter: "All Projects",
  selectedFolder: null,
  // this is where we store the files of the current project.
  // but if the user selects a folder as well then the folders files will also be stored here.
  allFiles: [],
  selectedFile: null,
  gettingFiles: false,
  filesSorting: "Name",
  projectsSorting: "",
  currentPage: 1,
  toolFilesCurrentPage: 1,
}
