import { createStore } from "vuex"
import * as getters from "./getters"
import * as actions from "./actions"
import * as mutations from "./mutations"
import state from "./state"
import projects from "./modules/projects"
import notes from "./modules/notes"
import research from "./modules/research"
import agent from "./modules/agent"
import onboarding from "./modules/onboarding"

const store = createStore({
  getters,
  actions,
  mutations,
  state,
  modules: {
    projects,
    notes,
    research,
    agent,
    onboarding,
  },
})

export default store