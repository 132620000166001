import { computed } from "vue"
import { filesService } from "@/services"
import { http, parseError, trimDownToPlaintext } from "@/utils"
import uiEvents from "@/configs/uiEvents"
import { replaceHtmlCodeWithChars } from "@/utils/stringUtils"
import { TOOL_API_ROUTES_V4, TOOL_TYPE } from "@/constants/ToolType"
import { COMMITS, GETTERS } from "@/store/constants"
import { FIRST_DRAFT_TYPES } from "@/constants/firstDraftTypes"
import { tokenManager } from '@/plugins/tokenManager';

export default {
  /**
   * @param {import("vue").App} app
   */
  install(app) {
    /** @type {import("vuex").Store} */
    const store = app.config.globalProperties.$store
    const eventBus = app.config.globalProperties.$eventBus
    const browserCache = app.config.globalProperties.$browserCache
    const allFiles = computed(() => store.getters[GETTERS.ALL_FILES])
    /** @returns {ProjectFile}*/
    const getFile = () => {
      const file =
        app.config.globalProperties.$store.getters[GETTERS.SELECTED_FILE]
      if (!file) return {}
      return JSON.parse(JSON.stringify(file))
    }
    const getFileId = () => app.config.globalProperties.$route.query.file
    const getCategory = () => app.config.globalProperties.$route.params.category
    const getTool = () => app.config.globalProperties.$route.query.tool || ""
    const getFolderId = () => app.config.globalProperties.$route.query.folder
    const getProjectId = () => app.config.globalProperties.$route.query.project
    const getSelectedProject = () =>
      app.config.globalProperties.$store.getters[GETTERS.SELECTED_PROJECT] ||
      null
    const getUser = () =>
      app.config.globalProperties.$store.getters[GETTERS.USER] || null

    const getFileLang = () => {
      const project = getSelectedProject()
      const user = getUser()

      return {
        source_lang: project
          ? project.language.input
          : user
          ? user.language.input
          : "EN-US",
        target_lang: project
          ? project.language.target
          : user
          ? user.language.target
          : "EN-US",
      }
    }

    /**
     * It does not contains any valid _id because its not saved in the mongodb
     * @returns {ProjectFile}
     */
    const createEmptyFile = () => ({
      ownerId: store.state.user._id,
      name: "Untitled file",
      type: "file",
      tool_type: getCategory(),
      status: "In Progress",
      folder: getFolderId() || "",
      project_id: getProjectId(),
      contents: [],
      tokensUsed: {
        input: 0,
        output: 0,
      },
      previous_contents: [],
      base_data: {
        description: "",
        descriptionHTML: "",
        tones: "",
        ...getFileLang(),
        type:
          getCategory()?.toLowerCase() === "article"
            ? FIRST_DRAFT_TYPES.ARTICLE_WRITER
            : getTool(),
        // for media pitch only
        journalistName: "",
        domains: "",
        company: "",
        name: "",
        role: "",
        article: {
          article: "",
          text: "",
          plaintext: "",
          cites: [],
          baseData: {
            title: "",
            authors: "",
            date: "",
            domain: "",
            documentType: "",
            keywords: [],
          },
        },
      },
    })

    /**
     * save the current file to the db or on the browser cache
     */
    const saveSelectedFile = async (silent = false) => {
      try {
        let selectedFile = getFile()
        const fileId = getFileId()
        if (fileId) {
          await filesService.updateAFile(fileId, selectedFile, silent)
        } else if (getProjectId() || selectedFile.project_id) {
          browserCache.files().remove()
          const file = await filesService.createFile(selectedFile)
          app.config.globalProperties.$router.replace({
            query: {
              ...app.config.globalProperties.$route.query,
              file: file._id,
              project: file.project_id,
              folder: file.folder,
            },
          })
        } else if (selectedFile.base_data) {
          browserCache.files().save(selectedFile)
        } else {
          selectedFile = createEmptyFile()
          browserCache.files().save(selectedFile)
        }
      } catch (err) {
        window.devErr(err)
      }
    }

    const selectATemplate = (id) => {
      const allTemplates = store.getters[GETTERS.ALL_TEMPLATES]
      /** @type {Template|undefined} */
      const template = allTemplates.find((t) => t._id === id)
      if (!template) return

      let file = getFile()
      if (!file) return

      file = JSON.parse(JSON.stringify(file))
      file.name = template.name
      file.base_data.description = template.description
      file.base_data.descriptionHTML = template.descriptionHTML
      file.tool_type = template.category
      file.base_data.type = template.toolId
      store.commit(COMMITS.SET_SELECTED_FILE, file)

      eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
        text: "Selected template: " + template.name,
      })
    }

    /**
     * get and set the current file or create a new cached file
     */
    const getCurrentFile = async () => {
      try {
        /** @type {ProjectFile} */
        let file = {}
        const selectedProject = getSelectedProject()
        /** @type {ProjectFile | null} */
        const prevFile = getFile()
        if (!prevFile.previous_contents) prevFile.previous_contents = []

        const fileId = getFileId()
        if (!fileId) {
          file = browserCache.files().get()
          if (!file || !file.base_data) {
            file = createEmptyFile()
            if (prevFile && prevFile.base_data) {
              file.base_data.description = prevFile.base_data.description
              file.base_data.descriptionHTML =
                prevFile.base_data.descriptionHTML
              file.previous_contents = prevFile.previous_contents
            }
            browserCache.files().save(file)
            // use the default tone of the project.
          }
          // use the default tone of the project.
          if (selectedProject) file.base_data.tones = selectedProject.tone || ""
          if (prevFile && prevFile.base_data) {
            file.base_data.description = prevFile.base_data.description
            file.base_data.descriptionHTML = prevFile.base_data.descriptionHTML
            file.previous_contents = prevFile.previous_contents
          }
        } else {
          file = allFiles.value.find((f) => f._id === fileId)
          if (!file) {
            file = await filesService.getAFile(fileId)
          }

          // if for any reason the file is also not found in the backend
          // i.e. the dev team manually removed the file, then this would prevent the frontend from throwing error
          if (!file) {
            file = createEmptyFile()
          }
        }

        // if current tool is a Media Pitch then make sure the file's base_data has all the necessary fields
        if (
          getCategory() === TOOL_TYPE.MEDIAPITCH ||
          getCategory() === TOOL_TYPE.prev_MEDIAPITCH
        ) {
          if (!file.base_data.journalistName) file.base_data.journalistName = ""
          if (!file.base_data.domains) file.base_data.domains = ""
          if (!file.base_data.company) file.base_data.company = ""
          if (!file.base_data.name) file.base_data.name = ""
          if (!file.base_data.role) file.base_data.role = ""
        } else if (
          getCategory() === TOOL_TYPE.FIRSTDRAFT ||
          getCategory === TOOL_TYPE.prev_FIRSTDRAFT
        ) {
          file.base_data.type = ""
          file.tool_type = TOOL_TYPE.FIRSTDRAFT
        }

        if (getFolderId()) {
          file.folder = getFolderId()
        }

        if (!file._id) {
          const lang = getFileLang()
          file.base_data.source_lang = lang.source_lang
          file.base_data.target_lang = lang.target_lang
        }

        store.commit(COMMITS.SET_SELECTED_FILE, file)
        // if there is an active template then load the template
        const templateId = app.config.globalProperties.$route.query.template
        if (templateId) {
          selectATemplate(templateId)
        }
      } catch (err) {
        window.devErr("fileController.js :>>", err)
      }
    }

    /**
     * @param {boolean} preservePreviousContents
     * @param {{tool_type: string; type: string}} overwrite - to overwrite the default tool type and tool id (especially for article writer tool)
     * @returns {Promise<Array<Record<string, any>>>}
     */
    const generateContent = async (
      preservePreviousContents = false,
      overwrite = null,
    ) => {
      const file = getFile()
      if (!file.previous_contents) file.previous_contents = []
      let contents = []
      const prevContents = JSON.parse(JSON.stringify(file.contents))
      try {
        if (!file) {
          throw new Error("Not enough information for generating contents")
        }

        store.commit(COMMITS.SET_GENERATING, true)

        const apiPath =
          TOOL_API_ROUTES_V4[overwrite ? overwrite.tool_type : file.tool_type]
        const payload = {
          ...file.base_data,
          type: overwrite ? overwrite.type : file.base_data.type,
        }
        if (!apiPath) {
          window.devErr(file)
          throw new Error("No path found for the tool")
        }
        const generationResult = await filesService.generateContent(
          apiPath,
          payload,
        )
        const {
          results: newContents,
          tokenInfo,
          tokensUsed,
        } = generationResult || {}

        if (preservePreviousContents) {
          file.contents = [...prevContents, ...newContents]
        } else {
          file.previous_contents.push(...prevContents)
          file.contents = newContents
        }

        if (file.tokensUsed && file.tokensUsed.output) {
          file.tokensUsed.input += tokensUsed.input
          file.tokensUsed.output += tokensUsed.output
        } else {
          file.tokensUsed = tokensUsed
        }

        if (tokenInfo) store.commit(COMMITS.SET_TOKEN_INFO, tokenInfo)
        store.commit(COMMITS.SET_SELECTED_FILE, file)
        eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
          severity: "success",
          text: "Content Ready!",
        })
        await saveSelectedFile()
      } catch (err) {
        console.error(err.response || err)
        window.devErr(err.response || err)
        if (err.response && err.response.data?.tokenRanOut) {
          tokenManager.handleTokenDepletionMidGeneration(
            err.response.data.partialResults,
            tokenManager.isFreeTrial()
          )
        } else {
          contents = []
          eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
            severity: "error",
            text: parseError(err),
          })
          eventBus.emit(uiEvents.GLOBAL.SHOW_ALERT_MODAL, {
            severity: "error",
            title: "Error when generating content!",
            body: parseError(err),
            callback: async () => {
              browserCache.files().remove()
              await getCurrentFile()
            },
          })
        }
      } finally {
        store.commit(COMMITS.SET_GENERATING, false)
      }
      return contents
    }

    /**
     * Generates similar content from a generated content and updates the selected file
     */
    const generateSimilarContent = async (
      refContent,
      isPrevContent = false,
    ) => {
      /** @type {ProjectFile} */
      const file = getFile()
      const apiPath = TOOL_API_ROUTES_V4[file.tool_type]
      const payload = {
        ...file.base_data,
        new_model: refContent.text || refContent.answer,
        reference_content: refContent.text || refContent.answer,
      }
      const {
        results: newContents,
        tokensUsed,
        tokenInfo,
      } = await filesService.generateContent(apiPath, payload)

      for (
        let i = 0;
        i < (isPrevContent ? file.previous_contents : file.contents).length;
        i++
      ) {
        const content = (
          isPrevContent ? file.previous_contents : file.contents
        )[i]
        let matches = false
        if (refContent.text && content.text === refContent.text) {
          matches = true
        }
        if (refContent.answer && content.answer === refContent.answer) {
          matches = true
        }
        if (!matches) continue
        ;(isPrevContent ? file.previous_contents : file.contents)[i].similar =
          newContents
        break
      }

      if (tokenInfo) store.commit(COMMITS.SET_TOKEN_INFO, tokenInfo)
      if (file.tokensUsed && file.tokensUsed.output) {
        file.tokensUsed.input += tokensUsed.input
        file.tokensUsed.output += tokensUsed.output
      } else {
        file.tokensUsed = tokensUsed
      }
      store.commit(COMMITS.SET_SELECTED_FILE, file)
      await saveSelectedFile()
      eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
        severity: "success",
        text: "Success! Clones created!",
      })
      return newContents
    }

    const prepareDownloadableFileContents = async () => {
      const file = getFile()
      const res = await (
        await http()
      ).post("/exporter/create/file", {
        prompt: file.base_data.description,
        contents: file.contents,
        article: file.base_data.article.plaintext,
        cites: file.base_data.article.cites,
      })
      window.open(res.data.downloadUrl, "_blank")
    }

    const copyFileContents = async () => {
      const file = getFile()
      let contents = ""
      if (
        file.base_data.type === TOOL_TYPE.FIRSTDRAFT ||
        file.base_data.type === TOOL_TYPE.prev_FIRSTDRAFT
      ) {
        contents += file.base_data.article.plaintext
      } else {
        file.contents.forEach((c, i) => {
          if (i > 0) contents += "\n\n-------\n\n"
          if (c.text) {
            contents += trimDownToPlaintext(c.text)
          }
        })
      }
      await window.navigator.clipboard.writeText(
        replaceHtmlCodeWithChars(contents),
      )
      eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
        severity: "success",
        text: "Contents copied!",
      })
      return contents
    }

    const openFileInFallbackMode = async (selectedFile, callback) => {
      let file = JSON.parse(JSON.stringify(selectedFile))

      let needsUpdate = false
      // fallback support for old tools and tool categories
      if (file.tool_type === TOOL_TYPE.prev_BRAINSTORM) {
        file.tool_type = TOOL_TYPE.BRAINSTORM
      } else if (file.tool_type === TOOL_TYPE.prev_ADCOPY) {
        file.tool_type = TOOL_TYPE.ADCOPY
        needsUpdate = true
      } else if (file.tool_type === TOOL_TYPE.prev_MEDIAPITCH) {
        file.tool_type = TOOL_TYPE.MEDIAPITCH
        needsUpdate = true
      } else if (file.tool_type === TOOL_TYPE.prev_FIRSTDRAFT) {
        file.tool_type = TOOL_TYPE.FIRSTDRAFT
        needsUpdate = true
      } else if (file.tool_type === TOOL_TYPE.prev_HEADLINE) {
        file.tool_type = TOOL_TYPE.HEADLINES
        needsUpdate = true
      } else if (file.tool_type === TOOL_TYPE.prev_MESSAGING) {
        file.tool_type = TOOL_TYPE.MESSAGING
        needsUpdate = true
      } else if (file.tool_type === TOOL_TYPE.prev_SOCIALMEDIA) {
        file.tool_type = TOOL_TYPE.SOCIALMEDIA
        needsUpdate = true
      } else if (file.tool_type === TOOL_TYPE.prev_QUOTES) {
        file.tool_type = TOOL_TYPE.QUOTES
        needsUpdate = true
      } else if (file.tool_type === TOOL_TYPE.prev_QUESTION) {
        file.tool_type = TOOL_TYPE.QUESTION
        needsUpdate = true
      }

      if (needsUpdate) {
        file = await filesService.updateAFile(file._id, file)
      }

      callback(file)
    }

    eventBus.on("saveSelectedFile", async () => {
      await saveSelectedFile(true)
    })
    eventBus.on("getCurrentFile", getCurrentFile)

    const fileController = {
      saveSelectedFile,
      getCurrentFile,
      createEmptyFile,
      generateContent,
      prepareDownloadableFileContents,
      copyFileContents,
      generateSimilarContent,
      openFileInFallbackMode,
      selectATemplate,
    }
    app.config.globalProperties.$fileController = fileController
    app.provide("fileController", fileController)
  },
}
