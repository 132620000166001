import { sortByCreatedAtDescending, sortByUpdatedAtDescending } from "@/utils"
import { PROJECTS_PER_PAGE, TOOL_FILES_PER_PAGE } from "@/configs/constants"

/** @type {import("vuex").GetterTree} */
export default {
  getAllProjects(state) {
    const currentPage = state.currentPage
    const allProjects = state.allProjects
    const start = PROJECTS_PER_PAGE * (currentPage - 1)
    const end = PROJECTS_PER_PAGE * currentPage
    const data = allProjects.slice(start, end)
    return data
  },

  getSelectedProject(state) {
    return state.selectedProject
  },

  getSelectedFolder(state) {
    return state.selectedFolder
  },

  getAllFiles(state) {
    /** @type {ProjectFile[]} */
    let files = state.allFiles

    const filter = state.filesSorting
    switch (filter) {
      case "Name":
        files = files.sort((a, b) => {
          const nameA = a.name.toLowerCase()
          const nameB = b.name.toLowerCase()
          return nameA.localeCompare(nameB)
        })
        break
      case "Created At":
        files = files.sort(sortByCreatedAtDescending)
        break
      default:
        files = files.sort(sortByUpdatedAtDescending)
        break
    }

    const toolFilesCurrentPage = state.toolFilesCurrentPage
    const start = (toolFilesCurrentPage - 1) * TOOL_FILES_PER_PAGE
    const end = toolFilesCurrentPage * TOOL_FILES_PER_PAGE
    const data = files.slice(start, end)
    return data
  },

  getSelectedFile(state) {
    return state.selectedFile
  },

  getGettingFiles(state) {
    return state.gettingFiles
  },

  getFilesSorting(state) {
    return state.filesSorting
  },

  getProjectsSorting(state) {
    return state.projectsSorting
  },

  getProjectsFilter(state) {
    return state.projectsFilter
  },

  getProjectsCurrentPage(state) {
    return state.currentPage
  },

  getToolFilesCurrentPage(state) {
    return state.toolFilesCurrentPage
  },

  getProjectsRepo(state) {
    return state.projectsRepo
  },
}
