/**
 * Global event bus for emitting events globally
 */
class EventBus {
  /**
   * @type {Record<string, ((...any) => void|Promise<void>)[]>}
   */
  events = {}

  constructor() {
    this.events = {}
  }

  /**
   * Subscribe to an event
   * @param {string} eventName
   * @param {(...any) => void|Promise<void>} callback
   */
  on(eventName, callback) {
    if (!this.events[eventName]) {
      this.events[eventName] = [callback]
      return
    }
    const isSubbed = this.events[eventName].find((cb) => cb === callback)
    if (isSubbed) return
    this.events[eventName].push(callback)
  }

  /**
   * Unsubscribe from and event
   * @param {string} eventName
   * @param {(...any) => void|Promise<void>} callback
   */
  off(eventName, callback) {
    if (!this.events[eventName]) return
    this.events[eventName] = this.events[eventName].filter(
      (cb) => cb !== callback,
    )
  }

  /**
   * Emit and event
   * @param {string} eventName
   * @param  {...any} args
   */
  async emit(eventName, ...args) {
    if (!this.events[eventName]) return
    const promises = []
    this.events[eventName].forEach((cb) => {
      promises.push(cb(...args))
    })
    await Promise.all(promises)
  }
}

// we can use this instance to emit events from outside vue components
export const eventBus = new EventBus()

export default {
  /**
   * @param {import("vue").App} app
   */
  install(app) {
    app.config.globalProperties.$eventBus = eventBus
    app.provide("eventBus", eventBus)
  },
}
