/** @type {import("vuex").MutationTree} */
export default {
  setProjects(state, projects) {
    state.allProjects = projects
  },

  setFiles(state, files) {
    state.allFiles = files
  },

  setSelectedProject(state, project) {
    state.selectedProject = project
  },

  setSelectedFolder(state, folder) {
    state.selectedFolder = folder
  },

  setSelectedFile(state, file) {
    state.selectedFile = file
  },

  setGettingFiles(state, value) {
    state.gettingFiles = value
  },

  setFilesSorting(state, sorting) {
    state.filesSorting = sorting
  },

  setProjectsSorting(state, sorting) {
    state.projectsSorting = sorting
  },

  setProjectsFilter(state, filter) {
    state.projectsFilter = filter
  },

  setProjectsCurrentPage(state, pageNo) {
    state.currentPage = pageNo
  },

  setToolFilesCurrentPage(state, pageNo) {
    state.toolFilesCurrentPage = pageNo
  },

  setProjectsRepo(state, projects) {
    state.projectsRepo = projects
  },
}
