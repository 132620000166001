import { http } from "@/utils"
import store from "@/store"
import { ACTIONS, COMMITS, GETTERS } from "@/store/constants"
import { eventBus } from "@/plugins/eventBus.plugin"
import uiEvents from "@/configs/uiEvents"

const getUser = () => {
  const user = store.getters[GETTERS.USER]
  if (!user) throw new Error("Unable to find any logged in user")
  return user
}

/**
 * Get all the notes
 * @returns {Promise<Note[]>}
 */
export const getAll = async () => {
  const res = await (await http()).get("/notes")
  store.commit(COMMITS.SET_NOTES_REPO, res.data.notes)
  return res.data.notes
}

/**
 * Create new note
 * @param {Omit<Note, "_id">} payload
 * @returns {Promise<Note>}
 */
export const createNote = async (payload) => {
  const user = getUser()
  const data = JSON.parse(JSON.stringify(payload))
  data.ownerId = user._id

  const project = store.getters[GETTERS.SELECTED_PROJECT]
  if (project) data.project = project._id

  const res = await (await http()).post("/notes", data)
  store.dispatch(ACTIONS.UPDATE_NOTES_REPO, [res.data.note])
  return res.data.note
}

/**
 * Update a note
 * @param {string} id
 * @param {Partial<Note>} payload
 * @returns {Promise<Note>}
 */
export const updateNote = async (id, payload) => {
  const res = await (
    await http()
  ).put(`/notes/${id}`, JSON.parse(JSON.stringify(payload)))
  const allNotes = store.getters[GETTERS.NOTES_REPO].filter(
    (note) => note._id !== id,
  )
  allNotes.unshift(res.data.note)
  store.commit(COMMITS.SET_NOTES_REPO, allNotes)
  store.commit(COMMITS.SET_SELECTED_NOTE, res.data.note)
  eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
    severity: "success",
    text: "Note updated!",
  })
  return res.data.note
}

/**
 * Delete a note
 * @param {string} id
 */
export const deleteNote = async (id) => {
  await (await http()).delete(`/notes/${id}`)
  const allNotes = store.getters[GETTERS.NOTES_REPO].filter(
    (note) => note._id !== id,
  )
  store.commit(COMMITS.SET_NOTES_REPO, allNotes)
  eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
    severity: "success",
    text: "Note deleted!",
  })
}

/**
 * Get all the tags of the current user
 * @returns {Promise<Tag[]>}
 */
export const getAllTags = async () => {
  try {
    const user = getUser()
    const res = await (await http()).get(`/tags/${user._id}`)
    store.commit(COMMITS.SET_TAGS, res.data.tags)
    return res.data.tags
  } catch (err) {
    window.devErr(err)
    return []
  }
}

export const createTag = async ({ name, colorName, colorHex }) => {
  const user = getUser()
  const res = await (
    await http()
  ).post(`/tags/${user._id}`, { name, colorName, colorHex })
  const allTags = store.getters[GETTERS.ALL_TAGS]
  allTags.unshift(res.data.tag)
  store.commit(COMMITS.SET_TAGS, allTags)
  eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
    severity: "success",
    text: "New Tag created!",
  })
  return res.data.tag
}
