/** @type {import("vuex").MutationTree} */
export default {
  setChatHistory(state, chatHistory) {
    state.chatHistory = chatHistory
  },

  setChatHistoryRepo(state, chats) {
    state.chatHistoryRepo = chats
  },

  setSessionId(state, sessionId) {
    state.sessionId = sessionId
  },

  setUserReply(state, userReply) {
    state.userReply = userReply
  },

  setIsUserReply(state, isUserReply) {
    state.isUserReply = isUserReply
  },

  setShowStopBtn(state, showStopBtn) {
    state.showStopBtn = showStopBtn
  },

  setObjective(state, objective) {
    state.objective = objective
  },

  setTaskList(state, taskList) {
    state.taskList = taskList
  },

  setHasNextPage(state, hasNextPage) {
    state.hasNextPage = hasNextPage
  },

  setChatHistoryCurrentPage(state, pageNo) {
    state.chatHistoryCurrentPage = pageNo
  },

  setShowQuickStarts(state, status) {
    state.showQuickStarts = status
  },

  updateThread(state, thread) {
    if (!thread || !thread.clarifications) {
      return
    }

    let idx = -1
    for (let i = 0; i < state.chatHistoryRepo.length; i++) {
      if (thread.id === state.chatHistoryRepo[i].id) {
        idx = i
        break
      }
    }
    if (idx > 0) state.chatHistoryRepo[idx] = thread

    idx = -1
    for (let i = 0; i < state.chatHistory.length; i++) {
      if (thread.id === state.chatHistory[i].id) {
        idx = i
        break
      }
    }
    if (idx > 0) state.chatHistory[idx] = thread
  },
}
