import { watch } from "vue"
import routerNames from "@/configs/routerNames"
import auth0 from "@/plugins/auth0.plugin"

/** @type {import("vue-router").NavigationGuardWithThis} */
export const redirectLoggedInUsers = (to, from, next) => {
  const verify = () => {
    if (auth0.isAuthenticated.value) {
      next({ name: routerNames.DEFAULT_LANDING_PAGE })
    } else {
      next()
    }
  }

  if (!auth0.isLoading.value) {
    return verify()
  }

  const unwatch = watch(auth0.isLoading, (loading) => {
    if (!loading) {
      unwatch()
      verify()
    }
  })
}
