<script setup>
import { computed } from "vue"
import { useStore } from "vuex"
import { GETTERS } from "@/store/constants"
import routerNames from "@/configs/routerNames"
import UserIcon from "@/components/Icons/UserIcon.vue"
import { useAuth0 } from "@auth0/auth0-vue"

const navLinks = [
  {
    name: "About Us",
    link: "https://helloscribe.ai/home/helloscribe-about-us",
  },
  {
    name: "Pricing",
    link: window.origin + "/onboarding/pricing",
  },
  {
    name: "Contact",
    link: "https://helloscribe.ai/home/contact-us",
  },
]

const store = useStore()
const user = computed(() => store.getters[GETTERS.USER])
const auth0 = useAuth0()
</script>

<template>
  <nav
    class="z-[100] fixed top-0 right-0 left-0 flex flex-row justify-between items-center px-4 md:px-[10vw] h-[50px] md:h-[65px] bg-bgNav-light dark:bg-bgNav-dark shadow border-b border-transparent dark:border-border-dark"
  >
    <BrandLogo3d class="h-[38px] hidden md:block" />
    <BrandLogo3dIcon class="h-[30px] md:hidden" />

    <div class="w-max flex flex-row justify-center items-center gap-4">
      <a
        v-for="{ name, link } in navLinks"
        :key="link"
        :href="link"
        class="text-bodyS md:text-bodyL cursor-pointer hover:text-primary"
        target="_blank"
        rel="noreferrer"
      >
        {{ name }}
      </a>
    </div>

    <BaseButton
      v-if="user || auth0.user"
      size="xs"
      variant="outline"
      @click="$router.push({ name: routerNames.LOGOUT })"
    >
      Logout
    </BaseButton>
    <BaseButton
      v-else
      size="xs"
      variant="outline"
      @click="$router.push({ name: routerNames.LOGIN })"
    >
      <UserIcon class="w-[14px] h-[14px]" />
      Login
    </BaseButton>
  </nav>
</template>
