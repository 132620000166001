<script setup></script>

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="w-auto h-auto stroke-inherit"
  >
    <path
      d="M15.8192 13.3292L10.4952 19.3192C10.3076 19.5304 10.0773 19.6994 9.81964 19.8151C9.56196 19.9309 9.28268 19.9907 9.0002 19.9907C8.71772 19.9907 8.43845 19.9309 8.18077 19.8151C7.92309 19.6994 7.69285 19.5304 7.5052 19.3192L2.1812 13.3292C1.85563 12.9631 1.67578 12.4902 1.67578 12.0002C1.67578 11.5103 1.85563 11.0374 2.1812 10.6712L7.5052 4.68124C7.69285 4.47009 7.92309 4.30108 8.18077 4.18534C8.43845 4.0696 8.71772 4.00977 9.0002 4.00977C9.28268 4.00977 9.56196 4.0696 9.81964 4.18534C10.0773 4.30108 10.3076 4.47009 10.4952 4.68124L15.8192 10.6712C16.1448 11.0374 16.3246 11.5103 16.3246 12.0002C16.3246 12.4902 16.1448 12.9631 15.8192 13.3292Z"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 6.37424L13.505 4.68124C13.6926 4.47009 13.9229 4.30108 14.1806 4.18534C14.4382 4.0696 14.7175 4.00977 15 4.00977C15.2825 4.00977 15.5618 4.0696 15.8194 4.18534C16.0771 4.30108 16.3074 4.47009 16.495 4.68124L21.819 10.6712C22.1443 11.0373 22.324 11.51 22.324 11.9997C22.324 12.4895 22.1443 12.9622 21.819 13.3282L16.495 19.3182C16.3074 19.5294 16.0771 19.6984 15.8194 19.8141C15.5618 19.9299 15.2825 19.9897 15 19.9897C14.7175 19.9897 14.4382 19.9299 14.1806 19.8141C13.9229 19.6984 13.6926 19.5294 13.505 19.3182L12 17.6232"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
