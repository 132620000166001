import routerNames from "@/configs/routerNames"
import { authGuard } from "@auth0/auth0-vue"
import PageLayout from "@/layouts/PageLayout.vue"
import AllToolTemplatesLayout from "@/layouts/AllToolTemplatesLayout.vue"
import ToolsLayout from "@/layouts/ToolsLayout.vue"
import { TOOL_TYPE } from "@/constants/ToolType"
import {FIRST_DRAFT_TYPES } from "@/constants/firstDraftTypes"

/**
 * @name authRoutes
 * @type {import("vue-router").RouteRecordRaw}
 */
export default {
  path: "/writing",
  beforeEnter: authGuard,
  component: PageLayout,
  children: [
    {
      path: "",
      name: routerNames.DEFAULT_LANDING_PAGE,
      redirect: {
        name: routerNames.AGENT_MODE,
        query: {
          tool: FIRST_DRAFT_TYPES.ARTICLE_WRITER,
        },
        params: {
          category: TOOL_TYPE.FIRSTDRAFT,
        },
      },
    },
    {
      path: "all",
      component: AllToolTemplatesLayout,
      children: [
        {
          path: "tools",
          name: routerNames.ALL_TOOLS,
          component: () => import("@/modules/writing/views/ToolsListView.vue"),
          meta: { keepAlive: true },
        },
        {
          path: "templates",
          name: routerNames.ALL_TEMPLATES,
          component: () =>
            import("@/modules/writing/views/TemplatesListView.vue"),
          meta: { keepAlive: true },
        },
      ],
    },

    {
      path: "general",
      component: ToolsLayout,
      children: [
        {
          path: ":category",
          children: [
            {
              path: "",
              name: routerNames.FIRSTDRAFT,
              component: () =>
                import("@/modules/writing/views/ArticleEditorView.vue"),
              meta: {
                keepAlive: true,
                transition: 'fade'
              },
            },
            {
              path: "tools",
              name: routerNames.TOOL_MODE,
              component: () =>
                import("@/modules/writing/views/CombinedToolsView.vue"),
              meta: {
                keepAlive: true,
                transition: 'fade'
              },
            },
            {
              path: "chat",
              name: routerNames.AGENT_MODE,
              component: () =>
                import("@/modules/writing/views/AgentModeView.vue"),
              meta: {
                keepAlive: true,
                transition: 'fade'
              },
            },
          ],
        },
      ],
    },
  ],
}