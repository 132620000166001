const ADVERTISING_IDEA = "advertising-idea"
const AIDA = "aida"
const AMAZON_ADS = "amazon-ads"
const BAB = "bab"
const BILLBOARD_COPY = "billboard-copy"
const COPY_FREESTYLE = "copy-freestyle"
const FAB = "fab"
const FACEBOOK_ADS = "facebook-ads"
const GENERAL_COPY = "general-copy"
const GOOGLE_ADS = "google-ads"
const HEADLINE_SLOGAN = "headline-slogan"
const LANDING_PAGE = "landing-page"
const LINKEDIN_AD = "linkedin-ad"
const MAGAZINE_AD = "magazine-ad"
const MICRO_COPY = "micro-copy"
const PAS = "pas"
const PRINT_AD = "print-ad"
const RADIO_AD = "radio-ad"
const TAGLINE = "tagline-writer"
const VIDEO_SCRIPT = "video-script"

export const PROMOTE_TYPES = {
  HEADLINE_SLOGAN,
  TAGLINE,
  VIDEO_SCRIPT,
  BILLBOARD_COPY,
  PRINT_AD,
  GENERAL_COPY,
  AIDA,
  PAS,
  BAB,
  FAB,
  GOOGLE_ADS,
  FACEBOOK_ADS,
  AMAZON_ADS,
  RADIO_AD,
  MAGAZINE_AD,
  COPY_FREESTYLE,
  ADVERTISING_IDEA,
  LINKEDIN_AD,
  MICRO_COPY,
  LANDING_PAGE,
}

export const PROMOTE_TYPE_LABELS = {
  [AIDA]: "AIDA (Attention-Interest-Desire-Action)",
  [AMAZON_ADS]: "Amazon Ad",
  [RADIO_AD]: "Audio Script (Ad)",
  [ADVERTISING_IDEA]: "Big Idea",
  [BILLBOARD_COPY]: "Billboard Ad",
  [COPY_FREESTYLE]: "Copy Freestyle (Write Anything)",
  [FAB]: "FAB (Feature-To-Benefit)",
  [FACEBOOK_ADS]: "Facebook Ad",
  [GOOGLE_ADS]: "Google Ad",
  [HEADLINE_SLOGAN]: "Headline + Slogan",
  [LANDING_PAGE]: "Landing Page",
  [LINKEDIN_AD]: "LinkedIn Ad",
  [MAGAZINE_AD]: "Magazine Ad",
  [MICRO_COPY]: "Micro Copy",
  [PRINT_AD]: "Print Ad Concept",
  [GENERAL_COPY]: "Product Description",
  [BAB]: "BAB (Before-After-Bridge)",
  [PAS]: "PAS (Problem-Agitate-Solve)",
  [TAGLINE]: "Tagline",
  [VIDEO_SCRIPT]: "TV Ad Copy",
}

export const PROMOTE_TYPE_PLACEHOLDERS = {
  [AMAZON_ADS]:
    " e.g. Ad for a productivity widget. The Objective is to get people to buy the product. The target audience is impulse buyers who want to get more done in less time.",
  [BILLBOARD_COPY]:
    "e.g. Billboard Ad for a campaign on the future of work. The Objective is to get people to sign up for an online course on productivity. The target audience is professionals who want to get more done in less time.",
  [COPY_FREESTYLE]:
    "e.g. Write ad copy for a campaign on the future of work. The Objective is to get people to sign up for a free trial of a new productivity tool. The target audience is professionals who want to get more done in less time.",
  [FACEBOOK_ADS]:
    " e.g. Write an ad for a productivity widget that helps professionals get more done in less time.",
  [GOOGLE_ADS]:
    "e.g. Ad for an online course about the future of work. The target audience is professionals who want to get more done in less time.",
  [HEADLINE_SLOGAN]:
    "e.g. Headline and slogan for a campaign on the future of work. The Objective is to get people to sign up for a free trial of a new productivity tool. The target audience is professionals who want to get more done in less time.",
  [MAGAZINE_AD]:
    "e.g. Ad about the future of work. The Objective is to get people to sign up for an online course on productivity. The target audience is professionals who read high-end business publications and are interested in learning how to get more done in less time.",
  [PRINT_AD]:
    "e.g.Print concept for a campaign on the future of work. The Objective is to get people to sign up for an online course on productivity. The target audience is professionals who want to get more done in less time.",
  [GENERAL_COPY]:
    "e.g. Description for a new productivity tool. The Objective is to get people to purchase a subscriptoion. The target audience is professionals who want to get more done in less time.",
  [RADIO_AD]:
    "e.g. Ad for a new productivity tool. The Objective is to get you to start a free trial. The target audience is professionals who want to get more done in less time.",
  [TAGLINE]:
    "e.g. Tagline for a new productivity tool that helps you to get more done in less time.",
  [VIDEO_SCRIPT]:
    "e.g. Script for a new productivity tool that helps you to get more done in less time.",
  [AIDA]:
    "e.g. Ad for a productivity tool that helps you to get more done in less time.",
  [BAB]:
    "e.g. Ad for a productivity tool that helps you to get more done in less time.",
  [FAB]:
    "e.g. Ad for a productivity tool that helps you to get more done in less time.",
  [PAS]:
    "e.g. Ad for a productivity tool that helps you to get more done in less time.",
  [LINKEDIN_AD]:
    "e.g. Ad for a productivity tool that helps you to get more done in less time.",
  [MICRO_COPY]:
    "e.g. Write placeholder text for a call to action on an abandoned cart page.",
  [ADVERTISING_IDEA]: "Enter campaign brief to generate a big idea",
  [LANDING_PAGE]: "Enter your campaign brief to generate a landing page",
}
