export const setSidebarExpanded = (state, value) => {
  state.sidebarExpanded = value
}

export const setUser = (state, user) => {
  state.user = user ? {
    ...user,
    isNewUser: user.isNewUser ?? false,
    email_verified: user.email_verified ?? false
  } : null
}

export const setAccount = (state, account) => {
  state.account = account
}

export const setAdminAccount = (state, acc) => {
  state.adminAccount = acc
}

export const setFetchingData = (state, value) => {
  state.fetchingData = value
}

export const setTemplates = (state, templates) => {
  state.allTemplates = templates
}

export const updateTemplatesList = (state, templates) => {
  state.allTemplates = [...state.allTemplates, ...templates]
}

// export const setSelectedTemplate = (state, template) => {
//   state.selectedTemplate = template
// }

export const setSelectedTool = (state, tool) => {
  state.selectedTool = tool
}

export const setNotes = (state, notes) => {
  state.allNotes = notes
}

export const setSubscription = (state, subscription) => {
  if (subscription) {
    // set the appSumo subscription to null if the user is a helloscribe subscriber
    state.appSumoSubscription = null
  }
  state.subscription = subscription
}

export const setAppSumoSubscription = (state, subscription) => {
  if (subscription) {
    // set the subscription to null if the user is a sumoling
    state.subscription = null
  }
  state.appSumoSubscription = subscription
}

export const setGenerating = (state, value) => {
  state.generating = value
}

export const setSelectedTags = (state, tags) => {
  state.selectedTags = tags
}

export const setAllTags = (state, tags) => {
  state.allTags = tags
}

export const setShowTutorialModal = (state, value) => {
  state.showTutorialModal = value
}

export const setTutorialLink = (state, link) => {
  state.tutorialLink = link
}

export const setAlerts = (state, alerts) => {
  state.alerts = alerts
}

export const setAllTools = (state, tools) => {
  state.allTools = tools
}

export const setDarkMode = (state, darkMode) => {
  state.darkMode = darkMode
}

export const setUserCards = (state, cards) => {
  state.cards = cards
}

export const setUserTeam = (state, team) => {
  state.team = team
}

export function setTokenInfo(state, tokenInfo) {
  state.tokenInfo = tokenInfo
}

export function setAuthErr(state, err) {
  state.authErr = err
}

export const setUserEmailVerified = (state, verified) => {
  if (state.user) {
    state.user.email_verified = verified
    if (verified) {
      state.user.isNewUser = false
    }
  }
}