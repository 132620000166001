import {
  convertLineBreaks,
  clickableStringForDynamicEditor,
} from "@/utils/stringUtils"
import { ALL_TOOLS_LIST } from "@/constants/allToolsList"
import { TOOL_TYPE } from "./ToolType"

/**
 * @type {Template[]}
 */
const defaultTemplates = [
  {
    tool: "Copy Freestyle",
    name: "Sales Landing Page",
    description:
      "Write a sales landing page\n\nProduct: [product/brand/service name and one-liner]\n\nTarget audience: [target audience]\n\nFeatures:\n[list of features that solve problems or provide value]\n[feature 2]\n[feature 3]\n\nBenefits:\n[list of benefits]\n[benefit 2]\n[benefit 3]\n",
    tones: "",
    category: TOOL_TYPE.ADCOPY, // "Copywriter Pro",
    _id: "SalesLandingPage",
    pinned: false,
  },
  {
    tool: "Copy Freestyle",
    name: "About Us Page",
    description:
      "Brand: Write an About Us page \n\n [product/brand/service name and one-liner]\n\nTarget audience: [target audience]\n\nMission:\n[why did you start the company]\n[what customers are you serving]\n[what problem are you trying to solve]\n[how are you trying to solve the problem]",
    tones: "",
    category: TOOL_TYPE.ADCOPY, // "Copywriter Pro",
    _id: "AboutUsPage",
    pinned: false,
  },
  {
    tool: "Brainstorm Unlimited",
    name: "Diplomatic Note",
    description:
      'Write a diplomatic noteTitle \n\n [Title of the note, e.g., "Diplomatic Note" or "Note Verbale"]\n\nHeader:\n[Name of issuing country/organization]\n[Date of issuance]\n[Addressee]\n\nPurpose:\n[Clear statement of purpose, e.g., "To request a meeting with the ambassador" or "To express concern over recent events"]\nBody:\n[Detailed explanation of the purpose, including any supporting information or background]\n[Any specific requests or proposals]\n\nClosing:\n[Closing remark, e.g., "Thank you for your attention to this matter"]\n[Signature and title of the author]',
    tones: "",
    category: TOOL_TYPE.BRAINSTORM, // "Brainstorm",
    _id: "DiplomaticNote",
    pinned: false,
  },
  {
    tool: "Brainstorm Unlimited",
    name: "Creative Brief",
    description:
      "Write a creative brief:\n\nTitle: [Name of the Project]\n\nObjectives:\n[What are the primary goals of the project?]\n[What are the secondary goals or desired outcomes?]\n\nTarget Audience:\n[Who are we trying to reach with this project?]\n[What are the characteristics of the target audience?]\n\nKey Message:\n[What is the main message we want to communicate?]\n[What is the tone or style of the message?]\n\nDeliverables:\n[What specific deliverables are required for the project?]\n[What is the timeline for each deliverable?]\n\nBudget:\n[What is the total budget for the project?]\n[How will the budget be allocated?]\n\nCreative Considerations:\n[What are the visual or design considerations?]",
    tones: "",
    category: TOOL_TYPE.BRAINSTORM, // "Brainstorm",
    _id: "CreativeBrief",
    pinned: false,
  },
  {
    tool: "Brainstorm Unlimited",
    name: "Case Study Overview",
    description:
      "Write a case study overview:\n\nBusiness: [your business name and description]\n\nCustomer: [the customer/client's name and business description]\n\nProblem statement: [customer's pain points]\n\nSolution: [customer's solution to their problem]\n\nResult: [list the key metrics and improvements]\n\nWritten in the 3rd person.",
    tones: "",
    category: TOOL_TYPE.BRAINSTORM, // "Brainstorm",
    _id: "CaseStudyOverview",
    pinned: false,
  },
  {
    tool: "Copy Freestyle",
    name: "Webinar Promotion",
    description:
      "Write a Webinar Promotion.\n\nTitle: [webinar title]\n\nPresenter: [presenter name and title]\n\nDate and Time: [date and time of the webinar]\n\nDuration: [length of the webinar]\n\nDescription: [brief overview of what the webinar will cover]\n\nTarget audience: [who the webinar is intended for]",
    tones: "",
    category: TOOL_TYPE.ADCOPY, // "Copywriter Pro",
    _id: "WebinarPromotion",
    pinned: false,
  },
  {
    tool: "Copy Freestyle",
    name: "Webinar Introduction",
    description:
      "Write a Webinar Introduction.\n\nTitle: [webinar title]\n\nPresenter: [presenter name and title]\n\nDescription: [brief overview of what the webinar will cover]",
    tones: "",
    category: TOOL_TYPE.ADCOPY,
    _id: "WebinarIntroduction",
    pinned: false,
  },
  {
    tool: "Social Post",
    name: "Twitter Bio",
    description:
      "Write a Twitter Bio\nName: [name]\nA few key points:\n[bullet points]\nWhat you want the reader to do: [example: follow me on]",
    tones: "",
    category: TOOL_TYPE.SOCIALMEDIA, // "Social Media",
    _id: "TwitterBio",
    pinned: false,
  },
  {
    tool: "Songwriter",
    name: "Write a Song",
    description:
      "Genre: [song genre]\nTopic: [topic or themes of the song]\nAdditional instructions: [example: Make it catchy]",
    tones: "",
    category: TOOL_TYPE.ENTERTAINMENT, // "Creative Writing",
    _id: "WriteASong",
    pinned: false,
  },
  {
    tool: "Brainstorm Unlimited",
    name: "Executive Summary",
    description:
      'Write an executive summary: \n\n Purpose: [State the purpose of the document. Example: "Report on market research for new product launch."]\n\nKey Findings: [Highlight the most important points from the document. Example: "High demand for proposed product with estimated market size of $5B."]\n\nRecommendations: [Provide specific recommendations for action. Example: "Launch new product in Q3 2022 with $1.5M marketing budget."]\n\nAction Plan: [Outline a plan for implementing the recommendations. Example: "Develop marketing campaign, secure distribution channels, conduct pilot test."]\n\nBenefits: [List the benefits of following the recommended action plan. Example: "Increase revenue by 20%, position as market leader."]\n\nConclusion: [Summarize main points and importance of taking action. Example: "Strong business case for launching new product and following recommended action plan for significant growth."]',
    tones: "",
    category: TOOL_TYPE.BRAINSTORM, // "Brainstorm",
    _id: "ExecutiveSummary",
    pinned: false,
  },
]

export const getDefaultTemplates = () => {
  const templates = defaultTemplates.map((template) => {
    let descriptionHTML = convertLineBreaks(template.description)
    descriptionHTML = clickableStringForDynamicEditor(descriptionHTML)

    let toolId = ""
    for (let i = 0; i < ALL_TOOLS_LIST.length; i++) {
      const tool = ALL_TOOLS_LIST[i]
      if (!tool.name || typeof tool.name !== "string") continue
      const rgx = new RegExp(template.tool, "gi")
      // const match = tool.name.includes(template.tool)
      const match = tool.name.match(rgx)
      if (match) {
        toolId = tool.id
        break
      }
    }
    return {
      ...template,
      toolId: toolId,
      descriptionHTML,
      createdAt: new Date(1677628800000),
      updatedAt: new Date(1677628800000),
    }
  })
  return templates
}
