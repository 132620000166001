export const sortByCreatedAtAscending = (a, b) => {
  const dateA = new Date(a.createdAt)
  const dateB = new Date(b.createdAt)
  return dateA - dateB
}

export const sortByCreatedAtDescending = (a, b) => {
  const dateA = new Date(a.createdAt)
  const dateB = new Date(b.createdAt)
  return dateB - dateA
}

export const sortByUpdatedAtAscending = (a, b) => {
  const dateA = new Date(a.updatedAt)
  const dateB = new Date(b.updatedAt)
  return dateA - dateB
}

export const sortByUpdatedAtDescending = (a, b) => {
  const dateA = new Date(a.updatedAt)
  const dateB = new Date(b.updatedAt)
  return dateB - dateA
}
