import { TOOL_TYPE_ICONS, TOOL_TYPE } from "@/constants/ToolType"
import {
  BRAINSTORM_TYPES,
  BRAINSTORM_TYPE_LABELS,
  BRAINSTORM_TYPE_PLACEHOLDERS,
} from "@/constants/brainstormTypes"
import {
  CORRESPONDENCE_TYPES,
  CORRESPONDENCE_TYPE_LABELS,
  CORRESPONDENCE_TYPE_PLACEHOLDERS,
} from "@/constants/correspondenceTypes"
import {
  ENTERTAINMENT_TYPES,
  ENTERTAINMENT_TYPES_LABELS,
  ENTERTAINMENT_TYPES_PLACEHOLDERS,
} from "@/constants/entertainmentTypes"
import {
  HEADLINE_TYPES,
  HEADLINE_TYPE_LABELS,
  HEADLINE_TYPE_PLACEHOLDERS,
} from "@/constants/headlineTypes"
import {
  MESSAGE_TYPES,
  MESSAGE_TYPE_LABELS,
  MESSAGE_TYPE_PLACEHOLDERS,
} from "@/constants/messageTypes"
import {
  OUTREACH_TYPES,
  OUTREACH_TYPES_PLACEHOLDERS,
} from "@/constants/outreachTypes"
import {
  PROMOTE_TYPES,
  PROMOTE_TYPE_LABELS,
  PROMOTE_TYPE_PLACEHOLDERS,
} from "@/constants/promoteTypes"
import {
  QUESTION_TYPE,
  QUESTION_TYPE_LABELS,
  QUESTION_TYPE_PLACEHOLDERS,
} from "@/constants/questionTypes"
import {
  QUOTE_TYPE,
  QUOTE_TYPE_LABELS,
  QUOTE_TYPE_PLACEHOLDERS,
} from "@/constants/quoteTypes"
import {
  SOCIAL_MEDIA_TYPES,
  SOCIAL_MEDIA_TYPE_LABELS,
  SOCIAL_MEDIA_TYPE_PLACEHOLDERS,
} from "@/constants/socialMediaTypes"
import {
  SUMMARY_TYPES,
  SUMMARY_TYPE_LABELS,
  SUMMARY_TYPE_PLACEHOLDERS,
} from "@/constants/summaryTypes"
import { FIRST_DRAFT_TYPES } from "./firstDraftTypes"

export const ALL_TOOLS_LIST = [
  {
    id: FIRST_DRAFT_TYPES.ARTICLE_WRITER,
    name: TOOL_TYPE.FIRSTDRAFT,
    placeholder: SUMMARY_TYPE_PLACEHOLDERS[SUMMARY_TYPES.FIRSTDRAFT],
    description:
      "From a simple outline to a fully-fledged article, let your thoughts be translated into engaging, well-structured first draft.",
    category: TOOL_TYPE.FIRSTDRAFT,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.FIRSTDRAFT],
  },
  {
    id: OUTREACH_TYPES.MEDIA_PITCH,
    name: OUTREACH_TYPES.MEDIA_PITCH,
    placeholder: OUTREACH_TYPES_PLACEHOLDERS[OUTREACH_TYPES.MEDIA_PITCH],
    description:
      "A crafted media pitch is crucial for publicity for your business, product, or event. This tool grabs journalist attention and convinces them to cover your story. From product launches to charitable initiatives, a compelling media pitch boosts exposure and leads to media coverage.",
    category: TOOL_TYPE.MEDIAPITCH,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.MEDIAPITCH],
  },
  {
    id: QUOTE_TYPE.QUOTE,
    name: QUOTE_TYPE_LABELS[QUOTE_TYPE.QUOTE],
    placeholder: QUOTE_TYPE_PLACEHOLDERS[QUOTE_TYPE.QUOTE],
    description:
      "Quotes are powerful tools that inspire, motivate, amuse, or provoke thought. Use this tool to share insights, wisdom, humor, or messages that resonate with your audience. Whether it's a social media post, presentation slide, or email signature, a great quote leaves a lasting impression.",
    category: TOOL_TYPE.QUOTES,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.QUOTES],
  },
  {
    id: PROMOTE_TYPES.AIDA,
    name: PROMOTE_TYPE_LABELS[PROMOTE_TYPES.AIDA],
    placeholder: PROMOTE_TYPE_PLACEHOLDERS[PROMOTE_TYPES.AIDA],
    description:
      "AIDA, an advertising framework for Attention, Interest, Desire, and Action, guides ad copy to grab attention, pique interest, generate desire, and prompt action.",
    category: TOOL_TYPE.ADCOPY,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.ADCOPY],
  },
  {
    id: PROMOTE_TYPES.AMAZON_ADS,
    name: PROMOTE_TYPE_LABELS[PROMOTE_TYPES.AMAZON_ADS],
    placeholder: PROMOTE_TYPE_PLACEHOLDERS[PROMOTE_TYPES.AMAZON_ADS],
    description:
      "Create compelling Amazon ad copy that drives customers to click 'Add to Cart'. This tool is designed to help you highlight your product's unique features, benefits, and value, making it stand out in the vast Amazon marketplace.",
    category: TOOL_TYPE.ADCOPY,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.ADCOPY],
  },
  {
    id: PROMOTE_TYPES.RADIO_AD,
    name: PROMOTE_TYPE_LABELS[PROMOTE_TYPES.RADIO_AD],
    placeholder: PROMOTE_TYPE_PLACEHOLDERS[PROMOTE_TYPES.RADIO_AD],
    description:
      "Audio ads require careful crafting since you rely solely on sound. With this tool, create engaging scripts that resonate with listeners, prompting them to remember your brand, product, or service. Craft memorable jingles, messages, and powerful calls to action.",
    category: TOOL_TYPE.ADCOPY,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.ADCOPY],
  },
  {
    id: PROMOTE_TYPES.BAB,
    name: PROMOTE_TYPE_LABELS[PROMOTE_TYPES.BAB],
    placeholder: PROMOTE_TYPE_PLACEHOLDERS[PROMOTE_TYPES.BAB],
    description:
      "Demonstrate the transformative power of your product by highlighting the before and after benefits. Ideal for use in product descriptions, social media posts, or any promotional content where you want to illustrate the positive change your product can bring.",
    category: TOOL_TYPE.ADCOPY,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.ADCOPY],
  },
  {
    id: PROMOTE_TYPES.ADVERTISING_IDEA,
    name: PROMOTE_TYPE_LABELS[PROMOTE_TYPES.ADVERTISING_IDEA],
    placeholder: PROMOTE_TYPE_PLACEHOLDERS[PROMOTE_TYPES.ADVERTISING_IDEA],
    description:
      "Every great marketing campaign starts with a big idea. This tool assists you in developing unique, creative concepts that set your advertising campaigns apart, igniting interest and driving engagement.",
    category: TOOL_TYPE.ADCOPY,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.ADCOPY],
  },
  {
    id: PROMOTE_TYPES.BILLBOARD_COPY,
    name: PROMOTE_TYPE_LABELS[PROMOTE_TYPES.BILLBOARD_COPY],
    placeholder: PROMOTE_TYPE_PLACEHOLDERS[PROMOTE_TYPES.BILLBOARD_COPY],
    description:
      "Crafting effective billboard copy requires conciseness and impact. This tool helps you create short, memorable messages, and image suggestions that catch the eye and leave a lasting impression, even at high speed.",
    category: TOOL_TYPE.ADCOPY,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.ADCOPY],
  },
  {
    id: PROMOTE_TYPES.FAB,
    name: PROMOTE_TYPE_LABELS[PROMOTE_TYPES.FAB],
    placeholder: PROMOTE_TYPE_PLACEHOLDERS[PROMOTE_TYPES.FAB],
    description:
      "FAB stands for Features, Advantages, and Benefits. This tool assists you in translating your product's features into benefits that resonate with your customers, demonstrating how your product can meet their needs and solve their problems.",
    category: TOOL_TYPE.ADCOPY,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.ADCOPY],
  },

  {
    id: PROMOTE_TYPES.FACEBOOK_ADS,
    name: PROMOTE_TYPE_LABELS[PROMOTE_TYPES.FACEBOOK_ADS],
    placeholder: PROMOTE_TYPE_PLACEHOLDERS[PROMOTE_TYPES.FACEBOOK_ADS],
    description:
      "Shape compelling Facebook ads that effectively target and engage your desired audience. Use it to drive awareness, increase customers, and share your story amongst a broad audience or just a select few.",
    category: TOOL_TYPE.ADCOPY,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.ADCOPY],
  },
  {
    id: PROMOTE_TYPES.COPY_FREESTYLE,
    name: PROMOTE_TYPE_LABELS[PROMOTE_TYPES.COPY_FREESTYLE],
    placeholder: PROMOTE_TYPE_PLACEHOLDERS[PROMOTE_TYPES.COPY_FREESTYLE],
    description:
      "Get creative with freestyle ad copy that stands out. Use this tool to craft engaging, unique advertisements that reflect the personality of your brand and captivate your target audience.",
    category: TOOL_TYPE.ADCOPY,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.ADCOPY],
  },
  {
    id: PROMOTE_TYPES.GENERAL_COPY,
    name: PROMOTE_TYPE_LABELS[PROMOTE_TYPES.GENERAL_COPY],
    placeholder: PROMOTE_TYPE_PLACEHOLDERS[PROMOTE_TYPES.GENERAL_COPY],
    description:
      "Create an effective blend of language, tone, and message to drive sales and conversions. Use this tool to compose ad copy that persuades and compels, regardless of the medium or platform.",
    category: TOOL_TYPE.ADCOPY,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.ADCOPY],
  },
  {
    id: PROMOTE_TYPES.GOOGLE_ADS,
    name: PROMOTE_TYPE_LABELS[PROMOTE_TYPES.GOOGLE_ADS],
    placeholder: PROMOTE_TYPE_PLACEHOLDERS[PROMOTE_TYPES.GOOGLE_ADS],
    description:
      "Write ad copy that will put your business at the top of Google search results. Use this tool to generate persuasive, concise ads that enhance your online presence and drive traffic to your website.",
    category: TOOL_TYPE.ADCOPY,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.ADCOPY],
  },
  {
    id: PROMOTE_TYPES.HEADLINE_SLOGAN,
    name: PROMOTE_TYPE_LABELS[PROMOTE_TYPES.HEADLINE_SLOGAN],
    placeholder: PROMOTE_TYPE_PLACEHOLDERS[PROMOTE_TYPES.HEADLINE_SLOGAN],
    description:
      "Create memorable headlines and slogans that encapsulate your brand's essence and mission. Use this tool to formulate short, catchy phrases that resonate with your audience and reinforce brand recognition.",
    category: TOOL_TYPE.ADCOPY,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.ADCOPY],
  },
  {
    id: PROMOTE_TYPES.LANDING_PAGE,
    name: PROMOTE_TYPE_LABELS[PROMOTE_TYPES.LANDING_PAGE],
    placeholder: PROMOTE_TYPE_PLACEHOLDERS[PROMOTE_TYPES.LANDING_PAGE],
    description:
      "Design a landing page that captures and converts. Use this tool to compose compelling content that guides visitors towards your call to action, whether that's signing up for a newsletter, downloading an ebook, or purchasing a product.",
    category: TOOL_TYPE.ADCOPY,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.ADCOPY],
  },
  {
    id: PROMOTE_TYPES.LINKEDIN_AD,
    name: PROMOTE_TYPE_LABELS[PROMOTE_TYPES.LINKEDIN_AD],
    placeholder: PROMOTE_TYPE_PLACEHOLDERS[PROMOTE_TYPES.LINKEDIN_AD],
    description:
      "Sculpt professional, engaging ads that resonate with LinkedIn's audience of business professionals. Use this tool to generate copy that showcases your company's expertise, builds brand awareness, and drives qualified leads.",
    category: TOOL_TYPE.ADCOPY,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.ADCOPY],
  },
  {
    id: PROMOTE_TYPES.MAGAZINE_AD,
    name: PROMOTE_TYPE_LABELS[PROMOTE_TYPES.MAGAZINE_AD],
    placeholder: PROMOTE_TYPE_PLACEHOLDERS[PROMOTE_TYPES.MAGAZINE_AD],
    description:
      "Create compelling magazine ads that capture readers' attention and imagination. Use this tool to develop copy that combines storytelling and persuasion, promoting your products or services in a way that resonates with the magazine's readership.",
    category: TOOL_TYPE.ADCOPY,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.ADCOPY],
  },
  {
    id: PROMOTE_TYPES.MICRO_COPY,
    name: PROMOTE_TYPE_LABELS[PROMOTE_TYPES.MICRO_COPY],
    placeholder: PROMOTE_TYPE_PLACEHOLDERS[PROMOTE_TYPES.MICRO_COPY],
    description:
      "Craft concise, user-friendly microcopy to enhance your digital platforms. Use this tool to guide users through your website or app with intuitive instructions, witty error messages, and persuasive call-to-actions.",
    category: TOOL_TYPE.ADCOPY,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.ADCOPY],
  },
  {
    id: PROMOTE_TYPES.PAS,
    name: PROMOTE_TYPE_LABELS[PROMOTE_TYPES.PAS],
    placeholder: PROMOTE_TYPE_PLACEHOLDERS[PROMOTE_TYPES.PAS],
    description:
      "Develop compelling ads using the Problem-Agitate-Solve (PAS) formula. Use this tool to identify a problem, aggravate that problem, and then present your product or service as the perfect solution.",
    category: TOOL_TYPE.ADCOPY,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.ADCOPY],
  },
  {
    id: PROMOTE_TYPES.PRINT_AD,
    name: PROMOTE_TYPE_LABELS[PROMOTE_TYPES.PRINT_AD],
    placeholder: PROMOTE_TYPE_PLACEHOLDERS[PROMOTE_TYPES.PRINT_AD],
    description:
      "Convey your brand message in a creative and memorable print ad. Use this when designing a print advertisement, like depicting your product's benefits in a clever visual or telling a powerful story with minimal text.",
    category: TOOL_TYPE.ADCOPY,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.ADCOPY],
  },
  {
    id: PROMOTE_TYPES.TAGLINE,
    name: PROMOTE_TYPE_LABELS[PROMOTE_TYPES.TAGLINE],
    placeholder: PROMOTE_TYPE_PLACEHOLDERS[PROMOTE_TYPES.TAGLINE],
    description:
      "Ideal for creating a punchy and memorable phrase that encapsulates your brand's essence. This tool is particularly useful when branding or rebranding your business, and can help elevate the perception of your products or services.",
    category: TOOL_TYPE.ADCOPY,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.ADCOPY],
  },
  {
    id: PROMOTE_TYPES.VIDEO_SCRIPT,
    name: PROMOTE_TYPE_LABELS[PROMOTE_TYPES.VIDEO_SCRIPT],
    placeholder: PROMOTE_TYPE_PLACEHOLDERS[PROMOTE_TYPES.VIDEO_SCRIPT],
    description:
      "This tool aids in writing captivating video scripts to enhance your ad campaigns or promotional videos. It helps breathe life into your brand story, making your videos more engaging and enticing to viewers.",
    category: TOOL_TYPE.ADCOPY,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.ADCOPY],
  },
  {
    id: SUMMARY_TYPES.BLOG_INTRO,
    name: SUMMARY_TYPE_LABELS[SUMMARY_TYPES.BLOG_INTRO],
    placeholder: SUMMARY_TYPE_PLACEHOLDERS[SUMMARY_TYPES.BLOG_INTRO],
    description:
      "Ideal for drafting a compelling introduction that hooks your readers from the outset. This tool is essential for blog writers looking to increase reader engagement and set the tone for the rest of the article.",
    category: TOOL_TYPE.SUMMARY,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.SUMMARY],
  },
  {
    id: SUMMARY_TYPES.BLOG_OUTLINE,
    name: SUMMARY_TYPE_LABELS[SUMMARY_TYPES.BLOG_OUTLINE],
    placeholder: SUMMARY_TYPE_PLACEHOLDERS[SUMMARY_TYPES.BLOG_OUTLINE],
    description:
      "Useful for structuring your blog post effectively and ensuring that your ideas flow coherently. This tool provides a clear roadmap for your writing, helping you stay focused and organized throughout the process.",
    category: TOOL_TYPE.SUMMARY,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.SUMMARY],
  },
  {
    id: SUMMARY_TYPES.BULLET_POINT,
    name: SUMMARY_TYPE_LABELS[SUMMARY_TYPES.BULLET_POINT],
    placeholder: SUMMARY_TYPE_PLACEHOLDERS[SUMMARY_TYPES.BULLET_POINT],
    description:
      "An effective tool for presenting information in a structured, concise, and easy-to-digest format. It's perfect for lists, product features, key points, or any content that benefits from a clear and organized presentation.",
    category: TOOL_TYPE.SUMMARY,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.SUMMARY],
  },
  {
    id: SUMMARY_TYPES.CONCLUSION,
    name: SUMMARY_TYPE_LABELS[SUMMARY_TYPES.CONCLUSION],
    placeholder: SUMMARY_TYPE_PLACEHOLDERS[SUMMARY_TYPES.CONCLUSION],
    description:
      "Helps you craft a compelling conclusion that leaves a lasting impression on your reader. This tool is ideal for wrapping up articles, essays, speeches, etc., summarizing key points and reinforcing your message.",
    category: TOOL_TYPE.SUMMARY,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.SUMMARY],
  },
  {
    id: SUMMARY_TYPES.ESSAY_INTRO,
    name: SUMMARY_TYPE_LABELS[SUMMARY_TYPES.ESSAY_INTRO],
    placeholder: SUMMARY_TYPE_PLACEHOLDERS[SUMMARY_TYPES.ESSAY_INTRO],
    description:
      "Ideal for creating engaging introductions that capture your reader's attention from the start. It's perfect for academic essays, giving a clear direction and setting the context for your argument.",
    category: TOOL_TYPE.SUMMARY,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.SUMMARY],
  },
  {
    id: SUMMARY_TYPES.ESSAY_OUTLINE,
    name: SUMMARY_TYPE_LABELS[SUMMARY_TYPES.ESSAY_OUTLINE],
    placeholder: SUMMARY_TYPE_PLACEHOLDERS[SUMMARY_TYPES.ESSAY_OUTLINE],
    description:
      "Aids in structuring your essay, ensuring that your argument flows logically and coherently. This tool is crucial for academic writing, helping to plan your points and supporting evidence before you start writing.",
    category: TOOL_TYPE.SUMMARY,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.SUMMARY],
  },
  {
    id: SUMMARY_TYPES.FREESTYLE,
    name: SUMMARY_TYPE_LABELS[SUMMARY_TYPES.FREESTYLE],
    placeholder: SUMMARY_TYPE_PLACEHOLDERS[SUMMARY_TYPES.FREESTYLE],
    description:
      "Allows your creativity to flow unrestricted, helping you craft engaging and original content. While it's crucial to refine and edit afterward, this tool is ideal for drafting initial ideas and breaking away from conventional formats.",
    category: TOOL_TYPE.SUMMARY,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.SUMMARY],
  },
  {
    id: SUMMARY_TYPES.PROPOSAL_INTRO,
    name: SUMMARY_TYPE_LABELS[SUMMARY_TYPES.PROPOSAL_INTRO],
    placeholder: SUMMARY_TYPE_PLACEHOLDERS[SUMMARY_TYPES.PROPOSAL_INTRO],
    description:
      "Perfect for drafting a compelling introduction to your business proposal. It sets the tone, provides context, and encourages your potential clients or partners to read further.",
    category: TOOL_TYPE.SUMMARY,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.SUMMARY],
  },
  {
    id: SUMMARY_TYPES.PROPOSAL_SUMMARY,
    name: SUMMARY_TYPE_LABELS[SUMMARY_TYPES.PROPOSAL_SUMMARY],
    placeholder: SUMMARY_TYPE_PLACEHOLDERS[SUMMARY_TYPES.PROPOSAL_SUMMARY],
    description:
      "Ideal for encapsulating the key points of your proposal in a concise, impactful manner. It acts like a trailer for your proposal, enticing your potential clients or partners to delve deeper into the details.",
    category: TOOL_TYPE.SUMMARY,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.SUMMARY],
  },

  {
    id: SUMMARY_TYPES.REFRAMER,
    name: SUMMARY_TYPE_LABELS[SUMMARY_TYPES.REFRAMER],
    placeholder: SUMMARY_TYPE_PLACEHOLDERS[SUMMARY_TYPES.REFRAMER],
    description:
      "Shift your perspective to see things in a new light and uncover fresh ideas. Use this tool when tackling a challenge, brainstorming ideas, or resolving conflicts, like viewing a problem as an opportunity or seeing things from a different angle.",
    category: TOOL_TYPE.SUMMARY,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.SUMMARY],
  },
  {
    id: SUMMARY_TYPES.REWRITER,
    name: SUMMARY_TYPE_LABELS[SUMMARY_TYPES.REWRITER],
    placeholder: SUMMARY_TYPE_PLACEHOLDERS[SUMMARY_TYPES.REWRITER],
    description:
      "Give your content a makeover and improve its effectiveness. Use this when refining your work, like making your sales copy more persuasive, revising your blog post for better engagement, or enhancing your presentation for maximum impact.",
    category: TOOL_TYPE.SUMMARY,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.SUMMARY],
  },
  {
    id: SUMMARY_TYPES.SIMPLIFY,
    name: SUMMARY_TYPE_LABELS[SUMMARY_TYPES.SIMPLIFY],
    placeholder: SUMMARY_TYPE_PLACEHOLDERS[SUMMARY_TYPES.SIMPLIFY],
    description:
      "Use this tool when you need to condense complex, lengthy narratives into clear, concise statements. It's perfect for summarizing reports, articles, or proposals, allowing readers to quickly understand the essential information.",
    category: TOOL_TYPE.SUMMARY,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.SUMMARY],
  },
  {
    id: SUMMARY_TYPES.SPEECH_INTRO,
    name: SUMMARY_TYPE_LABELS[SUMMARY_TYPES.SPEECH_INTRO],
    placeholder: SUMMARY_TYPE_PLACEHOLDERS[SUMMARY_TYPES.SPEECH_INTRO],
    description:
      "Craft compelling introductions for your speeches or presentations that grab attention, set the tone, and make your audience eager to hear more. This tool is useful in various speaking situations, from professional presentations to public speaking engagements.",
    category: TOOL_TYPE.SUMMARY,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.SUMMARY],
  },
  {
    id: SUMMARY_TYPES.ANALOGY,
    name: SUMMARY_TYPE_LABELS[SUMMARY_TYPES.ANALOGY],
    placeholder: SUMMARY_TYPE_PLACEHOLDERS[SUMMARY_TYPES.ANALOGY],
    category: TOOL_TYPE.SUMMARY,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.SUMMARY],
    description:
      "This tool aids in creating insightful analogies to simplify complex ideas, enhance understanding, and make your content more engaging and relatable.",
  },
  {
    id: SUMMARY_TYPES.COURSE_OUTLINE,
    name: SUMMARY_TYPE_LABELS[SUMMARY_TYPES.COURSE_OUTLINE],
    placeholder: SUMMARY_TYPE_PLACEHOLDERS[SUMMARY_TYPES.COURSE_OUTLINE],
    category: TOOL_TYPE.SUMMARY,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.SUMMARY],
    description:
      "An intuitive tool designed to streamline your course planning process, providing a clear roadmap of course objectives, modules, lessons, assessment methods, and timelines.",
  },
  {
    id: SUMMARY_TYPES.ABSTRACT_WRITER,
    name: SUMMARY_TYPE_LABELS[SUMMARY_TYPES.ABSTRACT_WRITER],
    placeholder: SUMMARY_TYPE_PLACEHOLDERS[SUMMARY_TYPES.ABSTRACT_WRITER],
    category: TOOL_TYPE.SUMMARY,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.SUMMARY],
    description:
      "An indispensable tool for writing concise, informative, and compelling abstracts for your research papers, helping to accurately summarize your work and draw in readers.",
  },
  {
    id: SUMMARY_TYPES.SPEECH_CONCLUSION,
    name: SUMMARY_TYPE_LABELS[SUMMARY_TYPES.SPEECH_CONCLUSION],
    placeholder: SUMMARY_TYPE_PLACEHOLDERS[SUMMARY_TYPES.SPEECH_CONCLUSION],
    description:
      "This tool assists in crafting impactful conclusions for your speeches, leaving your audience with a clear understanding of your message and a lasting impression.",
    category: TOOL_TYPE.SUMMARY,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.SUMMARY],
  },

  // social media
  {
    id: SOCIAL_MEDIA_TYPES.HASHTAG_GENERATOR,
    category: TOOL_TYPE.SOCIALMEDIA,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.SOCIALMEDIA],
    name: SOCIAL_MEDIA_TYPE_LABELS[SOCIAL_MEDIA_TYPES.HASHTAG_GENERATOR],
    placeholder:
      SOCIAL_MEDIA_TYPE_PLACEHOLDERS[SOCIAL_MEDIA_TYPES.HASHTAG_GENERATOR],
    description:
      "This tool is ideal for generating catchy, trending hashtags that can boost your social media presence. It assists in increasing the visibility of your posts, ensuring your content reaches a larger audience and attracts more engagement.",
  },
  {
    id: SOCIAL_MEDIA_TYPES.INSTAGRAM_CAPTION,
    name: SOCIAL_MEDIA_TYPE_LABELS[SOCIAL_MEDIA_TYPES.INSTAGRAM_CAPTION],
    placeholder:
      SOCIAL_MEDIA_TYPE_PLACEHOLDERS[SOCIAL_MEDIA_TYPES.INSTAGRAM_CAPTION],
    category: TOOL_TYPE.SOCIALMEDIA,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.SOCIALMEDIA],
    description:
      "The right caption can turn a good post into a great one. This tool helps you do just that. Give your post with the context it needs, add personality, humor, or inspiration as needed. Whether you're aiming to evoke emotion, provoke thought, or incite engagement.",
  },

  {
    id: SOCIAL_MEDIA_TYPES.LINKEDIN_OUTREACH,
    name: SOCIAL_MEDIA_TYPE_LABELS[SOCIAL_MEDIA_TYPES.LINKEDIN_OUTREACH],
    placeholder:
      SOCIAL_MEDIA_TYPE_PLACEHOLDERS[SOCIAL_MEDIA_TYPES.LINKEDIN_OUTREACH],
    category: TOOL_TYPE.SOCIALMEDIA,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.SOCIALMEDIA],
    description:
      "Network and build professional relationships through personalized LinkedIn messages. Use this when you want to connect with industry peers, potential clients, or job candidates, whether it's introducing your services to a potential client or inviting an expert for collaboration.",
  },
  {
    id: SOCIAL_MEDIA_TYPES.LINKEDIN_POST,
    name: SOCIAL_MEDIA_TYPE_LABELS[SOCIAL_MEDIA_TYPES.LINKEDIN_POST],
    placeholder:
      SOCIAL_MEDIA_TYPE_PLACEHOLDERS[SOCIAL_MEDIA_TYPES.LINKEDIN_POST],
    category: TOOL_TYPE.SOCIALMEDIA,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.SOCIALMEDIA],
    description:
      "Share your professional insights, updates, and stories on LinkedIn. Use this when you want to build your professional brand, engage your network, and reach a wider audience, like sharing an insightful article you've written or celebrating a company milestone.",
  },
  {
    id: SOCIAL_MEDIA_TYPES.PRODUCT_LAUNCH,
    name: SOCIAL_MEDIA_TYPE_LABELS[SOCIAL_MEDIA_TYPES.PRODUCT_LAUNCH],
    placeholder:
      SOCIAL_MEDIA_TYPE_PLACEHOLDERS[SOCIAL_MEDIA_TYPES.PRODUCT_LAUNCH],
    category: TOOL_TYPE.SOCIALMEDIA,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.SOCIALMEDIA],
    description:
      "Craft captivating and engaging content for your product launches on social media. This tool helps generate buzz and anticipation around your new product or service, ensuring you make a significant impact in the market right from the launch.",
  },
  {
    id: SOCIAL_MEDIA_TYPES.QUORA_ANSWERS,
    name: SOCIAL_MEDIA_TYPE_LABELS[SOCIAL_MEDIA_TYPES.QUORA_ANSWERS],
    placeholder:
      SOCIAL_MEDIA_TYPE_PLACEHOLDERS[SOCIAL_MEDIA_TYPES.QUORA_ANSWERS],
    category: TOOL_TYPE.SOCIALMEDIA,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.SOCIALMEDIA],
    description:
      "Provide meaningful, insightful answers on Quora to build your brand's credibility and establish yourself as an expert in your field. This tool helps you articulate your knowledge and experience in a way that resonates with Quora's users and prompts further engagement.",
  },
  {
    id: SOCIAL_MEDIA_TYPES.SOCIAL_POST,
    name: SOCIAL_MEDIA_TYPE_LABELS[SOCIAL_MEDIA_TYPES.SOCIAL_POST],
    placeholder: SOCIAL_MEDIA_TYPE_PLACEHOLDERS[SOCIAL_MEDIA_TYPES.SOCIAL_POST],
    category: TOOL_TYPE.SOCIALMEDIA,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.SOCIALMEDIA],
    description:
      "Create compelling, engaging, and share-worthy social media posts. This tool helps you design content that grabs attention, prompts responses, and drives shares, making sure your brand's message spreads effectively across the social media landscape.",
  },
  {
    id: SOCIAL_MEDIA_TYPES.TWITTER_POST,
    name: SOCIAL_MEDIA_TYPE_LABELS[SOCIAL_MEDIA_TYPES.TWITTER_POST],
    placeholder:
      SOCIAL_MEDIA_TYPE_PLACEHOLDERS[SOCIAL_MEDIA_TYPES.TWITTER_POST],
    category: TOOL_TYPE.SOCIALMEDIA,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.SOCIALMEDIA],
    description:
      "Craft compelling tweets that spark conversations and engagement. Use this when tweeting, like sharing a thoughtful comment on a trending topic or posting a witty remark that reflects your brand's personality.",
  },
  {
    id: SOCIAL_MEDIA_TYPES.TWITTER_THREAD,
    name: SOCIAL_MEDIA_TYPE_LABELS[SOCIAL_MEDIA_TYPES.TWITTER_THREAD],
    placeholder:
      SOCIAL_MEDIA_TYPE_PLACEHOLDERS[SOCIAL_MEDIA_TYPES.TWITTER_THREAD],
    category: TOOL_TYPE.SOCIALMEDIA,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.SOCIALMEDIA],
    description:
      "Weave together a series of tweets that convey a more complex message or story. Use this when you have more to say than can fit into one tweet, like explaining a detailed process or sharing a personal experience.",
  },
  {
    id: SOCIAL_MEDIA_TYPES.VIRAL_POST_HOOK,
    name: SOCIAL_MEDIA_TYPE_LABELS[SOCIAL_MEDIA_TYPES.VIRAL_POST_HOOK],
    placeholder:
      SOCIAL_MEDIA_TYPE_PLACEHOLDERS[SOCIAL_MEDIA_TYPES.VIRAL_POST_HOOK],
    category: TOOL_TYPE.SOCIALMEDIA,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.SOCIALMEDIA],
    description:
      "Create a hook that makes your content irresistible and shareable. Use this when crafting content with viral potential, like devising a catchy challenge for a TikTok video or writing a headline for a controversial blog post",
  },
  {
    id: SOCIAL_MEDIA_TYPES.YOUTUBE_DESCRIPTION,
    name: SOCIAL_MEDIA_TYPE_LABELS[SOCIAL_MEDIA_TYPES.YOUTUBE_DESCRIPTION],
    placeholder:
      SOCIAL_MEDIA_TYPE_PLACEHOLDERS[SOCIAL_MEDIA_TYPES.YOUTUBE_DESCRIPTION],
    category: TOOL_TYPE.SOCIALMEDIA,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.SOCIALMEDIA],
    description:
      "Write a compelling description that makes viewers want to watch your video. Use this when uploading your videos, like highlighting the key takeaways of an educational video or teasing the exciting content of a vlog.",
  },
  {
    id: QUESTION_TYPE.INTERVIEW,
    name: QUESTION_TYPE_LABELS[QUESTION_TYPE.INTERVIEW],
    placeholder: QUESTION_TYPE_PLACEHOLDERS[QUESTION_TYPE.INTERVIEW],
    description:
      "Dig deeper into a person's thoughts, experiences, and perspectives with thought-provoking questions. Use when conducting an interview, whether it's for a news article, a podcast episode, or a job interview, to uncover intriguing insights and stories.",
    category: TOOL_TYPE.QUESTION,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.QUESTION],
  },
  {
    id: QUESTION_TYPE.RESEARCH,
    name: QUESTION_TYPE_LABELS[QUESTION_TYPE.RESEARCH],
    placeholder: QUESTION_TYPE_PLACEHOLDERS[QUESTION_TYPE.RESEARCH],
    description:
      "This tool aids in creating sharp, focused questions that will steer your research efforts in the right direction. It's ideal for the preliminary stages of research projects, helping to define scope and target precisely the information you need.",
    category: TOOL_TYPE.QUESTION,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.QUESTION],
  },
  {
    id: QUESTION_TYPE.SURVEY,
    name: QUESTION_TYPE_LABELS[QUESTION_TYPE.SURVEY],
    placeholder: QUESTION_TYPE_PLACEHOLDERS[QUESTION_TYPE.SURVEY],
    description:
      "Develop effective and insightful survey questions with this tool. It's perfect for creating customer feedback forms, employee satisfaction surveys, market research queries, and more, delivering valuable insights that can help you drive meaningful changes.",
    category: TOOL_TYPE.QUESTION,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.QUESTION],
  },

  {
    id: MESSAGE_TYPES.KEY_MESSAGES,
    name: MESSAGE_TYPE_LABELS[MESSAGE_TYPES.KEY_MESSAGES],
    placeholder: MESSAGE_TYPE_PLACEHOLDERS[MESSAGE_TYPES.KEY_MESSAGES],
    description:
      "Use this tool to define the core messages that encapsulate your brand's value and uniqueness. Ideal for creating consistent narratives across multiple channels like your website, marketing collateral, social media, and more, keeping your brand story clear and cohesive.",
    category: TOOL_TYPE.MESSAGING,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.MESSAGING],
  },
  {
    id: MESSAGE_TYPES.TALKING_POINTS,
    name: MESSAGE_TYPE_LABELS[MESSAGE_TYPES.TALKING_POINTS],
    placeholder: MESSAGE_TYPE_PLACEHOLDERS[MESSAGE_TYPES.TALKING_POINTS],
    description:
      "Prepare persuasive talking points for your presentations, interviews, or meetings. This tool is invaluable when you need to communicate your ideas effectively and clearly, be it during a pitch to investors, a conference presentation, or a team meeting.",
    category: TOOL_TYPE.MESSAGING,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.MESSAGING],
  },
  {
    id: MESSAGE_TYPES.POSITIONING_STATEMENT,
    name: MESSAGE_TYPE_LABELS[MESSAGE_TYPES.POSITIONING_STATEMENT],
    placeholder: MESSAGE_TYPE_PLACEHOLDERS[MESSAGE_TYPES.POSITIONING_STATEMENT],
    description:
      "Develop a robust positioning statement that succinctly communicates what sets your brand apart. It is a crucial element of your overall brand strategy, defining your market niche and underscoring the unique value you bring to your customers.",
    category: TOOL_TYPE.MESSAGING,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.MESSAGING],
  },
  {
    id: MESSAGE_TYPES.BRAND_STORY,
    name: MESSAGE_TYPE_LABELS[MESSAGE_TYPES.BRAND_STORY],
    placeholder: MESSAGE_TYPE_PLACEHOLDERS[MESSAGE_TYPES.BRAND_STORY],
    description:
      "A tool to weave engaging narratives around your brand, presenting your brand's mission, journey, and values in a compelling way. It's integral in establishing a strong brand identity and fostering an emotional connection with your audience.",
    category: TOOL_TYPE.MESSAGING,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.MESSAGING],
  },
  {
    id: MESSAGE_TYPES.MARKETING_ANGLE,
    name: MESSAGE_TYPE_LABELS[MESSAGE_TYPES.MARKETING_ANGLE],
    placeholder: MESSAGE_TYPE_PLACEHOLDERS[MESSAGE_TYPES.MARKETING_ANGLE],
    description:
      "Find the unique perspective that makes your brand stand out in a crowded market. This tool helps you pinpoint the unique aspects and benefits of your product or service, providing a distinctive angle for your marketing campaigns.",
    category: TOOL_TYPE.MESSAGING,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.MESSAGING],
  },
  {
    id: MESSAGE_TYPES.VALUE_PROPOSITION,
    name: MESSAGE_TYPE_LABELS[MESSAGE_TYPES.VALUE_PROPOSITION],
    placeholder: MESSAGE_TYPE_PLACEHOLDERS[MESSAGE_TYPES.VALUE_PROPOSITION],
    description:
      "Articulate your product's unique value in a way that resonates with your customers. This tool helps you highlight the unique benefits and differentiators of your product or service, clarifying why your customers should choose you over competitors.",
    category: TOOL_TYPE.MESSAGING,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.MESSAGING],
  },
  {
    id: MESSAGE_TYPES.ELEVATOR_PITCH,
    name: MESSAGE_TYPE_LABELS[MESSAGE_TYPES.ELEVATOR_PITCH],
    placeholder: MESSAGE_TYPE_PLACEHOLDERS[MESSAGE_TYPES.ELEVATOR_PITCH],
    description:
      "An elevator pitch is a concise, catchy summary of your business idea or product that can be delivered in the time it takes to ride an elevator. This tool is essential when you need to make a quick yet compelling case for your business or idea.",
    category: TOOL_TYPE.MESSAGING,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.MESSAGING],
  },
  {
    id: MESSAGE_TYPES.NEWS_HOOK,
    name: MESSAGE_TYPE_LABELS[MESSAGE_TYPES.NEWS_HOOK],
    placeholder: MESSAGE_TYPE_PLACEHOLDERS[MESSAGE_TYPES.NEWS_HOOK],
    description:
      "The news hook is the unique angle that makes your story newsworthy and attention-grabbing. Utilize it to get your story noticed by journalists, be it a timely charity initiative in response to a crisis or a disruptive product launch that challenges industry norms.",
    category: TOOL_TYPE.MESSAGING,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.MESSAGING],
  },
  {
    id: MESSAGE_TYPES.PROFILE_SUMMARY,
    name: MESSAGE_TYPE_LABELS[MESSAGE_TYPES.PROFILE_SUMMARY],
    placeholder: MESSAGE_TYPE_PLACEHOLDERS[MESSAGE_TYPES.PROFILE_SUMMARY],
    description:
      "A tool designed to exhibit your highlights and strengths in a compelling manner. Use it to display your accomplishments and unique skill set, making your profile a beacon to potential clients or employers. Ideal for professional networking and social media platforms.",
    category: TOOL_TYPE.MESSAGING,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.MESSAGING],
  },
  {
    id: MESSAGE_TYPES.FREESTYLE_WRITER,
    name: MESSAGE_TYPE_LABELS[MESSAGE_TYPES.FREESTYLE_WRITER],
    placeholder: MESSAGE_TYPE_PLACEHOLDERS[MESSAGE_TYPES.FREESTYLE_WRITER],
    description:
      "Tailored to serve your unique communication needs, it allows you to craft custom messages for any event or occasion, from formal announcements to personal greetings.",
    category: TOOL_TYPE.MESSAGING,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.MESSAGING],
  },
  {
    id: MESSAGE_TYPES.EDITORIAL_STRATEGY,
    name: MESSAGE_TYPE_LABELS[MESSAGE_TYPES.EDITORIAL_STRATEGY],
    placeholder: MESSAGE_TYPE_PLACEHOLDERS[MESSAGE_TYPES.EDITORIAL_STRATEGY],
    description:
      "This tool serves as your guide in constructing a robust content strategy. From defining your key messages to planning their delivery, it helps create a roadmap to achieving your content goals. A must-have for content marketers and strategists.",
    category: TOOL_TYPE.MESSAGING,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.MESSAGING],
  },
  {
    id: MESSAGE_TYPES.STORY_BRAND_MARKETING,
    name: MESSAGE_TYPE_LABELS[MESSAGE_TYPES.STORY_BRAND_MARKETING],
    placeholder: MESSAGE_TYPE_PLACEHOLDERS[MESSAGE_TYPES.STORY_BRAND_MARKETING],
    description:
      "This tool enables you to shape your brand narrative in a way that resonates with your target audience. By transforming your brand into a hero, you can create compelling stories that align with your customers' needs and values. Perfect for brand strategists and marketing professionals.",
    category: TOOL_TYPE.MESSAGING,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.MESSAGING],
  },
  {
    id: MESSAGE_TYPES.PERSONA_BUILDER,
    name: MESSAGE_TYPE_LABELS[MESSAGE_TYPES.PERSONA_BUILDER],
    placeholder: MESSAGE_TYPE_PLACEHOLDERS[MESSAGE_TYPES.PERSONA_BUILDER],
    category: TOOL_TYPE.MESSAGING,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.MESSAGING],
    description:
      "A tool designed to assist in building detailed and realistic customer personas. It helps you understand your target audience better, enhancing your marketing strategies and improving customer experiences.",
  },
  {
    id: MESSAGE_TYPES.BRAND_VOICE,
    name: MESSAGE_TYPE_LABELS[MESSAGE_TYPES.BRAND_VOICE],
    placeholder: MESSAGE_TYPE_PLACEHOLDERS[MESSAGE_TYPES.BRAND_VOICE],
    category: TOOL_TYPE.MESSAGING,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.MESSAGING],
    description:
      "A unique tool to help define and consistently apply your brand's voice across all communications, ensuring a uniform brand identity that resonates with your audience.",
  },

  // headlines
  {
    id: HEADLINE_TYPES.ARTICLE_HEADLINE,
    category: TOOL_TYPE.HEADLINES,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.HEADLINES],
    name: HEADLINE_TYPE_LABELS[HEADLINE_TYPES.ARTICLE_HEADLINE],
    placeholder: HEADLINE_TYPE_PLACEHOLDERS[HEADLINE_TYPES.ARTICLE_HEADLINE],
    description:
      "This tool helps you craft attention-grabbing headlines that act as an irresistible invitation to your content. Perfect for sparking interest and encouraging deeper reading of your articles, blog posts, and more.",
  },
  {
    id: HEADLINE_TYPES.GENERAL_HEADLINE,
    category: TOOL_TYPE.HEADLINES,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.HEADLINES],
    name: HEADLINE_TYPE_LABELS[HEADLINE_TYPES.GENERAL_HEADLINE],
    placeholder: HEADLINE_TYPE_PLACEHOLDERS[HEADLINE_TYPES.GENERAL_HEADLINE],
    description:
      "Here's your ticket to creating compelling headlines that act as the gateway to your content. Ideal for a variety of formats and channels, from blogs to newsletters, it ensures your key messages get the attention they deserve.",
  },
  {
    id: HEADLINE_TYPES.EMAIL_SUBJECT,
    category: TOOL_TYPE.HEADLINES,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.HEADLINES],
    name: HEADLINE_TYPE_LABELS[HEADLINE_TYPES.EMAIL_SUBJECT],
    placeholder: HEADLINE_TYPE_PLACEHOLDERS[HEADLINE_TYPES.EMAIL_SUBJECT],
    description:
      "The tool you need to design enticing email subject lines. These crucial few words can be the deciding factor between your email being opened or ignored, making it a powerful asset for any email marketing campaign.",
  },
  {
    id: HEADLINE_TYPES.PRODUCT_NAME,
    category: TOOL_TYPE.HEADLINES,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.HEADLINES],
    name: HEADLINE_TYPE_LABELS[HEADLINE_TYPES.PRODUCT_NAME],
    placeholder: HEADLINE_TYPE_PLACEHOLDERS[HEADLINE_TYPES.PRODUCT_NAME],
    description:
      "This tool is designed to aid you in christening your product with a unique and memorable name, thus ensuring it stands out in the competitive marketplace. Ideal for entrepreneurs, product managers, and brand strategists.",
  },
  {
    id: HEADLINE_TYPES.BOOK_TITLE,
    category: TOOL_TYPE.HEADLINES,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.HEADLINES],
    name: HEADLINE_TYPE_LABELS[HEADLINE_TYPES.BOOK_TITLE],
    placeholder: HEADLINE_TYPE_PLACEHOLDERS[HEADLINE_TYPES.BOOK_TITLE],
    description:
      "A crucial companion for authors, publishers, and anyone in the realm of literature. This tool guides you in crafting a compelling book title, creating the first impression and the promise of a captivating journey within the pages.",
  },
  {
    id: HEADLINE_TYPES.WEBSITE_HERO_TITLE,
    category: TOOL_TYPE.HEADLINES,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.HEADLINES],
    name: HEADLINE_TYPE_LABELS[HEADLINE_TYPES.WEBSITE_HERO_TITLE],
    placeholder: HEADLINE_TYPE_PLACEHOLDERS[HEADLINE_TYPES.WEBSITE_HERO_TITLE],
    description:
      "Create captivating hero titles for your website with this tool, making the first interaction with visitors impactful. An essential resource for web designers and marketers aiming to engage visitors and prompt them to explore further.",
  },
  {
    id: HEADLINE_TYPES.GOOGLE_AD_HEADLINE,
    category: TOOL_TYPE.HEADLINES,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.HEADLINES],
    name: HEADLINE_TYPE_LABELS[HEADLINE_TYPES.GOOGLE_AD_HEADLINE],
    placeholder: HEADLINE_TYPE_PLACEHOLDERS[HEADLINE_TYPES.GOOGLE_AD_HEADLINE],
    description:
      "Your Google ad's opening act, drawing in viewers with a brief yet compelling statement. Use it to create an immediate impact and inspire clicks, like promising a solution to a common problem or offering a tempting discount.",
  },
  {
    id: HEADLINE_TYPES.FACEBOOK_ADS_HEADLINE,
    category: TOOL_TYPE.HEADLINES,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.HEADLINES],
    name: HEADLINE_TYPE_LABELS[HEADLINE_TYPES.FACEBOOK_ADS_HEADLINE],
    placeholder:
      HEADLINE_TYPE_PLACEHOLDERS[HEADLINE_TYPES.FACEBOOK_ADS_HEADLINE],
    description:
      "The first impression of your Facebook ad that grabs attention and incites curiosity. Use it to spark interest in your product or service, making people pause their scrolling and take notice.'",
  },
  {
    id: HEADLINE_TYPES.HEADLINE_ONLY,
    category: TOOL_TYPE.HEADLINES,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.HEADLINES],
    name: HEADLINE_TYPE_LABELS[HEADLINE_TYPES.HEADLINE_ONLY],
    placeholder: HEADLINE_TYPE_PLACEHOLDERS[HEADLINE_TYPES.HEADLINE_ONLY],
    description:
      "Master the art of first impressions. An impactful headline can dictate the success of your ads, stirring curiosity and driving engagement. Ideal for ad campaigns where you want to command attention.",
  },
  {
    id: HEADLINE_TYPES.LINKEDIN_PROFILE_HEADLINE,
    category: TOOL_TYPE.HEADLINES,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.HEADLINES],
    name: HEADLINE_TYPE_LABELS[HEADLINE_TYPES.LINKEDIN_PROFILE_HEADLINE],
    placeholder:
      HEADLINE_TYPE_PLACEHOLDERS[HEADLINE_TYPES.LINKEDIN_PROFILE_HEADLINE],
    description:
      "Distill your professional prowess into a captivating LinkedIn headline. This is your personal marketing tool for networking and job searching.",
  },
  {
    id: HEADLINE_TYPES.PINTEREST_HEADLINE,
    category: TOOL_TYPE.HEADLINES,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.HEADLINES],
    name: HEADLINE_TYPE_LABELS[HEADLINE_TYPES.PINTEREST_HEADLINE],
    placeholder: HEADLINE_TYPE_PLACEHOLDERS[HEADLINE_TYPES.PINTEREST_HEADLINE],
    description:
      "Craft an irresistible Pinterest title to inspire curiosity and click-throughs. Ideal for your creative pins or mouthwatering recipe posts.",
  },
  {
    id: HEADLINE_TYPES.SEO_META_DESCRIPTION,
    category: TOOL_TYPE.HEADLINES,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.HEADLINES],
    name: HEADLINE_TYPE_LABELS[HEADLINE_TYPES.SEO_META_DESCRIPTION],
    placeholder:
      HEADLINE_TYPE_PLACEHOLDERS[HEADLINE_TYPES.SEO_META_DESCRIPTION],
    description:
      "Create an inviting sneak peek to pull in your audience and improve SEO. Ideal for enhancing your web page's visibility on search engine results.",
  },
  {
    id: HEADLINE_TYPES.YOUTUBE_TITLE,
    category: TOOL_TYPE.HEADLINES,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.HEADLINES],
    name: HEADLINE_TYPE_LABELS[HEADLINE_TYPES.YOUTUBE_TITLE],
    placeholder: HEADLINE_TYPE_PLACEHOLDERS[HEADLINE_TYPES.YOUTUBE_TITLE],
    description:
      "Make your YouTube video irresistible with a compelling title. Perfect for increasing viewership and engagement on your video content.",
  },
  {
    id: HEADLINE_TYPES.EVENT_NAME,
    category: TOOL_TYPE.HEADLINES,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.HEADLINES],
    name: HEADLINE_TYPE_LABELS[HEADLINE_TYPES.EVENT_NAME],
    placeholder: HEADLINE_TYPE_PLACEHOLDERS[HEADLINE_TYPES.EVENT_NAME],
    description:
      "Give your event a captivating name that leaves a lasting impression. This tool helps you craft memorable and engaging event names that draw attention and spark interest.",
  },
  {
    id: HEADLINE_TYPES.CONFERENCE_SESSION_TITLE,
    category: TOOL_TYPE.HEADLINES,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.HEADLINES],
    name: HEADLINE_TYPE_LABELS[HEADLINE_TYPES.CONFERENCE_SESSION_TITLE],
    placeholder:
      HEADLINE_TYPE_PLACEHOLDERS[HEADLINE_TYPES.CONFERENCE_SESSION_TITLE],
    description:
      "An innovative tool that generates compelling and attention-grabbing titles for your conference sessions, designed to pique interest, drive attendance, and clearly convey the session's subject matter.",
  },
  {
    id: HEADLINE_TYPES.LANDING_PAGE_HEADLINE,
    category: TOOL_TYPE.HEADLINES,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.HEADLINES],
    name: HEADLINE_TYPE_LABELS[HEADLINE_TYPES.LANDING_PAGE_HEADLINE],
    placeholder:
      HEADLINE_TYPE_PLACEHOLDERS[HEADLINE_TYPES.LANDING_PAGE_HEADLINE],
    description:
      "A creative resource for crafting powerful, persuasive headlines for your website's landing pages. This tool aids in increasing site traffic, improving user engagement, and boosting conversion rates.",
  },
  {
    id: HEADLINE_TYPES.RESEARCH_PAPER_TITLE,
    category: TOOL_TYPE.HEADLINES,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.HEADLINES],
    name: HEADLINE_TYPE_LABELS[HEADLINE_TYPES.RESEARCH_PAPER_TITLE],
    placeholder:
      HEADLINE_TYPE_PLACEHOLDERS[HEADLINE_TYPES.RESEARCH_PAPER_TITLE],
    description:
      "A tool that aids in generating concise, compelling, and scholarly appropriate titles for your research papers, helping to succinctly convey your paper's central focus and capture the interest of peers and reviewers.",
  },
  {
    id: HEADLINE_TYPES.APP_NAME,
    category: TOOL_TYPE.HEADLINES,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.HEADLINES],
    name: HEADLINE_TYPE_LABELS[HEADLINE_TYPES.APP_NAME],
    placeholder: HEADLINE_TYPE_PLACEHOLDERS[HEADLINE_TYPES.APP_NAME],
    description:
      "A unique tool designed to devise catchy, memorable, and relevant names for your applications. It helps make your app stand out in crowded marketplaces, enhancing visibility and attracting potential users.",
  },
  {
    id: HEADLINE_TYPES.PODCAST_EPISODE_TITLE,
    category: TOOL_TYPE.HEADLINES,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.HEADLINES],
    name: HEADLINE_TYPE_LABELS[HEADLINE_TYPES.PODCAST_EPISODE_TITLE],
    placeholder:
      HEADLINE_TYPE_PLACEHOLDERS[HEADLINE_TYPES.PODCAST_EPISODE_TITLE],
    description:
      "This tool assists in crafting intriguing and descriptive titles for your podcast episodes, to attract listeners and accurately depict the episode's content.",
  },
  {
    id: HEADLINE_TYPES.PRESS_RELEASE_HEADLINES,
    category: TOOL_TYPE.HEADLINES,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.HEADLINES],
    name: HEADLINE_TYPE_LABELS[HEADLINE_TYPES.PRESS_RELEASE_HEADLINES],
    placeholder:
      HEADLINE_TYPE_PLACEHOLDERS[HEADLINE_TYPES.PRESS_RELEASE_HEADLINES],
    description:
      "An essential tool for creating impactful headlines for your press releases, helping to grab media attention and efficiently conveying your news in a captivating manner.",
  },

  // entertainment
  {
    id: ENTERTAINMENT_TYPES.BLURB,
    category: TOOL_TYPE.ENTERTAINMENT,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.ENTERTAINMENT],
    name: ENTERTAINMENT_TYPES_LABELS[ENTERTAINMENT_TYPES.BLURB],
    placeholder: ENTERTAINMENT_TYPES_PLACEHOLDERS[ENTERTAINMENT_TYPES.BLURB],
    description:
      "Whip up a captivating blurb that teases an upcoming adventure. Use it when you need to generate excitement for a new book, event, or game.",
  },
  {
    id: ENTERTAINMENT_TYPES.CHARACTER_SKETCH,
    category: TOOL_TYPE.ENTERTAINMENT,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.ENTERTAINMENT],
    name: ENTERTAINMENT_TYPES_LABELS[ENTERTAINMENT_TYPES.CHARACTER_SKETCH],
    placeholder:
      ENTERTAINMENT_TYPES_PLACEHOLDERS[ENTERTAINMENT_TYPES.CHARACTER_SKETCH],
    description:
      "Facilitates a deep dive into your story characters, exploring their traits and motivations. Ideal for writers developing characters for novels, scripts, or short stories.",
  },
  {
    id: ENTERTAINMENT_TYPES.COMEDY_SKETCH_SCENARIOS,
    category: TOOL_TYPE.ENTERTAINMENT,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.ENTERTAINMENT],
    name: ENTERTAINMENT_TYPES_LABELS[
      ENTERTAINMENT_TYPES.COMEDY_SKETCH_SCENARIOS
    ],
    placeholder:
      ENTERTAINMENT_TYPES_PLACEHOLDERS[
        ENTERTAINMENT_TYPES.COMEDY_SKETCH_SCENARIOS
      ],
    description:
      "Create a multitude of comedic situations for your sketches. An invaluable aid when you're aiming to tickle funny bones and light up faces.",
  },
  {
    id: ENTERTAINMENT_TYPES.CREATIVE_OUTLINE,
    category: TOOL_TYPE.ENTERTAINMENT,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.ENTERTAINMENT],
    name: ENTERTAINMENT_TYPES_LABELS[ENTERTAINMENT_TYPES.CREATIVE_OUTLINE],
    placeholder:
      ENTERTAINMENT_TYPES_PLACEHOLDERS[ENTERTAINMENT_TYPES.CREATIVE_OUTLINE],
    description:
      "Staring at a blank page can be intimidating. Transform those white voids into vibrant, compelling worlds in a matter of seconds. It provides a structured layout to your narrative, helping you to flesh out your ideas and organize your thoughts, be it for a script, a story, a campaign, or any other creative endeavor.",
  },
  {
    id: ENTERTAINMENT_TYPES.CREATIVE_RATIONALE,
    category: TOOL_TYPE.ENTERTAINMENT,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.ENTERTAINMENT],
    name: ENTERTAINMENT_TYPES_LABELS[ENTERTAINMENT_TYPES.CREATIVE_RATIONALE],
    placeholder:
      ENTERTAINMENT_TYPES_PLACEHOLDERS[ENTERTAINMENT_TYPES.CREATIVE_RATIONALE],
    description:
      "Presenting a brilliant idea is only half the battle. The Creative Rationale tool helps you to articulate the thought process and artistic vision that fuelled your idea. Whether it's a  marketing campaign,or a breakthrough product, create a compelling narrative that justifies your choices and highlights your creativity.",
  },
  {
    id: ENTERTAINMENT_TYPES.FIRST_PAGE,
    category: TOOL_TYPE.ENTERTAINMENT,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.ENTERTAINMENT],
    name: ENTERTAINMENT_TYPES_LABELS[ENTERTAINMENT_TYPES.FIRST_PAGE],
    placeholder:
      ENTERTAINMENT_TYPES_PLACEHOLDERS[ENTERTAINMENT_TYPES.FIRST_PAGE],
    description:
      "This is the initial page of your novel that hooks your readers and sets the stage for the rest of the story. Use it when starting a new book to captivate readers right from the start with a dramatic event, interesting character introduction, or intriguing mystery.",
  },
  {
    id: ENTERTAINMENT_TYPES.FREESTYLE,
    category: TOOL_TYPE.ENTERTAINMENT,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.ENTERTAINMENT],
    name: ENTERTAINMENT_TYPES_LABELS[ENTERTAINMENT_TYPES.FREESTYLE],
    placeholder:
      ENTERTAINMENT_TYPES_PLACEHOLDERS[ENTERTAINMENT_TYPES.FREESTYLE],
    description:
      "Whether it's for a creative writing exercise, brainstorming session, or just for fun, the Freestyle tool is your on-demand partner for generating creative and unique content. With its ability to handle diverse prompts, this tool can help you in creating riveting stories, unique ideas, or even hilarious jokes.",
  },
  {
    id: ENTERTAINMENT_TYPES.LOGLINE,
    category: TOOL_TYPE.ENTERTAINMENT,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.ENTERTAINMENT],
    name: ENTERTAINMENT_TYPES_LABELS[ENTERTAINMENT_TYPES.LOGLINE],
    placeholder: ENTERTAINMENT_TYPES_PLACEHOLDERS[ENTERTAINMENT_TYPES.LOGLINE],
    description:
      "This is a succinct summary of your narrative's main premise and conflict, usually used for films, TV shows, or books. Use it to pitch your story to potential investors or to pique your audience's curiosity, such as in a book blurb or movie trailer.",
  },
  {
    id: ENTERTAINMENT_TYPES.PLOT_SUMMARY,
    category: TOOL_TYPE.ENTERTAINMENT,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.ENTERTAINMENT],
    name: ENTERTAINMENT_TYPES_LABELS[ENTERTAINMENT_TYPES.PLOT_SUMMARY],
    placeholder:
      ENTERTAINMENT_TYPES_PLACEHOLDERS[ENTERTAINMENT_TYPES.PLOT_SUMMARY],
    description:
      "Provide a brief yet enticing overview of your story's plot. Use this when pitching your story or giving readers a sneak peek, like revealing the initial conflict in a novel or hinting at the thrilling adventure in a movie.",
  },
  {
    id: ENTERTAINMENT_TYPES.PROMPT_CRAFTER,
    category: TOOL_TYPE.ENTERTAINMENT,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.ENTERTAINMENT],
    name: ENTERTAINMENT_TYPES_LABELS[ENTERTAINMENT_TYPES.PROMPT_CRAFTER],
    placeholder:
      ENTERTAINMENT_TYPES_PLACEHOLDERS[ENTERTAINMENT_TYPES.PROMPT_CRAFTER],
    description:
      "This tool helps you create engaging prompts for AI image generation. Whether you're an artist looking for inspiration or a developer training an AI model, the Prompt Crafter tool can provide the creative stimulus you need.",
  },
  {
    id: ENTERTAINMENT_TYPES.PROMPT_TEMPLATE,
    category: TOOL_TYPE.ENTERTAINMENT,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.ENTERTAINMENT],
    name: ENTERTAINMENT_TYPES_LABELS[ENTERTAINMENT_TYPES.PROMPT_TEMPLATE],
    placeholder:
      ENTERTAINMENT_TYPES_PLACEHOLDERS[ENTERTAINMENT_TYPES.PROMPT_TEMPLATE],
    description:
      "Become the puppeteer of AI! Craft engaging prompts for use anywhere - whether here or on other platforms. Elevate your imagination and let AI bring your ideas to life.",
  },
  {
    id: ENTERTAINMENT_TYPES.SCREEN_PLAY,
    category: TOOL_TYPE.ENTERTAINMENT,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.ENTERTAINMENT],
    name: ENTERTAINMENT_TYPES_LABELS[ENTERTAINMENT_TYPES.SCREEN_PLAY],
    placeholder:
      ENTERTAINMENT_TYPES_PLACEHOLDERS[ENTERTAINMENT_TYPES.SCREEN_PLAY],
    description:
      "Condense your captivating narrative or promotional content into a single page. Ideal for presenting a quick yet impactful pitch.",
  },
  {
    id: ENTERTAINMENT_TYPES.SONGWRITING,
    category: TOOL_TYPE.ENTERTAINMENT,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.ENTERTAINMENT],
    name: ENTERTAINMENT_TYPES_LABELS[ENTERTAINMENT_TYPES.SONGWRITING],
    placeholder:
      ENTERTAINMENT_TYPES_PLACEHOLDERS[ENTERTAINMENT_TYPES.SONGWRITING],
    description:
      "Craft your musical masterpiece or an engaging tune for your advertisement. Perfect for resonating with listeners and customers.",
  },
  {
    id: ENTERTAINMENT_TYPES.VIGNETTE,
    category: TOOL_TYPE.ENTERTAINMENT,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.ENTERTAINMENT],
    name: ENTERTAINMENT_TYPES_LABELS[ENTERTAINMENT_TYPES.VIGNETTE],
    placeholder: ENTERTAINMENT_TYPES_PLACEHOLDERS[ENTERTAINMENT_TYPES.VIGNETTE],
    description:
      "Etch a vivid sketch of an event, scene, or sentiment using this concise tool. A handy choice when aiming to stir specific emotions or atmospheres.",
  },
  {
    category: TOOL_TYPE.BRAINSTORM,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.BRAINSTORM],
    id: BRAINSTORM_TYPES.ADVANCED_IDEA_GENERATOR,
    name: BRAINSTORM_TYPE_LABELS[BRAINSTORM_TYPES.ADVANCED_IDEA_GENERATOR],
    placeholder:
      BRAINSTORM_TYPE_PLACEHOLDERS[BRAINSTORM_TYPES.ADVANCED_IDEA_GENERATOR],
    description:
      "Infuse fresh perspectives and complexity into your brainstorming routine. Optimal for diversifying your concept development process.",
  },
  {
    category: TOOL_TYPE.BRAINSTORM,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.BRAINSTORM],
    id: BRAINSTORM_TYPES.AD_CAMPAIGN,
    name: BRAINSTORM_TYPE_LABELS[BRAINSTORM_TYPES.AD_CAMPAIGN],
    placeholder: BRAINSTORM_TYPE_PLACEHOLDERS[BRAINSTORM_TYPES.AD_CAMPAIGN],
    description:
      "Create the blueprint for an impactful ad campaign that elevates your brand. Every successful campaign begins with a sound strategy; this tool will guide you.",
  },
  {
    category: TOOL_TYPE.BRAINSTORM,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.BRAINSTORM],
    id: BRAINSTORM_TYPES.BLOG_TOPICS,
    name: BRAINSTORM_TYPE_LABELS[BRAINSTORM_TYPES.BLOG_TOPICS],
    placeholder: BRAINSTORM_TYPE_PLACEHOLDERS[BRAINSTORM_TYPES.BLOG_TOPICS],
    description:
      "Conjure up an array of engaging blog topics without the creative fatigue. Suited for maintaining a consistent content flow.",
  },
  {
    category: TOOL_TYPE.BRAINSTORM,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.BRAINSTORM],
    id: BRAINSTORM_TYPES.CREATIVE_FREESTYLE,
    name: BRAINSTORM_TYPE_LABELS[BRAINSTORM_TYPES.CREATIVE_FREESTYLE],
    placeholder:
      BRAINSTORM_TYPE_PLACEHOLDERS[BRAINSTORM_TYPES.CREATIVE_FREESTYLE],
    description:
      "Ideal for brainstorming sessions where ideas are allowed to flow freely, and push the boundaries of imagination.",
  },
  {
    category: TOOL_TYPE.BRAINSTORM,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.BRAINSTORM],
    id: BRAINSTORM_TYPES.CAMPAIGN_THEME,
    name: BRAINSTORM_TYPE_LABELS[BRAINSTORM_TYPES.CAMPAIGN_THEME],
    placeholder: BRAINSTORM_TYPE_PLACEHOLDERS[BRAINSTORM_TYPES.CAMPAIGN_THEME],
    description:
      "Cultivate innovative themes for your marketing and PR endeavors with ease. Perfect for generating remarkable campaign ideas.",
  },
  {
    category: TOOL_TYPE.BRAINSTORM,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.BRAINSTORM],
    id: BRAINSTORM_TYPES.CONTEST_IDEAS,
    name: BRAINSTORM_TYPE_LABELS[BRAINSTORM_TYPES.CONTEST_IDEAS],
    placeholder: BRAINSTORM_TYPE_PLACEHOLDERS[BRAINSTORM_TYPES.CONTEST_IDEAS],
    description:
      "Create standout online contests and sweepstakes that engage audiences. It's a great ally for your website or social media channel planning sessions.",
  },
  {
    category: TOOL_TYPE.BRAINSTORM,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.BRAINSTORM],
    id: BRAINSTORM_TYPES.CREATIVE_INSIGHTS,
    name: BRAINSTORM_TYPE_LABELS[BRAINSTORM_TYPES.CREATIVE_INSIGHTS],
    placeholder:
      BRAINSTORM_TYPE_PLACEHOLDERS[BRAINSTORM_TYPES.CREATIVE_INSIGHTS],
    description:
      "Transform your creative brief into a launching pad for success. Uncover creative insights, and craft strategies that truly resonate with your audience.",
  },
  {
    category: TOOL_TYPE.BRAINSTORM,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.BRAINSTORM],
    id: BRAINSTORM_TYPES.CRISIS_RESPONSE,
    name: BRAINSTORM_TYPE_LABELS[BRAINSTORM_TYPES.CRISIS_RESPONSE],
    placeholder: BRAINSTORM_TYPE_PLACEHOLDERS[BRAINSTORM_TYPES.CRISIS_RESPONSE],
    description:
      "Build a solid crisis response strategy that helps your company avoid unwanted attention. It's your go-to when creating crisis management plans.",
  },
  {
    category: TOOL_TYPE.BRAINSTORM,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.BRAINSTORM],
    id: BRAINSTORM_TYPES.EVENT_IDEAS,
    name: BRAINSTORM_TYPE_LABELS[BRAINSTORM_TYPES.EVENT_IDEAS],
    placeholder: BRAINSTORM_TYPE_PLACEHOLDERS[BRAINSTORM_TYPES.EVENT_IDEAS],
    description:
      "Ignite your creative event planning with unique party ideas. A perfect fit when you're in the early stages of planning any event.",
  },
  {
    id: BRAINSTORM_TYPES.GUERILLA_MARKETING,
    category: TOOL_TYPE.BRAINSTORM,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.BRAINSTORM],
    name: BRAINSTORM_TYPE_LABELS[BRAINSTORM_TYPES.GUERILLA_MARKETING],
    placeholder:
      BRAINSTORM_TYPE_PLACEHOLDERS[BRAINSTORM_TYPES.GUERILLA_MARKETING],
    description:
      "Formulate bold marketing strategies that turn heads. Use it when you're aiming to disrupt the market and create a buzz around your brand.",
  },
  {
    id: BRAINSTORM_TYPES.INFLUENCER_MARKETING,
    category: TOOL_TYPE.BRAINSTORM,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.BRAINSTORM],
    name: BRAINSTORM_TYPE_LABELS[BRAINSTORM_TYPES.INFLUENCER_MARKETING],
    placeholder:
      BRAINSTORM_TYPE_PLACEHOLDERS[BRAINSTORM_TYPES.INFLUENCER_MARKETING],
    description:
      "Optimize influencer collaborations to benefit your brand. It's your best bet for boosting reach during product launches or sponsored campaigns.",
  },
  {
    category: TOOL_TYPE.BRAINSTORM,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.BRAINSTORM],
    id: BRAINSTORM_TYPES.BIG_IDEAS,
    name: BRAINSTORM_TYPE_LABELS[BRAINSTORM_TYPES.BIG_IDEAS],
    placeholder: BRAINSTORM_TYPE_PLACEHOLDERS[BRAINSTORM_TYPES.BIG_IDEAS],
    description:
      "Adopt design thinking to generate innovative ideas. Leverage it when you need to get the creative problem-solving gears turning.",
  },
  {
    id: BRAINSTORM_TYPES.MOBILE_MARKETING,
    category: TOOL_TYPE.BRAINSTORM,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.BRAINSTORM],
    name: BRAINSTORM_TYPE_LABELS[BRAINSTORM_TYPES.MOBILE_MARKETING],
    placeholder:
      BRAINSTORM_TYPE_PLACEHOLDERS[BRAINSTORM_TYPES.MOBILE_MARKETING],
    description:
      "Craft engaging techniques to connect with your audience on their smartphones. It's ideal for campaigns targeting mobile users, like app-exclusive discounts.",
  },
  {
    category: TOOL_TYPE.BRAINSTORM,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.BRAINSTORM],
    id: BRAINSTORM_TYPES.INVENTO,
    name: BRAINSTORM_TYPE_LABELS[BRAINSTORM_TYPES.INVENTO],
    placeholder: BRAINSTORM_TYPE_PLACEHOLDERS[BRAINSTORM_TYPES.INVENTO],
    description:
      "Generate potential product or service ideas that could shake up the market. Use it when you're seeking inspiration for your next innovation.",
  },
  {
    category: TOOL_TYPE.BRAINSTORM,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.BRAINSTORM],
    id: BRAINSTORM_TYPES.NEWSLETTER_TOPICS,
    name: BRAINSTORM_TYPE_LABELS[BRAINSTORM_TYPES.NEWSLETTER_TOPICS],
    placeholder:
      BRAINSTORM_TYPE_PLACEHOLDERS[BRAINSTORM_TYPES.NEWSLETTER_TOPICS],
    description:
      "Deliver intriguing newsletter topics to your email subscribers. This is your ally when you need to keep providing valuable content consistently.",
  },
  {
    category: TOOL_TYPE.BRAINSTORM,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.BRAINSTORM],
    id: BRAINSTORM_TYPES.PODCAST_EPISODES,
    name: BRAINSTORM_TYPE_LABELS[BRAINSTORM_TYPES.PODCAST_EPISODES],
    placeholder:
      BRAINSTORM_TYPE_PLACEHOLDERS[BRAINSTORM_TYPES.PODCAST_EPISODES],
    description:
      "Perk up your podcast with a plethora of unique ideas this tool brings to the table. Give it a spin when your content needs that spark of freshness.",
  },
  {
    category: TOOL_TYPE.BRAINSTORM,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.BRAINSTORM],
    id: BRAINSTORM_TYPES.PROBLEM_SOLVER,
    name: BRAINSTORM_TYPE_LABELS[BRAINSTORM_TYPES.PROBLEM_SOLVER],
    placeholder: BRAINSTORM_TYPE_PLACEHOLDERS[BRAINSTORM_TYPES.PROBLEM_SOLVER],
    description:
      "Unlock inventive solutions to gnarly problems with this handy assistant. It's your go-to companion when facing hurdles in business or projects.",
  },
  {
    category: TOOL_TYPE.BRAINSTORM,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.BRAINSTORM],
    id: BRAINSTORM_TYPES.PUBLICITY_STUNT,
    name: BRAINSTORM_TYPE_LABELS[BRAINSTORM_TYPES.PUBLICITY_STUNT],
    placeholder: BRAINSTORM_TYPE_PLACEHOLDERS[BRAINSTORM_TYPES.PUBLICITY_STUNT],
    description:
      "Capture the public eye with audacious publicity stunts, curated by this tool. Bring it into play when you're aiming for a splash on the social scene.",
  },
  {
    category: TOOL_TYPE.BRAINSTORM,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.BRAINSTORM],
    id: BRAINSTORM_TYPES.STRATEGY_THINKING,
    name: BRAINSTORM_TYPE_LABELS[BRAINSTORM_TYPES.STRATEGY_THINKING],
    placeholder:
      BRAINSTORM_TYPE_PLACEHOLDERS[BRAINSTORM_TYPES.STRATEGY_THINKING],
    description:
      "Jumpstart strategic thought processes to tackle business challenges, courtesy of this tool. Steer critical decisions like product launches or refining customer experience.",
  },
  {
    category: TOOL_TYPE.BRAINSTORM,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.BRAINSTORM],
    id: BRAINSTORM_TYPES.VIRAL_IDEAS,
    name: BRAINSTORM_TYPE_LABELS[BRAINSTORM_TYPES.VIRAL_IDEAS],
    placeholder: BRAINSTORM_TYPE_PLACEHOLDERS[BRAINSTORM_TYPES.VIRAL_IDEAS],
    description:
      "Ignite the internet with viral content ideas, all served up by this tool. When it's time for your next traffic-drawing video, remember to check in here.",
  },
  {
    category: TOOL_TYPE.BRAINSTORM,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.BRAINSTORM],
    id: BRAINSTORM_TYPES.VIDEO_TREATMENT,
    name: BRAINSTORM_TYPE_LABELS[BRAINSTORM_TYPES.VIDEO_TREATMENT],
    placeholder: BRAINSTORM_TYPE_PLACEHOLDERS[BRAINSTORM_TYPES.VIDEO_TREATMENT],
    description:
      "Shape your video's narrative or product showcase with key elements outlined by this tool. It's your storyboard assistant when you're piecing together a video project.",
  },
  {
    id: BRAINSTORM_TYPES.PROMOTION_IDEA,
    category: TOOL_TYPE.BRAINSTORM,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.BRAINSTORM],
    name: BRAINSTORM_TYPE_LABELS[BRAINSTORM_TYPES.PROMOTION_IDEA],
    placeholder: BRAINSTORM_TYPE_PLACEHOLDERS[BRAINSTORM_TYPES.PROMOTION_IDEA],
    description:
      "A creative brainstorming tool designed to generate innovative promotion ideas to enhance your marketing efforts, boost sales, and elevate your brand visibility.",
  },

  // correspondence
  {
    id: CORRESPONDENCE_TYPES.APOLOGY,
    category: TOOL_TYPE.CORRESPONDENCE,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.CORRESPONDENCE],
    name: CORRESPONDENCE_TYPE_LABELS[CORRESPONDENCE_TYPES.APOLOGY],
    placeholder: CORRESPONDENCE_TYPE_PLACEHOLDERS[CORRESPONDENCE_TYPES.APOLOGY],
    description:
      "Craft heartfelt apologies with tact and sincerity. This tool supports you in writing effective, empathetic messages to mend relationships and rebuild trust after a misstep.",
  },
  {
    id: CORRESPONDENCE_TYPES.CALL_SCRIPT,
    category: TOOL_TYPE.CORRESPONDENCE,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.CORRESPONDENCE],
    name: CORRESPONDENCE_TYPE_LABELS[CORRESPONDENCE_TYPES.CALL_SCRIPT],
    placeholder:
      CORRESPONDENCE_TYPE_PLACEHOLDERS[CORRESPONDENCE_TYPES.CALL_SCRIPT],
    description:
      "Craft smooth and compelling call scripts that steer conversations toward positive outcomes. Ideal for enhancing dialogues with potential leads or valued customers.",
  },
  {
    id: CORRESPONDENCE_TYPES.EMAIL_SEQUENCE,
    category: TOOL_TYPE.CORRESPONDENCE,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.CORRESPONDENCE],
    name: CORRESPONDENCE_TYPE_LABELS[CORRESPONDENCE_TYPES.EMAIL_SEQUENCE],
    placeholder:
      CORRESPONDENCE_TYPE_PLACEHOLDERS[CORRESPONDENCE_TYPES.EMAIL_SEQUENCE],
    description:
      "Conjure up a captivating email sequence that keeps your audience intrigued and connected. Perfect for establishing a consistent, engaging dialogue with your clientele.",
  },
  {
    id: CORRESPONDENCE_TYPES.INFLUENCER_OUTREACH,
    category: TOOL_TYPE.CORRESPONDENCE,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.CORRESPONDENCE],
    name: CORRESPONDENCE_TYPE_LABELS[CORRESPONDENCE_TYPES.INFLUENCER_OUTREACH],
    placeholder:
      CORRESPONDENCE_TYPE_PLACEHOLDERS[
        CORRESPONDENCE_TYPES.INFLUENCER_OUTREACH
      ],
    description:
      "Forge meaningful connections with influencers to amplify your product or service. Use this when a product launch or service roll-out could use a boost from engaged influencer audiences.",
  },
  {
    id: CORRESPONDENCE_TYPES.LETTER,
    category: TOOL_TYPE.CORRESPONDENCE,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.CORRESPONDENCE],
    name: CORRESPONDENCE_TYPE_LABELS[CORRESPONDENCE_TYPES.LETTER],
    placeholder: CORRESPONDENCE_TYPE_PLACEHOLDERS[CORRESPONDENCE_TYPES.LETTER],
    description:
      "Effortlessly craft diverse types of letters - be it sales, proposals, personal, legal, or business - with precision and finesse. An all-encompassing tool for your letter-writing needs.",
  },
  {
    id: CORRESPONDENCE_TYPES.MEMO,
    category: TOOL_TYPE.CORRESPONDENCE,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.CORRESPONDENCE],
    name: CORRESPONDENCE_TYPE_LABELS[CORRESPONDENCE_TYPES.MEMO],
    placeholder: CORRESPONDENCE_TYPE_PLACEHOLDERS[CORRESPONDENCE_TYPES.MEMO],
    description:
      "Draft crystal-clear memos that disseminate essential information to your team. Ideal for keeping everyone in your organization on the same page.",
  },
  {
    id: CORRESPONDENCE_TYPES.MEDIA_STATEMENT,
    category: TOOL_TYPE.CORRESPONDENCE,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.CORRESPONDENCE],
    name: CORRESPONDENCE_TYPE_LABELS[CORRESPONDENCE_TYPES.MEDIA_STATEMENT],
    placeholder:
      CORRESPONDENCE_TYPE_PLACEHOLDERS[CORRESPONDENCE_TYPES.MEDIA_STATEMENT],
    description:
      "Convey your organization's perspective on crucial matters effectively. Use this when it's time to share your official standpoint with the media.",
  },
  {
    id: CORRESPONDENCE_TYPES.PERFECT_EMAIL,
    category: TOOL_TYPE.CORRESPONDENCE,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.CORRESPONDENCE],
    name: CORRESPONDENCE_TYPE_LABELS[CORRESPONDENCE_TYPES.PERFECT_EMAIL],
    placeholder:
      CORRESPONDENCE_TYPE_PLACEHOLDERS[CORRESPONDENCE_TYPES.PERFECT_EMAIL],
    description:
      "Compose standout emails that are sure to elicit responses. Turn to this tool for any email exchange, be it a formal proposal, newsletter, or a personal message.",
  },
  {
    id: CORRESPONDENCE_TYPES.PRESS_RELEASE,
    category: TOOL_TYPE.CORRESPONDENCE,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.CORRESPONDENCE],
    name: CORRESPONDENCE_TYPE_LABELS[CORRESPONDENCE_TYPES.PRESS_RELEASE],
    placeholder:
      CORRESPONDENCE_TYPE_PLACEHOLDERS[CORRESPONDENCE_TYPES.PRESS_RELEASE],
    description:
      "Articulate official news updates about your company that grab media attention. Deploy this when you have exciting updates to share with the public.",
  },
  {
    id: CORRESPONDENCE_TYPES.QUESTION_ANSWERING,
    category: TOOL_TYPE.CORRESPONDENCE,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.CORRESPONDENCE],
    name: CORRESPONDENCE_TYPE_LABELS[CORRESPONDENCE_TYPES.QUESTION_ANSWERING],
    placeholder:
      CORRESPONDENCE_TYPE_PLACEHOLDERS[CORRESPONDENCE_TYPES.QUESTION_ANSWERING],
    description:
      "Obtain concise and accurate answers to your burning questions. Use this when you need quick and reliable responses to specific queries.",
  },
  {
    id: CORRESPONDENCE_TYPES.ESSAY_WRITER,
    category: TOOL_TYPE.CORRESPONDENCE,
    icon: TOOL_TYPE_ICONS[TOOL_TYPE.CORRESPONDENCE],
    name: CORRESPONDENCE_TYPE_LABELS[CORRESPONDENCE_TYPES.ESSAY_WRITER],
    placeholder: CORRESPONDENCE_TYPE_PLACEHOLDERS[CORRESPONDENCE_TYPES.ESSAY_WRITER],
    description:
      "Craft well-structured and compelling essays on various topics. Use this tool to generate thoughtful, articulate essays for academic, professional, or personal purposes.",
  },
]
