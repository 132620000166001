<script setup>
import { onBeforeMount } from "vue"
import { useRouter } from "vue-router"
import routerNames from "@/configs/routerNames"

const router = useRouter()

onBeforeMount(() => {
  router.replace({ name: routerNames.LOGIN })
})
</script>

<template>
  <div class="w-full"></div>
</template>
