import { http } from "@/utils"
import store from "@/store"
import { GETTERS } from "@/store/constants"

/**
 * @param {import("@auth0/auth0-vue").User} auth0user
 * @returns {Promise<{
 *   user: User;
 *   cards: Array<any>;
 *   subscription: Record<string, any>;
 *   redirectUrl: string|undefined;
 *   adminAccount: Account|undefined;
 * }>}
 */

export const authenticate = async (auth0user) => {
  try {
    const payload = {
      ...JSON.parse(JSON.stringify(auth0user)),
      origin: window.origin,
    }
    const { data } = await (await http()).post("/authenticate", payload)
    
    // Check if there is data that indicates an upgrade is in process
    // if (data.upgrading) {
      // Store the necessary upgrading data in local storage
      // const upgrading_data = {
      //   clientSecret: data.clientSecret,
      //   upgrading: data.upgrading,
      // };
      // Use JSON.stringify to store the object as a string
      // localStorage.setItem('upgradingData', JSON.stringify(upgrading_data));
    // }
    
    return {
      upgrading: data.upgrading,
      user: data.user,
      cards: data.cards,
      team: data.team,
      subscription: data.subscription,
      redirectUrl: data.redirectUrl,
      tokenInfo: data.tokenInfo,
      adminAccount: data.adminAccount, // this is only for the invited user
    }
    
  } catch (err) {
    if (err.response && err.response.data?.redirectUrl) {
      return {
        redirectUrl: err.response.data.redirectUrl,
        user: null,
        team: null,
        cards: [],
        subscription: null,
        adminAccount: null,
      }
    } else {
      window.devErr("error while authenticating:", err.response || err)
      throw err
    }
  }
}

export const logout = async () => {
  await (await http()).post("/logout")
}

export const deleteAccount = async () => {
  const user = store.getters[GETTERS.USER]
  if (!user || !user._id) throw new Error("No logged in user found!")
  await (await http()).delete("/user/account-deletion/" + user._id)
}