export default {
  chatHistoryRepo: [],
  chatHistory: [],
  chatHistoryCurrentPage: 1,
  hasNextPage: false,
  sessionId: "",
  userReply: "",
  isUserReply: "",
  showStopBtn: false,
  objective: "",
  taskList: "", // in raw JSON
  showQuickStarts: true,
}
