import { ALL_TOOLS_LIST } from "@/constants/allToolsList"
import { paymentService, userService } from "@/services"
import { COMMITS } from "./constants"
import { http } from "@/utils"

export async function getAllUserInfo({ state, commit }) {
  const userId = state.user?._id
  if (!userId) {
    return
  }

  const { data } = await userService.getAllUserInfo(userId)
  if (data.user) {
    commit(COMMITS.SET_USER, data.user)
  }
  if (data.subscription) {
    commit(COMMITS.SET_SUBSCRIPTION, data.subscription)
  }
  if (data.team) {
    commit(COMMITS.SET_TEAM, data.team)
  }
  if (data.cards) {
    commit(COMMITS.SET_CARDS, data.cards)
  }
  if (data.cards) {
    commit(COMMITS.SET_CARDS, data.cards)
  }

  await fetchTokenInfo({ commit })
}

export const toggleSidebarExpanded = ({ state, commit }) => {
  commit("setSidebarExpanded", !state.sidebarExpanded)
}

export const selectTool = ({ commit }, category, id) => {
  let tool
  if (id) {
    tool = ALL_TOOLS_LIST.find((t) => t.id === id)
  } else {
    tool = ALL_TOOLS_LIST.filter((t) => t.category === category)[0]
  }
  if (tool) commit("setSelectedTool", tool)
}

export const getSubscriptionInfo = async ({ state }) => {
  if (!state.user || !state.account) return
  if (state.user.sumoling) {
    await paymentService.getAppSumoSub()
  } else if (state.account.subscription_id) {
    // await paymentService.getSubscriptionInformation()
  }
}

export const toggleGenerating = ({ state, commit }) => {
  commit("setGenerating", !state.generating)
}

export const selectATag = ({ state, commit }, tagId) => {
  if (state.selectedTags.find((tag) => tag._id === tagId)) return

  const tag = state.allTags.find((tag) => tag._id === tagId)
  if (!tag) return

  commit("setSelectedTags", [...state.selectedTags, tag])
}

export const removeSelectedTag = ({ state, commit }, tagId) => {
  const tags = state.selectedTags.filter((tag) => tag._id !== tagId)
  commit("setSelectedTags", tags)
}

export const hideTutorial = ({ commit }) => {
  commit("setShowTutorialModal", false)
  commit("setTutorialLink", "")
}

export const showTutorial = ({ commit }, link) => {
  commit("setTutorialLink", link)
  commit("setShowTutorialModal", true)
}

export const addAlerts = ({ commit, getters }, alert) => {
  const alerts = getters.getAlerts
  commit("setAlerts", [...alerts, alert])
}

export const pinTool = ({ dispatch }, id) => {
  const prev = JSON.parse(
    localStorage.getItem("helloscribe.locals.pinned_tools") || "[]",
  )
  if (prev.includes(id)) {
    localStorage.setItem(
      "helloscribe.locals.pinned_tools",
      JSON.stringify(prev.filter((i) => i !== id)),
    )
  } else {
    prev.push(id)
    localStorage.setItem(
      "helloscribe.locals.pinned_tools",
      JSON.stringify(prev),
    )
  }

  dispatch("setupAllTools")
}

export const setupAllTools = ({ commit }) => {
  const pinnedList = JSON.parse(
    localStorage.getItem("helloscribe.locals.pinned_tools") || "[]",
  )
  const tools = ALL_TOOLS_LIST.map((tool) => ({
    ...tool,
    pinned: pinnedList.includes(tool.id),
  }))
  commit(COMMITS.SET_ALL_TOOLS, tools)
}

export const updateThemePreference = ({ commit }, darkMode) => {
  const USER_PREF_KEY = "helloscribe.locals.user.theme_preference"
  const storedPref = localStorage.getItem(USER_PREF_KEY)

  let isDarkMode = true
  if (typeof darkMode === "undefined") {
    // if (storedPref) {
    isDarkMode = storedPref !== "light"
    // } else {
    //   isDarkMode =
    //     window.matchMedia &&
    //     window.matchMedia("(prefers-color-scheme: dark)").matches
    // }
  } else {
    isDarkMode = darkMode
  }

  localStorage.setItem(USER_PREF_KEY, isDarkMode ? "dark" : "light")
  commit(COMMITS.SET_DARK_MODE, isDarkMode)
}

export const cancelSubscription = async () => {
  await (await http()).post("/subscription/cancel")
}

export async function fetchTokenInfo({ commit }) {
  const info = await paymentService.getTokenInfo()
  commit("setTokenInfo", info)
}