import { onMounted, onUnmounted } from "vue"

/**
 * @param {string | string[]} elementId - can watch either one or multiple elements
 * @param {() => void} handleOutsideClick
 */
export const useOutsideClick = (elementId, handleOutsideClick) => {
  const listener = (e) => {
    /** @type {HTMLElement[]}*/
    const elements = []
    let isOutsideClick = true

    if (typeof elementId === "string") {
      const el = document.getElementById(elementId)
      if (el) elements.push(el)
    } else if (Array.isArray(elementId)) {
      elementId.forEach((id) => {
        const el = document.getElementById(id)
        if (el) elements.push(el)
      })
    }

    elements.forEach((el) => {
      if (el && el.contains(e.target)) {
        isOutsideClick = false
      }
    })

    if (isOutsideClick) handleOutsideClick()
  }

  onMounted(() => {
    document.addEventListener("click", listener)
  })

  onUnmounted(() => {
    document.removeEventListener("click", listener)
  })
}
