import routerNames from "@/configs/routerNames"
import { authGuard } from "@auth0/auth0-vue"
import PageLayout from "@/layouts/PageLayout.vue"

/**
 * @name authRoutes
 * @type {import("vue-router").RouteRecordRaw}
 */
export default {
  path: "/projects",
  beforeEnter: authGuard,
  component: PageLayout,
  meta: { requiresAuth: true },
  children: [
    {
      path: "",
      name: routerNames.EXPLORER_PROJECTS,
      component: () =>
        import("@/modules/projects/views/ProjectsExplorerView.vue"),
    },
    {
      path: ":project_id",
      name: routerNames.PROJECT_OVERVIEW,
      component: () => import("@/modules/projects/views/SingleProjectView.vue"),
    },
  ],
}