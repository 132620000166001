const APP_NAME = "app-name"
const ARTICLE_HEADLINE = "article-headline"
const BOOK_TITLE = "book-title"
const CONFERENCE_SESSION_TITLE = "conference-session-title"
const EMAIL_SUBJECT = "email-subject"
const EVENT_NAME = "event-name"
const FACEBOOK_ADS_HEADLINE = "facebook-ads-headline"
const GENERAL_HEADLINE = "general-headline"
const GOOGLE_AD_HEADLINE = "google-ad-headline"
const HEADLINE_ONLY = "headline-only"
const LANDING_PAGE_HEADLINE = "landing-page-headline"
const LINKEDIN_PROFILE_HEADLINE = "linkedin-profile-headline"
const PINTEREST_HEADLINE = "pinterest-headline"
const PODCAST_EPISODE_TITLE = "podcast-episode-title"
const PRESS_RELEASE_HEADLINES = "press-release-headlines"
const PRODUCT_NAME = "product-name"
const RESEARCH_PAPER_TITLE = "research-paper-title"
const SEO_META_DESCRIPTION = "seo-meta-description"
const WEBSITE_HERO_TITLE = "website-hero-title"
const YOUTUBE_TITLE = "youtube-title"

export const HEADLINE_TYPES = {
  APP_NAME,
  ARTICLE_HEADLINE,
  BOOK_TITLE,
  CONFERENCE_SESSION_TITLE,
  EVENT_NAME,
  EMAIL_SUBJECT,
  FACEBOOK_ADS_HEADLINE,
  GENERAL_HEADLINE,
  GOOGLE_AD_HEADLINE,
  HEADLINE_ONLY,
  LANDING_PAGE_HEADLINE,
  LINKEDIN_PROFILE_HEADLINE,
  PRODUCT_NAME,
  PODCAST_EPISODE_TITLE,
  PRESS_RELEASE_HEADLINES,
  PINTEREST_HEADLINE,
  SEO_META_DESCRIPTION,
  RESEARCH_PAPER_TITLE,
  WEBSITE_HERO_TITLE,
  YOUTUBE_TITLE,
}

export const HEADLINE_TYPE_LABELS = {
  [HEADLINE_ONLY]: "Ad Headline",
  [APP_NAME]: "App Name",
  [ARTICLE_HEADLINE]: "Article Headline",
  [BOOK_TITLE]: "Book Title",
  [EMAIL_SUBJECT]: "Email Subject Line",
  [FACEBOOK_ADS_HEADLINE]: "Facebook Ad Headline",
  [GENERAL_HEADLINE]: "General Headline",
  [GOOGLE_AD_HEADLINE]: "Google Ad Headline",
  [LINKEDIN_PROFILE_HEADLINE]: "LinkedIn Profile Headline",
  [PINTEREST_HEADLINE]: "Pinterest Title",
  [PRODUCT_NAME]: "Product Name",
  [RESEARCH_PAPER_TITLE]: "Research Paper Title",
  [RESEARCH_PAPER_TITLE]: "Research Paper Title",
  [SEO_META_DESCRIPTION]: "SEO Meta Description",
  [WEBSITE_HERO_TITLE]: "Website Hero Text",
  [YOUTUBE_TITLE]: "Youtube Video Title",
  [EVENT_NAME]: "Event Name",
  [CONFERENCE_SESSION_TITLE]: "Conference Session Title",
  [LANDING_PAGE_HEADLINE]: "Landing Page Headline",
  [RESEARCH_PAPER_TITLE]: "Research Paper Title",
  [APP_NAME]: "App Name",
  [PODCAST_EPISODE_TITLE]: "Podcast Episode Title",
  [PRESS_RELEASE_HEADLINES]: "Press Release Headlines",
}

export const HEADLINE_TYPE_PLACEHOLDERS = {
  [HEADLINE_ONLY]:
    "A headline for Grass, a new app that helps you find a lawn care service.",
  [ARTICLE_HEADLINE]:
    "e.g. A headline for an article about artificial intelligence.",
  [BOOK_TITLE]: "e.g. A title for a book about finding joy in your work",
  [EMAIL_SUBJECT]: "e.g. A subject line for an email requesting a meeting",
  [GENERAL_HEADLINE]:
    "e.g. A headline for a blog post about the future of work",
  [PRODUCT_NAME]:
    "e.g. A name for a new product that helps people work more efficiently",
  [WEBSITE_HERO_TITLE]:
    "e.g. A website hero title for a company that sells productivity tools",
  [GOOGLE_AD_HEADLINE]:
    "e.g A headline for a new productivity tool for professionals",
  [FACEBOOK_ADS_HEADLINE]:
    "e.g A headline for a new line of writing tools for professionals ",
  [LINKEDIN_PROFILE_HEADLINE]: "Enter your title and occupation...",
  [PINTEREST_HEADLINE]:
    "e.g. A title for a pin about how to improve your productivity",
  [SEO_META_DESCRIPTION]:
    "e.g. A meta description for a blog post about artificial intelligence.",
  [EVENT_NAME]: "Enter the event's description",
  [YOUTUBE_TITLE]: "e.g. A title for a video about the future of AI",
  [CONFERENCE_SESSION_TITLE]:
    "Suggest a title for a session on the impact of climate change on marine life.",
  [LANDING_PAGE_HEADLINE]:
    "Craft a headline for our new range of organic skincare products.",
  [RESEARCH_PAPER_TITLE]:
    "I need a title for my paper on the socio-economic effects of urbanization in Southeast Asia.",
  [APP_NAME]:
    "Brainstorm a name for a new meditation app focused on nature sounds.",
  [PODCAST_EPISODE_TITLE]:
    "Generate a title for an episode exploring the mysteries of ancient civilizations.",
  [PRESS_RELEASE_HEADLINES]:
    "Propose a headline for our collaboration with a renowned tech innovator.",
}
