import { GETTERS } from "@/store/constants"
import { sortByCreatedAtAscending, sortByCreatedAtDescending } from "@/utils"
import { NOTES_PER_PAGE } from "@/configs/constants"

/** @type {import("vuex").GetterTree} */
export default {
  getAllNotes(state, getters, rootState, rootGetters) {
    const searchIndex = getters.getSearchIndex
    const selectedTags = rootState.selectedTags
    const notesFromCurrentProject = state.notesFromCurrentProject
    const currentPage = state.currentPage
    const selectedProject = rootGetters[GETTERS.SELECTED_PROJECT]
    const filterType = state.notesFilterType
    const rgx = new RegExp(searchIndex, "gi")

    /** @type {Note[]} */
    let allNotes = state.notesRepo.filter((note) => {
      let tagFound = false

      if (!rgx.test(note.slug) && !rgx.test(note.text)) return false

      if (
        notesFromCurrentProject &&
        selectedProject &&
        note.project !== selectedProject._id
      ) {
        return false
      }

      if (selectedTags.length > 0) {
        selectedTags.forEach((tag) => {
          note.tags.forEach((item) => {
            if (item.name === tag.name) tagFound = true
          })
        })
      } else {
        tagFound = true
      }

      return tagFound
    })

    const FILTER_TYPE = {
      newest: "Newest",
      oldest: "Oldest",
      a_z: "A-Z",
      z_a: "Z-A",
    }
    if (allNotes.length > 1) {
      switch (filterType) {
        case FILTER_TYPE.oldest:
          allNotes = allNotes.sort(sortByCreatedAtAscending)
          break
        case FILTER_TYPE.a_z:
          allNotes = allNotes.sort((a, b) => {
            return a.slug.localeCompare(b.slug)
          })
          break
        case FILTER_TYPE.z_a:
          allNotes = allNotes.sort((a, b) => {
            return b.slug.localeCompare(a.slug)
          })
          break
        default:
          allNotes = allNotes.sort(sortByCreatedAtDescending)
          break
      }
    }

    const start = NOTES_PER_PAGE * (currentPage - 1)
    const end = NOTES_PER_PAGE * currentPage
    return allNotes.slice(start, end)
  },

  getNotesRepo(state) {
    return state.notesRepo
  },

  getGettingNotes(state) {
    return state.gettingNotes
  },

  getSearchIndex(state) {
    return state.searchIndex
  },

  getSelectedNote(state) {
    return state.selectedNote
  },

  getShowNoteEditor(state) {
    return state.showNoteEditor
  },

  getOnlyCurrentProjectNotes(state) {
    return state.notesFromCurrentProject
  },

  getNotesFilterType(state) {
    return state.notesFilterType
  },

  getCurrentPage(state) {
    return state.currentPage
  },
}
