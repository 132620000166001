/** @type {import("vuex").ActionTree} */
export default {
  gotoNextStep({ commit, getters, state }) {
    commit('setOnboardingError', '')

    const currentStep = state.onboardingStep
    const fullname = getters.getFullname
    const industry = getters.getIndustry
    const usageType = getters.getUsageType
    const sourceType = getters.getSourceType

    // Validation logic
    if (currentStep === 1) {
      if (fullname.length < 2) {
        commit('setOnboardingError', 'Full name must be at least 2 characters long')
        return
      }
      if (!industry) {
        commit('setOnboardingError', 'Please select an industry')
        return
      }
    } else if (currentStep === 2) {
      if (!usageType) {
        commit('setOnboardingError', 'Please select a usage type')
        return
      }
    } else if (currentStep === 3) {
      if (!sourceType) {
        commit('setOnboardingError', 'Please select a source type')
        return
      }
    }

    // Proceed to next step
    if (currentStep < getters.totalSteps) {
      commit('setOnboardingStep', currentStep + 1)
    } else {
      commit('setOnboardingComplete', true)
    }
  },

  gotoPreviousStep({ commit, state }) {
    if (state.onboardingStep > 1) {
      commit('setOnboardingStep', state.onboardingStep - 1)
    }
  },

  skipToNextStep({ commit, getters }) {
    commit("setOnboardingError", "")

    const currentStep = getters.getOnboardingStep
    const totalSteps = getters.totalSteps
    const usageType = getters.getUsageType
    const sourceType = getters.getSourceType

    if (currentStep === 2) {
      if (!usageType) commit("setUsageType", "Others")
    } else if (currentStep === 3) {
      if (!sourceType) commit("setSourceType", "Others")
    }

    if (currentStep < totalSteps) {
      commit("setOnboardingStep", currentStep + 1)
    } else {
      commit('setOnboardingComplete', true)
    }
  },
}