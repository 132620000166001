import routerNames from "@/configs/routerNames"
import { authGuard } from "@auth0/auth0-vue"
import OnboardingLayout from "@/layouts/OnboardingLayout.vue"

/**
 * @name onboardingRoutes
 * @type {import("vue-router").RouteRecordRaw}
 */
export default {
  path: "/onboarding",
  beforeEnter: authGuard,
  component: OnboardingLayout,
  children: [
    {
      path: "",
      name: routerNames.ONBOARDING,
      component: () => import("@/modules/onboarding/views/OnboardUserView.vue"),
    },
    {
      path: "pricing",
      name: routerNames.PRICING,
      component: () => import("@/modules/onboarding/views/PricingView.vue"),
    },
    {
      path: "success/:name",
      name: routerNames.SUBSCRIPTION_SUCCESS,
      component: () =>
        import("@/modules/onboarding/views/SuccessRedirectView.vue"),
    },
  ],
}