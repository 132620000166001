import { http } from "@/utils"

/**
 * A helper function to prevent throwing error.
 */
const getResults = async (client, path, query) => {
  try {
    const res = await client.post(path, { query })
    if (Array.isArray(res.data.results)) {
      return res.data.results.filter((r) => !!r)
    }
    return []
  } catch (err) {
    window.devErr(err)
    return []
  }
}

/** @type {import("vuex").ActionTree} */
export default {
  async startSearching({ getters, commit }) {
    try {
      commit("setSearching", true)

      const query = getters.getQuery
      const client = await http()
      const promises = [
        getResults(client, "/doc/news-info", query),
        getResults(client, "/doc/semantic-scholar-academic-info", query),
        getResults(client, "/facts/search", query),
      ]
      const [newsRes, scholarRes, factRes] = await Promise.all(promises)
      commit("setNewsResults", newsRes)
      commit("setScholarResults", scholarRes)
      commit("setFactResults", factRes)
    } catch (err) {
      window.devErr(err.response || err)
    } finally {
      commit("setSearching", false)
    }
  },

  async saveResultToNote() {},
}
