<script setup>
import { computed, onBeforeMount, watch, defineAsyncComponent } from "vue"
import { useStore } from "vuex"
import { ACTIONS, GETTERS } from "@/store/constants"

const BackToTop = defineAsyncComponent(() =>
  import("@/components/BackToTop.vue"),
)
const AlertsContainer = defineAsyncComponent(() =>
  import("@/modules/alerts/components/AlertsContainer.vue"),
)
const GlobalAlertModal = defineAsyncComponent(() =>
  import("@/components/GlobalAlertModal.vue"),
)
const ToastsContainer = defineAsyncComponent(() =>
  import("@/modules/alerts/components/ToastsContainer.vue"),
)

const store = useStore()
const darkMode = computed(() => store.getters[GETTERS.DARK_MODE])

const activateDarkMode = () => {
  if (darkMode.value) {
    if (!document.body.classList.contains("dark")) {
      document.body.classList.add("dark")
    }
  } else {
    document.body.classList.remove("dark")
  }
}

watch(darkMode, () => activateDarkMode())

onBeforeMount(async () => {
  await store.dispatch(ACTIONS.UPDATE_THEME_PREFERENCE)
  activateDarkMode()
})

</script>

<template>
  <BackToTop />
  <RouterView />
  <AlertsContainer />
  <GlobalAlertModal />
  <ToastsContainer />
</template>