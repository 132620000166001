const globalBaseComponents = import.meta.glob("./*.vue")

/**
 * @param {import("vue").App} app
 */
const registerSkeletonComponents = (app) => {
  for (const path in globalBaseComponents) {
    globalBaseComponents[path]().then((componentModule) => {
      const componentName = path
        .split("/")
        .pop()
        ?.replace(/\.\w+$/, "")
      if (componentName)
        app.component(componentName, componentModule.default || componentModule)
    })
  }
}

export default registerSkeletonComponents
