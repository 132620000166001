export const PROJECT_TYPE = {
  TEAM: "Team",
  PERSONAL: "Personal",
}

export const PROJECT_STATUS = {
  IN_PROGRESS: "In Progress",
  COMPLETED: "Completed",
}

export const NOTES_PER_PAGE = 6
export const PROJECTS_PER_PAGE = 6
export const TOOL_FILES_PER_PAGE = 24
export const AGENT_CHAT_HISTORY_PER_PAGE = 5
