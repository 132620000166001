const INTERVIEW = "Interview"
const RESEARCH = "Research"
const SURVEY = "Survey"

export const QUESTION_TYPE = {
  INTERVIEW,
  RESEARCH,
  SURVEY,
}

export const QUESTION_TYPE_LABELS = {
  [INTERVIEW]: "Interview Question",
  [RESEARCH]: "Research Question",
  [SURVEY]: "Survey Question",
}
export const QUESTION_TYPE_PLACEHOLDERS = {
  [INTERVIEW]:
    "e.g. Interview with the founder of SpaceX about his vision for Mars Travel",
  [RESEARCH]: "e.g. A study on the impact of AI on the professional workplace",
  [SURVEY]:
    "e.g. A survey to understand the opinions of the public on artificial intelligence",
}
