import { markRaw } from "vue"
import routerNames from "@/configs/routerNames"
import FolderIcon from "@/components/Icons/FolderIcon.vue"
import HeadlineIcon from "@/components/Icons/HeadlineIcon.vue"
import MessagingIcon from "@/components/Icons/MessagingIcon.vue"
import BrainstormIcon from "@/components/Icons/BrainstormIcon.vue"
import SocialMediaIcon from "@/components/Icons/SocialMediaIcon.vue"
import AdCopyIcon from "@/components/Icons/AdCopyIcon.vue"
import ArticlesIcon from "@/components/Icons/ArticlesIcon.vue"
import MediapitchIcon from "@/components/Icons/MediapitchIcon.vue"
import QuestionIcon from "@/components/Icons/QuestionIcon.vue"
import QuotesIcon from "@/components/Icons/QuotesIcon.vue"
import SummaryIcon from "@/components/Icons/SummaryIcon.vue"
import CorrespondenceIcon from "@/components/Icons/CorrespondenceIcon.vue"
import EntertainmentIcon from "@/components/Icons/EntertainmentIcon.vue"

const NONE = "none" // this means a folder
const HEADLINES = "Headlines"
const MESSAGING = "Messaging"
const BRAINSTORM = "Brainstorm & Strategy"
const SOCIALMEDIA = "Social"
const ADCOPY = "Ad Copy"
const FIRSTDRAFT = "Articles"
const MEDIAPITCH = "Pitches"
const QUESTION = "Questions"
const QUOTES = "Quotes"
const SUMMARY = "Writer's Toolkit"
const CORRESPONDENCE = "Correspondence"
const ENTERTAINMENT = "Creative Writing"
/*--------------------------------------*/
const prev_BRAINSTORM = "Brainstorm"
const prev_ADCOPY = "Copywriter Pro"
const prev_MEDIAPITCH = "Media Pitch"
const prev_FIRSTDRAFT = "First Draft Writer"
const prev_HEADLINE = "Headline Genius"
const prev_MESSAGING = "Brand Message"
const prev_SOCIALMEDIA = "Social Media"
const prev_QUOTES = "Quote Maker"
const prev_QUESTION = "Question Creator"

export const TOOL_TYPE = {
  // NONE,
  HEADLINES,
  MESSAGING,
  BRAINSTORM,
  SOCIALMEDIA,
  ADCOPY,
  FIRSTDRAFT,
  MEDIAPITCH,
  QUESTION,
  QUOTES,
  SUMMARY,
  CORRESPONDENCE,
  ENTERTAINMENT,
  // fallback --------------------
  prev_BRAINSTORM,
  prev_ADCOPY,
  prev_MEDIAPITCH,
  prev_FIRSTDRAFT,
  prev_HEADLINE,
  prev_MESSAGING,
  prev_SOCIALMEDIA,
  prev_QUOTES,
  prev_QUESTION,
}

export const TOOL_TYPE_ICONS = {
  [NONE]: "/icons/tools/folder-primary.svg",
  [HEADLINES]: "/icons/tools/headline-primary.svg",
  [MESSAGING]: "/icons/tools/messaging-primary.svg",
  [BRAINSTORM]: "/icons/tools/brainstorm-primary.svg",
  [SOCIALMEDIA]: "/icons/tools/socialmedia-primary.svg",
  [ADCOPY]: "/icons/tools/adcopy-primary.svg",
  [FIRSTDRAFT]: "/icons/tools/firstdraft-primary.svg",
  [MEDIAPITCH]: "/icons/tools/mediapitch-primary.svg",
  [QUESTION]: "/icons/tools/question-primary.svg",
  [QUOTES]: "/icons/tools/quotes-primary.svg",
  [SUMMARY]: "/icons/tools/summary-primary.svg",
  [CORRESPONDENCE]: "/icons/tools/correspondence-primary.svg",
  [ENTERTAINMENT]: "/icons/tools/entertainment-primary.svg",
  // fallback
  [prev_HEADLINE]: "/icons/tools/headline-primary.svg",
  [prev_MESSAGING]: "/icons/tools/messaging-primary.svg",
  [prev_BRAINSTORM]: "/icons/tools/brainstorm-primary.svg",
  [prev_ADCOPY]: "/icons/tools/adcopy-primary.svg",
  [prev_MEDIAPITCH]: "/icons/tools/mediapitch-primary.svg",
  [prev_FIRSTDRAFT]: "/icons/tools/firstdraft-primary.svg",
  [prev_SOCIALMEDIA]: "/icons/tools/socialmedia-primary.svg",
  [prev_QUOTES]: "/icons/tools/quotes-primary.svg",
  [prev_QUESTION]: "/icons/tools/question-primary.svg",
}

export const TOOL_TYPE_ICONS_DYNAMIC = {
  [NONE]: markRaw(FolderIcon),
  [HEADLINES]: markRaw(HeadlineIcon),
  [MESSAGING]: markRaw(MessagingIcon),
  [BRAINSTORM]: markRaw(BrainstormIcon),
  [SOCIALMEDIA]: markRaw(SocialMediaIcon),
  [ADCOPY]: markRaw(AdCopyIcon),
  [FIRSTDRAFT]: markRaw(ArticlesIcon),
  [MEDIAPITCH]: markRaw(MediapitchIcon),
  [QUESTION]: markRaw(QuestionIcon),
  [QUOTES]: markRaw(QuotesIcon),
  [SUMMARY]: markRaw(SummaryIcon),
  [CORRESPONDENCE]: markRaw(CorrespondenceIcon),
  [ENTERTAINMENT]: markRaw(EntertainmentIcon),
  // fallback
  [prev_HEADLINE]: markRaw(HeadlineIcon),
  [prev_MESSAGING]: markRaw(MessagingIcon),
  [prev_BRAINSTORM]: markRaw(BrainstormIcon),
  [prev_SOCIALMEDIA]: markRaw(SocialMediaIcon),
  [prev_ADCOPY]: markRaw(AdCopyIcon),
  [prev_FIRSTDRAFT]: markRaw(ArticlesIcon),
  [prev_MEDIAPITCH]: markRaw(MediapitchIcon),
  [prev_QUESTION]: markRaw(QuestionIcon),
  [prev_QUOTES]: markRaw(QuotesIcon),
}

export const TOOL_TYPE_URLS = {
  [HEADLINES]: routerNames.HEADLINE,
  [MESSAGING]: routerNames.MESSAGING,
  [BRAINSTORM]: routerNames.BRAINSTORM,
  [SOCIALMEDIA]: routerNames.SOCIALMEDIA,
  [ADCOPY]: routerNames.ADCOPY,
  [FIRSTDRAFT]: routerNames.FIRSTDRAFT,
  [MEDIAPITCH]: routerNames.MEDIA_PITCH,
  [QUESTION]: routerNames.QUESTION,
  [QUOTES]: routerNames.QUOTE,
  [SUMMARY]: routerNames.SUMMARY,
  [CORRESPONDENCE]: routerNames.CORRESPONDENCE,
  [ENTERTAINMENT]: routerNames.ENTERTAINMENT,
  // fallback
  [prev_HEADLINE]: routerNames.HEADLINE,
  [prev_MESSAGING]: routerNames.MESSAGING,
  [prev_BRAINSTORM]: routerNames.BRAINSTORM,
  [prev_MEDIAPITCH]: routerNames.MEDIA_PITCH,
  [prev_FIRSTDRAFT]: routerNames.FIRSTDRAFT,
  [prev_ADCOPY]: routerNames.ADCOPY,
  [prev_SOCIALMEDIA]: routerNames.SOCIAL,
  [prev_QUOTES]: routerNames.QUOTE,
  [prev_QUESTION]: routerNames.QUESTION,
}

export const TOOL_API_ROUTES = {
  [HEADLINES]: "/doc/generate-headline",
  [MESSAGING]: "/doc/generate-message",
  [SOCIALMEDIA]: "/doc/generate-social-media",
  [ADCOPY]: "/doc/generate-promote",
  [MEDIAPITCH]: "/doc/generate-media-pitch",
  [QUESTION]: "/doc/generate-question",
  [QUOTES]: "/doc/generate-quote",
  [SUMMARY]: "/doc/generate-summary",
  [CORRESPONDENCE]: "/doc/generate-correspondence",
  [ENTERTAINMENT]: "/doc/generate-entertainment",
  [FIRSTDRAFT]: "/doc/generate-article",
  [BRAINSTORM]: "/doc/generate-answer",
  // fallback
  [prev_BRAINSTORM]: "/doc/generate-answer",
  [prev_FIRSTDRAFT]: "/doc/generate-article",
  [prev_HEADLINE]: "/doc/generate-headline",
  [prev_MESSAGING]: "/doc/generate-message",
  [prev_MEDIAPITCH]: "/doc/generate-media-pitch",
  [prev_ADCOPY]: "/doc/generate-promote",
  [prev_SOCIALMEDIA]: "/doc/generate-social-media",
  [prev_QUOTES]: "/doc/generate-quote",
  [prev_QUESTION]: "/doc/generate-question",
}

export const TOOL_API_ROUTES_V3 = {
  [HEADLINES]: "/doc-v3/generate-headline",
  [MESSAGING]: "/doc-v3/generate-message",
  [SOCIALMEDIA]: "/doc-v3/generate-social-media",
  [ADCOPY]: "/doc-v3/generate-promote",
  [MEDIAPITCH]: "/doc-v3/generate-media-pitch",
  [QUESTION]: "/doc-v3/generate-question",
  [QUOTES]: "/doc-v3/generate-quote",
  [SUMMARY]: "/doc-v3/generate-summary",
  [CORRESPONDENCE]: "/doc-v3/generate-correspondence",
  [ENTERTAINMENT]: "/doc-v3/generate-entertainment",
  [FIRSTDRAFT]: "/doc-v3/generate-article",
  [BRAINSTORM]: "/doc-v3/generate-answer",
  // fallback
  [prev_BRAINSTORM]: "/doc-v3/generate-answer",
  [prev_FIRSTDRAFT]: "/doc-v3/generate-article",
  [prev_HEADLINE]: "/doc-v3/generate-headline",
  [prev_MESSAGING]: "/doc-v3/generate-message",
  [prev_MEDIAPITCH]: "/doc-v3/generate-media-pitch",
  [prev_ADCOPY]: "/doc-v3/generate-promote",
  [prev_SOCIALMEDIA]: "/doc-v3/generate-social-media",
  [prev_QUOTES]: "/doc-v3/generate-quote",
  [prev_QUESTION]: "/doc-v3/generate-question",
}

export const TOOL_API_ROUTES_V4 = {
  [HEADLINES]: "/doc-v4/generate-headline",
  [MESSAGING]: "/doc-v4/generate-message",
  [SOCIALMEDIA]: "/doc-v4/generate-social-media",
  [ADCOPY]: "/doc-v4/generate-promote",
  [MEDIAPITCH]: "/doc-v4/generate-media-pitch",
  [QUESTION]: "/doc-v4/generate-question",
  [QUOTES]: "/doc-v4/generate-quote",
  [SUMMARY]: "/doc-v4/generate-summary",
  [CORRESPONDENCE]: "/doc-v4/generate-correspondence",
  [ENTERTAINMENT]: "/doc-v4/generate-entertainment",
  [FIRSTDRAFT]: "/doc-v4/generate-article",
  [BRAINSTORM]: "/doc-v4/generate-answer",
  // fallback
  [prev_BRAINSTORM]: "/doc-v4/generate-answer",
  [prev_FIRSTDRAFT]: "/doc-v4/generate-article",
  [prev_HEADLINE]: "/doc-v4/generate-headline",
  [prev_MESSAGING]: "/doc-v4/generate-message",
  [prev_MEDIAPITCH]: "/doc-v4/generate-media-pitch",
  [prev_ADCOPY]: "/doc-v4/generate-promote",
  [prev_SOCIALMEDIA]: "/doc-v4/generate-social-media",
  [prev_QUOTES]: "/doc-v4/generate-quote",
  [prev_QUESTION]: "/doc-v4/generate-question",
}

export const QUICK_ACTIONS_API_URL_V3 = {
  "expand": "/doc-v3/quick-actions",
  "rewrite": "/doc-v3/quick-actions",
  "shorten": "/doc-v3/quick-actions",
  "free-writing": "/doc-v3/quick-actions",
}

export const QUICK_ACTIONS_API_URL_V4 = {
  "expand": "/doc-v4/quick-actions",
  "rewrite": "/doc-v4/quick-actions",
  "shorten": "/doc-v4/quick-actions",
  "free-writing": "/doc-v4/quick-actions",
}

export const TOOL_TYPE_PLACEHOLDERS = {
  [HEADLINES]: "Get more attention",
  [MESSAGING]: "Communicate clearly",
  [BRAINSTORM]: "Discover your big idea",
  [SOCIALMEDIA]: "Be a stand-out",
  [ADCOPY]: "Words that sell",
  [FIRSTDRAFT]: "Write drunk. Edit sober",
  [MEDIAPITCH]: "Tell your story",
  [QUESTION]: "Interview & Research",
  [QUOTES]: "Say what matters",
  [SUMMARY]: "Craft it perfectly",
  [CORRESPONDENCE]: "Connect with clarity",
  [ENTERTAINMENT]: "Make something fun",
  // fallback
  [prev_HEADLINE]: "Get more attention",
  [prev_MESSAGING]: "Communicate clearly",
  [prev_BRAINSTORM]: "Discover your big idea",
  [prev_MEDIAPITCH]: "Tell your story",
  [prev_FIRSTDRAFT]: "Write drunk. Edit sober",
  [prev_ADCOPY]: "Words that sell",
  [prev_SOCIALMEDIA]: "Be a stand-out",
  [prev_QUOTES]: "Say what matters",
  [prev_QUESTION]: "Interview & Research",
}

export const TOOL_TYPE_TUTORIALS = {
  [HEADLINES]: "https://demo.arcade.software/hygz725vOGOWN219Taor?embed",
  [MESSAGING]: "https://demo.arcade.software/hygz725vOGOWN219Taor?embed",
  [BRAINSTORM]: "https://demo.arcade.software/hygz725vOGOWN219Taor?embed",
  [SOCIALMEDIA]: "https://demo.arcade.software/hygz725vOGOWN219Taor?embed",
  [ADCOPY]: "https://demo.arcade.software/hygz725vOGOWN219Taor?embed",
  [FIRSTDRAFT]: "https://demo.arcade.software/hygz725vOGOWN219Taor?embed",
  [QUESTION]: "https://demo.arcade.software/hygz725vOGOWN219Taor?embed",
  [QUOTES]: "https://demo.arcade.software/hygz725vOGOWN219Taor?embed",
  [SUMMARY]: "https://demo.arcade.software/hygz725vOGOWN219Taor?embed",
  [CORRESPONDENCE]: "https://demo.arcade.software/hygz725vOGOWN219Taor?embed",
  [ENTERTAINMENT]: "https://demo.arcade.software/hygz725vOGOWN219Taor?embed",
  [prev_HEADLINE]: "https://demo.arcade.software/hygz725vOGOWN219Taor?embed",
  [prev_MESSAGING]: "https://demo.arcade.software/hygz725vOGOWN219Taor?embed",
  [prev_BRAINSTORM]: "https://demo.arcade.software/hygz725vOGOWN219Taor?embed",
  [prev_FIRSTDRAFT]: "https://demo.arcade.software/hygz725vOGOWN219Taor?embed",
  [prev_ADCOPY]: "https://demo.arcade.software/hygz725vOGOWN219Taor?embed",
  [prev_SOCIALMEDIA]: "https://demo.arcade.software/hygz725vOGOWN219Taor?embed",
  [prev_QUOTES]: "https://demo.arcade.software/hygz725vOGOWN219Taor?embed",
  [prev_QUESTION]: "https://demo.arcade.software/hygz725vOGOWN219Taor?embed",

  [prev_MEDIAPITCH]: "https://www.youtube.com/embed/_6opGCFM574?rel=0",
  [MEDIAPITCH]: "https://www.youtube.com/embed/_6opGCFM574?rel=0",

  "agent-mode": "https://demo.arcade.software/vXtLjebzL0apzOhaVNT5?embed",
}

export const old_TOOL_TYPE_TUTORIALS = {
  // here to replace url for different tools
  // [NONE]: "",
  [HEADLINES]: "https://www.youtube.com/embed/LD2OFdIRTQ8?rel=0",
  [MESSAGING]: "https://youtube.com/embed/MHAbUTPX4Bk?rel=0",
  [BRAINSTORM]: "https://youtube.com/embed/m2-lZFKgBs0?rel=0",
  [SOCIALMEDIA]: "https://youtube.com/embed/csNa-8T9aUE?rel=0",
  [ADCOPY]: "https://www.youtube.com/embed/RgZtBRINNRY?rel=0",
  [FIRSTDRAFT]: "https://www.youtube.com/embed/M4Ypg2EsxEk?rel=0",
  [MEDIAPITCH]: "https://www.youtube.com/embed/_6opGCFM574?rel=0",
  [QUESTION]: "https://www.youtube.com/embed/7nEcH7mqQeI?rel=0",
  [QUOTES]: "https://youtube.com/embed/ZyddW5tR66k?rel=0",
  [SUMMARY]: "https://www.youtube.com/embed/3ASBOSWXhTQ?rel=0",
  [CORRESPONDENCE]: "https://www.youtube.com/embed/b_UDhO9-m1Y?rel=0",
  [ENTERTAINMENT]: "https://www.youtube.com/embed/4XRO488o0WI?rel=0",
  // [WELCOME]: "https://youtube.com/embed/gnzr-HmKqRI?rel=0",
  [prev_HEADLINE]: "https://www.youtube.com/embed/LD2OFdIRTQ8?rel=0",
  [prev_MESSAGING]: "https://youtube.com/embed/MHAbUTPX4Bk?rel=0",
  [prev_BRAINSTORM]: "https://youtube.com/embed/m2-lZFKgBs0?rel=0",
  [prev_MEDIAPITCH]: "https://www.youtube.com/embed/_6opGCFM574?rel=0",
  [prev_FIRSTDRAFT]: "https://www.youtube.com/embed/M4Ypg2EsxEk?rel=0",
  [prev_ADCOPY]: "https://www.youtube.com/embed/RgZtBRINNRY?rel=0",
  [prev_SOCIALMEDIA]: "https://youtube.com/embed/csNa-8T9aUE?rel=0",
  [prev_QUOTES]: "https://youtube.com/embed/ZyddW5tR66k?rel=0",
  [prev_QUESTION]: "https://www.youtube.com/embed/7nEcH7mqQeI?rel=0",
  "agent-mode": "https://demo.arcade.software/vXtLjebzL0apzOhaVNT5?embed",
}
