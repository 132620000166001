import { createRouter, createWebHistory } from "vue-router"
import RedirectLoginView from "@/modules/auth/views/RedirectLoginView.vue"
import routerNames from "@/configs/routerNames"
import { authGuard } from "@auth0/auth0-vue"
import authRoutes from "./auth.routes"
import projectRoutes from "./projects.routes"
import onboardingRoutes from "./onboarding.routes"
import settingsRoutes from "./settings.routes"
import writingRoutes from "./writing.routes"
import notesRoutes from "./notes.routes"
import shareRoutes from "./share.routes"
import activitiesRoutes from "./activities.routes"
import printingRoutes from "./printing.routes"

export default createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  linkExactActiveClass: "active-route",
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: "/",
      component: RedirectLoginView,
    },
    {
      path: "/finish-login",
      name: routerNames.FINISH_LOGIN,
      beforeEnter: authGuard,
      component: () => import("@/modules/auth/views/FinishLogin.vue"),
    },
    authRoutes,
    projectRoutes,
    onboardingRoutes,
    settingsRoutes,
    writingRoutes,
    notesRoutes,
    shareRoutes,
    activitiesRoutes,
    printingRoutes,
  ],
})