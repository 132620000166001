export * from "./http"
export * from "./onOutsideClick"
export * from "./stringUtils"
export * from "./sorting"
export * from "./debounce"

export const parseError = (err) => {
  if (err.response) {
    if (err.response.data) {
      if (err.response.data.message) return err.response.data.message
    }
  }
  return err.message
}
/**
 * @returns {Record<string, string>}
 */
export const getBrowserCookies = () => {
  const cookiesList = document.cookie.split("; ")
  const cookies = {}
  for (let i = 0; i < cookiesList.length; i++) {
    const [key, value] = cookiesList[i].split("=")
    cookies[key] = value
  }

  return cookies
}
