import { PROJECTS_PER_PAGE, TOOL_FILES_PER_PAGE } from "@/configs/constants"
import routerNames from "@/configs/routerNames"
import uiEvents from "@/configs/uiEvents"
import { eventBus } from "@/plugins/eventBus.plugin"
import router from "@/router"
import { filesService, projectsService } from "@/services"
import { parseError } from "@/utils"
import { TOOL_TYPE } from "@/constants/ToolType"

/** @type {import("vuex").ActionTree} */
export default {
  async selectProject({ state, commit }, [projectId, folderId]) {
    commit("setSelectedFolder", null)
    commit("setFiles", [])

    let project = state.projectsRepo.find((p) => p._id === projectId)
    if (!project) {
      commit("setSelectedProject", null)
      project = await projectsService.getAProjectById(projectId)
    }
    if (!project) {
      eventBus.emit(uiEvents.GLOBAL.SHOW_ALERT_MODAL, {
        severity: "error",
        title: "Your project not found!",
        body: "The project may be deleted or your project id is incorrect.",
        callback: () => router.replace({ name: routerNames.EXPLORER_PROJECTS }),
      })
      return
    }

    commit("setSelectedProject", project)
    commit("setGettingFiles", true)

    let folder = null
    try {
      if (folderId) {
        folder = await filesService.getAFile(folderId)
      }
    } catch (err) {
      eventBus.emit(uiEvents.GLOBAL.SHOW_ALERT_MODAL, {
        severity: "error",
        title: "Your folder not found!",
        body: "The folder may be deleted or your folder id is incorrect.",
        callback: () =>
          router.replace({
            name: routerNames.PROJECT_OVERVIEW,
            params: { project_id: projectId },
          }),
      })
      return
    }

    commit("setSelectedFolder", folder)

    try {
      /** @type {ProjectFile[]} */
      const files = await filesService.getProjectFiles(projectId, {
        folder: folder ? folder._id : "",
      })
      commit("setFiles", files)
    } catch (err) {
      window.devErr(err)
      eventBus.emit(uiEvents.GLOBAL.SHOW_ALERT_MODAL, {
        severity: "error",
        title: "Unable full fill your request",
        body: parseError(err),
        callback: () => router.replace({ name: routerNames.EXPLORER_PROJECTS }),
      })
    } finally {
      commit("setGettingFiles", false)
    }
  },

  updateProjectsFilter({ commit, dispatch }, filter) {
    commit("setProjectsFilter", filter)
    dispatch("updateAllProjectsList")
  },

  updateAllProjectsList({ state, commit }) {
    const projectsRepo = state.projectsRepo
    const filter = state.projectsFilter
    const allProjects = projectsRepo.filter((project) => {
      if (filter !== "Archived" && project.status === "Archived") return false
      if (filter === "All Projects") return true
      return filter === project.status
    })
    commit("setProjects", allProjects)
  },

  updateProject({ state, commit, dispatch }, project) {
    const filteredProjects = state.projectsRepo.filter(
      (p) => p._id !== project._id,
    )
    filteredProjects.push(project)
    commit("setProjectsRepo", filteredProjects)

    if (state.selectedProject && state.selectedProject._id === project._id) {
      commit("setSelectedProject", project)
    }

    dispatch("updateAllProjectsList")
  },

  deleteProject({ state, commit, dispatch }, id) {
    const filteredProjects = state.projectsRepo.filter((p) => p._id !== id)
    commit("setProjectsRepo", filteredProjects)

    if (state.selectedProject && state.selectedProject._id === id) {
      commit("setSelectedProject", null)
      router.push({ name: routerNames.EXPLORER_PROJECTS })
    }

    dispatch("updateAllProjectsList")
  },

  removeFile({ state, commit }, id) {
    const newFilesList = state.files.filter((file) => file._id !== id)
    commit("setFiles", newFilesList)
  },

  changeProjectsCurrentPage({ state, commit }, { next, largeSteps }) {
    let nextPage = JSON.parse(JSON.stringify(state.currentPage))
    const maxPages = Math.ceil(state.allProjects.length / PROJECTS_PER_PAGE)
    if (largeSteps) {
      if (next) {
        nextPage = nextPage + 10 > maxPages ? maxPages : nextPage + 10
      } else {
        nextPage = nextPage - 10 < 1 ? 1 : nextPage - 10
      }
    } else {
      if (next) {
        nextPage = nextPage + 1 > maxPages ? maxPages : nextPage + 1
      } else {
        nextPage = nextPage - 1 < 1 ? 1 : nextPage - 1
      }
    }
    commit("setProjectsCurrentPage", nextPage)
  },

  changeToolFilesCurrentPage({ state, commit }, { next, largeSteps }) {
    let nextPage = JSON.parse(JSON.stringify(state.toolFilesCurrentPage))
    const maxPages = Math.ceil(state.allFiles.length / TOOL_FILES_PER_PAGE)
    if (largeSteps) {
      if (next) {
        nextPage = nextPage + 10 > maxPages ? maxPages : nextPage + 10
      } else {
        nextPage = nextPage - 10 < 1 ? 1 : nextPage - 10
      }
    } else {
      if (next) {
        nextPage = nextPage + 1 > maxPages ? maxPages : nextPage + 1
      } else {
        nextPage = nextPage - 1 < 1 ? 1 : nextPage - 1
      }
    }
    commit("setToolFilesCurrentPage", nextPage)
  },

  async quickEditAgentChat({ state, commit }, payload) {
    const file = JSON.parse(JSON.stringify(state.selectedFile))
    file.base_data.article.text = payload.chatContent
    file.base_data.article.replace_text = payload.chatContent

    if (payload.middleware) {
      await payload.middleware()
    }

    commit("setSelectedFile", file)
    await router.replace({
      name: routerNames.FIRSTDRAFT,
      query: {
        ...router.query,
        tool: "",
      },
      params: {
        ...router.params,
        category: TOOL_TYPE.FIRSTDRAFT,
      },
    })
  },
}
