<script setup></script>

<template>
  <span class="flex h-inherit">
    <svg
      class="w-auto h-auto stroke-inherit"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22Z"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 16C10.9391 16 9.92172 15.5786 9.17157 14.8284M12 16C13.0609 16 14.0783 15.5786 14.8284 14.8284M12 16V22M9.17157 14.8284C8.42143 14.0783 8 13.0609 8 12M9.17157 14.8284L4.929 19.071M8 12C8 10.9391 8.42143 9.92172 9.17157 9.17157C9.92172 8.42143 10.9391 8 12 8M8 12H2M12 8C13.0609 8 14.0783 8.42143 14.8284 9.17157M12 8V2M14.8284 9.17157C15.5786 9.92172 16 10.9391 16 12M14.8284 9.17157L19.071 4.929M16 12C16 13.0609 15.5786 14.0783 14.8284 14.8284M16 12H22M14.8284 14.8284L19.071 19.071M4.929 4.929L9.172 9.17"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </span>
</template>
