<script setup></script>

<template>
  <span class="flex h-inherit"
    ><svg
      class="w-auto h-auto stroke-inherit"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 6H17M7 9H17M9 17H15"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3 12H2.6C2.44087 12 2.28826 12.0632 2.17574 12.1757C2.06321 12.2883 2 12.4409 2 12.6V21.4C2 21.4788 2.01552 21.5568 2.04567 21.6296C2.07583 21.7024 2.12002 21.7685 2.17574 21.8243C2.23145 21.88 2.29759 21.9242 2.37039 21.9543C2.44319 21.9845 2.52121 22 2.6 22H21.4C21.4788 22 21.5568 21.9845 21.6296 21.9543C21.7024 21.9242 21.7685 21.88 21.8243 21.8243C21.88 21.7685 21.9242 21.7024 21.9543 21.6296C21.9845 21.5568 22 21.4788 22 21.4V12.6C22 12.4409 21.9368 12.2883 21.8243 12.1757C21.7117 12.0632 21.5591 12 21.4 12H21M3 12V2.6C3 2.44087 3.06321 2.28826 3.17574 2.17574C3.28826 2.06321 3.44087 2 3.6 2H20.4C20.5591 2 20.7117 2.06321 20.8243 2.17574C20.9368 2.28826 21 2.44087 21 2.6V12M3 12H21"
      />
    </svg>
  </span>
</template>
