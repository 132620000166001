import { createAuth0 } from "@auth0/auth0-vue"
import { clientId, domain, audience, customDomain } from "../../authConfig.json"

const auth0 = createAuth0({
  clientId,
  domain: customDomain || domain,
  authorizationParams: {
    audience,
    redirect_uri: window.location.origin + "/writing",
  },
})

export default auth0
