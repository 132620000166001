<script setup>
import { computed, onBeforeMount, inject, defineAsyncComponent } from "vue"
import { useStore } from "vuex"
import { useAuth0 } from "@auth0/auth0-vue"
import { ACTIONS, GETTERS } from "@/store/constants"

const AppNav = defineAsyncComponent(() =>
  import("@/modules/navBar/components/AppNav.vue"),
)
const AppSidebar = defineAsyncComponent(() =>
  import("@/modules/sideBar/components/AppSidebar.vue"),
)
const ContentHub = defineAsyncComponent(() =>
  import("@/modules/contentHub/components/ContentHub.vue"),
)
const ModifyNoteModal = defineAsyncComponent(() =>
  import("@/modules/notes/components/ModifyNoteModal.vue"),
)
const CreateProjectModal = defineAsyncComponent(() =>
  import("@/modules/projects/components/CreateProjectModal.vue"),
)
const ChooseProjectModal = defineAsyncComponent(() =>
  import("@/modules/projects/components/ChooseProjectModal.vue"),
)
const ShareToolContentsModal = defineAsyncComponent(() =>
  import("@/modules/share/components/ShareToolContentsModal.vue"),
)
const TutorialModal = defineAsyncComponent(() =>
  import("@/components/TutorialModal.vue"),
)
const FirstTimerModal = defineAsyncComponent(() =>
  import("../modules/onboarding/components/FirstTimerModal.vue"),
)
const TokenRanOutModal = defineAsyncComponent(() =>
  import("../modules/settings/components/TokenRanOutModal.vue"),
)

const auth0 = useAuth0()
const store = useStore()
const browserCache = inject("browserCache")
// const socket = inject("socket")
const sidebarExpanded = computed(() => store.getters[GETTERS.SIDEBAR_EXPANDED])
const fetchingData = computed(() => store.getters[GETTERS.FETCHING_DATA])
const isPageReady = computed(() => {
  if (auth0.isLoading.value) return false
  if (fetchingData.value) return false
  return true
})

onBeforeMount(() => {
  const resetNeeded = browserCache.checkResetNeeded()
  if (resetNeeded) {
    browserCache.clearCache()
  }
  store.dispatch(ACTIONS.SETUP_ALL_TOOLS)
})
</script>

<template>
  <AppSidebar />
  <AppNav />

  <ModifyNoteModal v-if="isPageReady" />
  <CreateProjectModal v-if="isPageReady" />
  <ChooseProjectModal v-if="isPageReady" />
  <TutorialModal v-if="isPageReady" />
  <ShareToolContentsModal v-if="isPageReady" />
  <ContentHub v-if="isPageReady" />
  <FirstTimerModal v-if="isPageReady" />
  <TokenRanOutModal v-if="isPageReady" />

  <div
    v-if="!isPageReady"
    class="flex flex-col justify-center items-center bg-bgContainer-light dark:bg-bgContainer-dark rounded-xl p-8 w-[90vw] max-w-[400px] gap-4 fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-[103]"
  >
    <BrandLogo3d class="h-[50px] mb-6" />
    <h5 class="text-bodyXXL text-textTitle-light dark:text-textTitle-dark">
      Authenticating...
    </h5>
    <BaseSpinner />
  </div>
  <div
    v-if="!isPageReady"
    class="fixed top-0 left-0 right-0 bottom-0 bg-bgNav-light dark:bg-bgNav-dark z-[102]"
  />

  <main
    class="w-full min-h-screen overflow-hidden p-2 bg-bgNav-light dark:bg-bgNav-dark"
    :class="{
      'md:pl-[258px]': sidebarExpanded,
      'md:pl-[98px]': !sidebarExpanded,
    }"
  >
    <RouterView v-if="isPageReady" />
  </main>
</template>