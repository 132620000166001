<script setup>
import { onBeforeMount } from "vue"
import { useStore } from "vuex"
import { useRoute, useRouter } from "vue-router"
import MagicWandIcon from "@/components/Icons/MagicWandIcon.vue"
import KeyFramesCoupleIcon from "@/components/Icons/KeyFramesCoupleIcon.vue"
import routerNames from "@/configs/routerNames"
import { COMMITS } from "@/store/constants"

const route = useRoute()
const router = useRouter()
const store = useStore()
const filterOptions = [
  {
    name: "Tools",
    filter: routerNames.ALL_TOOLS,
    icon: MagicWandIcon,
  },
  {
    name: "Templates",
    filter: routerNames.ALL_TEMPLATES,
    icon: KeyFramesCoupleIcon,
  },
]

const handleSelectFilter = (name) => {
  router.push({ name })
}

onBeforeMount(() => {
  store.commit(COMMITS.SET_SELECTED_PROJECT, null)
  store.commit(COMMITS.SET_SELECTED_FOLDER, null)
  store.commit(COMMITS.SET_SELECTED_FILE, null)
})
</script>

<template>
  <div class="page-wrapper flex flex-col justify-start items-start">
    <BaseExplorerFilterBar
      :options="filterOptions"
      :activeFilter="route.name"
      @selectFilter="handleSelectFilter"
    />

    <RouterView />
  </div>
</template>
