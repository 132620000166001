export const ACTIONS = {
  // general
  TOGGLE_SIDEBAR_EXPANDED: "toggleSidebarExpanded",
  GET_SUBSCRIPTION_INFO: "getSubscriptionInfo",
  TOGGLE_GENERATING: "toggleGenerating",
  SELECT_TAG: "selectATag",
  REMOVE_SELECT_TAG: "removeSelectedTag",
  SHOW_TUTORIAL: "showTutorial",
  HIDE_TUTORIAL: "hideTutorial",
  ADD_ALERTS: "addAlerts",
  PIN_TOOL: "pinTool",
  SETUP_ALL_TOOLS: "setupAllTools",
  UPDATE_THEME_PREFERENCE: "updateThemePreference",
  TOKEN_INFO: "fetchTokenInfo",
  AUTH_ERR: "getAuthErr",

  // project and files
  SELECT_PROJECT: "projects/selectProject",
  REMOVE_FILE: "projects/removeFile",
  UPDATE_PROJECT: "projects/updateProject",
  DELETE_PROJECT: "projects/deleteProject",
  CREATE_PROJECT: "projects/createProject",
  CHANGE_PROJECTS_CURRENT_PAGE: "projects/changeProjectsCurrentPage",
  UPDATE_ALL_PROJECTS_LIST: "projects/updateAllProjectsList",
  UPDATE_PROJECTS_FILTER: "projects/updateProjectsFilter",
  CHANGE_TOOL_FILES_CURRENT_PAGE: "projects/changeToolFilesCurrentPage",
  QUICK_EDIT_AGENT_CHATS: "projects/quickEditAgentChat",

  // notes
  UPDATE_NOTES: "notes/updateNotesList",
  UPDATE_NOTES_REPO: "notes/updateNotesRepo",
  TOGGLE_CURRENT_PROJECT_NOTE: "notes/toggleNoteFromCurrentProject",
  CHANGE_NOTES_PAGE: "notes/changeNotesPage",

  // searching
  START_SEARCHING: "research/startSearching",
  SAVE_RESULT_TO_NOTE: "research/saveResultToNote",

  // onboarding page
  ONBOARDING_NEXT_STEP: "onboarding/gotoNextStep",
  ONBOARDING_SKIP_STEP: "onboarding/gotoPreviousStep",

  // agent mode
  AGENT_FETCH_CHAT_HISTORY: "agent/fetchChatHistory",
  CHANGE_CHAT_HISTORY_PAGE: "agent/changeChatHistoryPage",
  AGENT_GET_A_THREAD: "agent/getAThread",
}

export const COMMITS = {
  // general
  SET_SIDEBAR_EXPANDED: "setSidebarExpanded",
  SET_USER: "setUser",
  SET_ACCOUNT: "setAccount",
  SET_FETCHING_DATA: "setFetchingData",
  SET_TEMPLATES: "setTemplates",
  UPDATE_TEMPLATES_LIST: "updateTemplatesList",
  SET_CARDS: "setUserCards",
  SET_TEAM: "setUserTeam",
  SET_SUBSCRIPTION: "setSubscription",
  SET_APPSUMO_SUBSCRIPTION: "setAppSumoSubscription",
  SET_GENERATING: "setGenerating",
  SET_SELECTED_TAGS: "setSelectedTags",
  SET_TAGS: "setAllTags",
  SET_SHOW_TUTORIAL_MODAL: "setShowTutorialModal",
  SET_TUTORIAL_LINK: "setTutorialLink",
  SET_ALERTS: "setAlerts",
  SET_ADMIN_ACCOUNT: "setAdminAccount",
  SET_ALL_TOOLS: "setAllTools",
  SET_DARK_MODE: "setDarkMode",
  SET_TOKEN_INFO: "setTokenInfo",
  SET_AUTH_ERR: "setAuthErr",

  // project and files
  SET_PROJECTS: "projects/setProjects",
  SET_FILES: "projects/setFiles",
  SET_SELECTED_PROJECT: "projects/setSelectedProject",
  SET_SELECTED_FOLDER: "projects/setSelectedFolder",
  SET_SELECTED_FILE: "projects/setSelectedFile",
  SET_GETTING_FILES: "projects/setGettingFiles",
  SET_FILES_SORTING: "projects/setFilesSorting",
  SET_PROJECTS_SORTING: "projects/setProjectsSorting",
  SET_PROJECTS_FILTER: "projects/setProjectsFilter",
  SET_PROJECTS_CURRENT_PAGE: "projects/setProjectsCurrentPage",
  SET_TOOL_FILES_CURRENT_PAGE: "projects/setToolFilesCurrentPage",
  SET_PROJECTS_REPO: "projects/setProjectsRepo",

  // notes
  SET_NOTES: "notes/setAllNotes",
  SET_NOTES_REPO: "notes/setNotesRepo",
  SET_GETTING_NOTES: "notes/setGettingNotes",
  SET_NOTE_SEARCH_INDEX: "notes/setSearchIndex",
  SET_SHOW_NOTE_EDITOR: "notes/setShowNoteEditor",
  SET_SELECTED_NOTE: "notes/setSelectedNote",
  SET_NOTES_FROM_CURRENT_PROJECT: "notes/setNotesFromCurrentProject",
  SET_NOTES_FILTER_TYPE: "notes/setNotesFilterType",
  SET_NOTES_CURRENT_PAGE: "notes/setCurrentPage",

  // research hub
  SET_RESEARCH_QUERY: "research/setQuery",
  SET_NEWS_RESULTS: "research/setNewsResults",
  SET_SCHOLAR_RESULTS: "research/setScholarResults",
  SET_FACT_RESULTS: "research/setFactResults",
  SET_SEARCHING: "research/setSearching",

  SET_ONBOARDING_USER_TYPE: "onboarding/setUserType",
  SET_ONBOARDING_FULLNAME: "onboarding/setFullname",
  SET_ONBOARDING_INDUSTRY: "onboarding/setIndustry",
  SET_ONBOARDING_USAGE_TYPE: "onboarding/setUsageType",
  SET_ONBOARDING_SOURCE_TYPE: "onboarding/setSourceType",
  SET_ONBOARDING_STEP: "onboarding/setOnboardingStep",
  SET_ONBOARDING_ERROR: "onboarding/setOnboardingError",

  // agent mode
  SET_AGENT_CHAT_HISTORY: "agent/setChatHistory",
  SET_AGENT_SESSION_ID: "agent/setSessionId",
  SET_AGENT_USER_REPLY: "agent/setUserReply",
  SET_AGENT_IS_USER_REPLY: "agent/setIsUserReply",
  SET_AGENT_SHOW_STOP_BTN: "agent/setShowStopBtn",
  SET_AGENT_OBJECTIVE: "agent/setObjective",
  SET_AGENT_TASKLIST: "agent/setTaskList",
  SET_CHAT_HISTORY_CURRENT_PAGE: "agent/setChatHistoryCurrentPage",
  UPDATE_THREAD: "agent/updateThread",
  SET_SHOW_QUICK_STARTS: "agent/setShowQuickStarts",

  // new commits
  SET_USER_EMAIL_VERIFIED: "setUserEmailVerified",
}

export const GETTERS = {
  // general
  USER: "getUser",
  ACCOUNT: "getAccount",
  ADMIN_ACCOUNT: "getAdminAccount",
  SUBSCRIPTION: "getSubscriptionInfo",
  TEAM: "getUserTeam",
  CARDS: "getUserCards",
  GENERATING: "getGenerating",
  SELECTED_TAGS: "getSelectedTags",
  ALL_TEMPLATES: "getAllTemplates",
  ALL_TAGS: "getAllTags",
  SIDEBAR_EXPANDED: "getSidebarExpanded",
  FETCHING_DATA: "getFetchingData",
  SHOW_TUTORIAL_MODAL: "getShowTutorialModal",
  TUTORIAL_LINK: "getTutorialLink",
  ALERTS: "getAlerts",
  GLOBAL_SEARCH_ITEMS: "getGlobalSearchItems",
  ALL_TOOLS: "getAllTools",
  DARK_MODE: "getDarkMode",
  TOKEN_INFO: "getTokenInfo",

  // project and files
  ALL_FILES: "projects/getAllFiles",
  ALL_PROJECTS: "projects/getAllProjects",
  SELECTED_FOLDER: "projects/getSelectedFolder",
  SELECTED_FILE: "projects/getSelectedFile",
  SELECTED_PROJECT: "projects/getSelectedProject",
  GETTING_FILES: "projects/getGettingFiles",
  FILES_SORTING: "projects/getFilesSorting",
  PROJECTS_SORTING: "projects/getProjectsSorting",
  PROJECTS_FILTER: "projects/getProjectsFilter",
  PROJECTS_CURRENT_PAGE: "projects/getProjectsCurrentPage",
  TOOL_FILES_CURRENT_PAGE: "projects/getToolFilesCurrentPage",
  PROJECTS_REPO: "projects/getProjectsRepo",

  // notes
  ALL_NOTES: "notes/getAllNotes",
  NOTES_REPO: "notes/getNotesRepo",
  NOTES_SEARCH_INDEX: "notes/getSearchIndex",
  GETTING_NOTES: "notes/getGettingNotes",
  SELECTED_NOTE: "notes/getSelectedNote",
  SHOW_NOTE_EDITOR: "notes/getShowNoteEditor",
  ONLY_CURRENT_PROJECT_NOTES: "notes/getOnlyCurrentProjectNotes",
  NOTES_FILTER_TYPE: "notes/getNotesFilterType",
  CURRENT_NOTES_PAGE: "notes/getCurrentPage",

  // results
  QUERY: "research/getQuery",
  NEWS_RESULTS: "research/getNewsResults",
  SCHOLAR_RESULTS: "research/getScholarResults",
  FACT_RESULTS: "research/getFactResults",
  RESEARCH_SEARCHING: "research/getSearching",

  // TOGAI
  TOGAI_CUSTOMER_INFO: "togai/togaiCustomerInfo",
  TOGAI_ACCOUNT_INFO: "togai/togaiAccountInfo",
  TOGAI_PLAN_LIST: "togai/togaiPlanList",
  TOGAI_PLAN_SCHEDULES: "togai/togaiPlanSchedules",
  TOGAI_PLAN: "togai/togaiPlan",
  TOGAI_FEATURE_CREDITS_BALANCE: "togai/featureCreditsBalance",
  NEW_PURCHASE_ENTITLEMENT_PLAN: "togai/newPurchasedEntitlementPlan",

  // onboarding page
  ONBOARDING_USER_TYPE: "onboarding/getUserType",
  ONBOARDING_FULLNAME: "onboarding/getFullname",
  ONBOARDING_USAGE_TYPE: "onboarding/getUsageType",
  ONBOARDING_SOURCE_TYPE: "onboarding/getSourceType",
  ONBOARDING_STEP: "onboarding/getOnboardingStep",
  ONBOARDING_INDUSTRY: "onboarding/getIndustry",
  ONBOARDING_ERROR: "onboarding/getOnboardingError",

  // agent mode
  AGENT_CHAT_HISTORY: "agent/getChatHistory",
  AGENT_SESSION_ID: "agent/getSessionId",
  AGENT_USER_REPLY: "agent/getUserReply",
  AGENT_IS_USER_REPLY: "agent/getIsUserReply",
  AGENT_SHOW_STOP_BTN: "agent/getShowStopBtn",
  AGENT_OBJECTIVE: "agent/getObjective",
  AGENT_TASKLIST: "agent/getTaskList",
  AGENT_HAS_NEXT_PAGE: "agent/getHasNextPage",
  CHAT_HISTORY_CURRENT_PAGE: "agent/getChatHistoryCurrentPage",
  SHOW_QUICK_STARTS: "agent/getShowQuickStarts",

  // new getters
  IS_NEW_USER: "isNewUser",
  IS_EMAIL_VERIFIED: "isEmailVerified",
}