import { NOTES_PER_PAGE } from "@/configs/constants"

/** @type {import("vuex").ActionTree} */
export default {
  updateNotesList({ state, commit }, notes) {
    const newNotesList = [...notes, ...state.allNotes]
    commit("setAllNotes", newNotesList)
  },

  updateNotesRepo({ state, commit }, notes) {
    commit("setNotesRepo", [...notes, ...state.notesRepo])
  },

  toggleNotesFromCurrentProject({ state, commit }) {
    commit("setNotesFromCurrentProject", !state.notesFromCurrentProject)
  },

  changeNotesPage(
    { state, commit },
    { next, largeSteps } = { next: true, largeSteps: false },
  ) {
    let nextPage = JSON.parse(JSON.stringify(state.currentPage))
    const maxPages = Math.ceil(state.notesRepo.length / NOTES_PER_PAGE)
    if (largeSteps) {
      if (next) {
        nextPage = nextPage + 10 > maxPages ? maxPages : nextPage + 10
      } else {
        nextPage = nextPage - 10 < 1 ? 1 : nextPage - 10
      }
    } else {
      if (next) {
        nextPage = nextPage + 1 > maxPages ? maxPages : nextPage + 1
      } else {
        nextPage = nextPage - 1 < 1 ? 1 : nextPage - 1
      }
    }
    commit("setCurrentPage", nextPage)
  },
}
